import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import store from "@/store";

export default {
  namespaced: true,
  state: {
    megaContainers: null,
    selectedMegaContainer: null,
    megaContainersContainers: null,
    containersList: null,
    selectedContainers: null
  },
  getters: {},
  mutations: {
    setMegaContainers(state, payload) {
      state.megaContainers = payload;
    },
    setSelectedMegaContainer(state, payload) {
      state.selectedMegaContainer = payload;
    },
    setMegaContainersContainers(state, payload) {
      state.megaContainersContainers = payload;
    },
    setContainersList(state, payload) {
      state.containersList = payload;
    },
    setSelectedContainers(state, payload) {
      if (!payload) {
        state.selectedContainers = payload;
      } else if (state.selectedContainers && state.selectedContainers.length) {
        if (payload.length) {
          const arr = [];
          payload.forEach(item => {
            if (
              state.selectedContainers.findIndex(sel => sel.id === item.id) ===
              -1
            ) {
              arr.push(item);
            }
          });
          state.selectedContainers = [...state.selectedContainers, ...arr];
        } else {
          state.selectedContainers = payload;
        }
      } else {
        state.selectedContainers = payload;
      }
    },
    setSelectedContainer(state, payload) {
      if (state.selectedContainers && state.selectedContainers.length) {
        const index = state.selectedContainers.findIndex(
          item => item.id === payload.id
        );
        if (index !== -1) {
          state.selectedContainers.splice(index, 1);
        } else {
          state.selectedContainers.push(payload);
        }
      } else {
        state.selectedContainers = [payload];
      }
    }
  },
  actions: {
    getMegaContainers({ state }) {
      store.dispatch("pagination/paginate", {
        whatToCall: "megaContainers/getMegaContainers",
        whereToSave: "megaContainers/setMegaContainers",
        url: apiEndpoints.company.megaContainers,
        data: state.megaContainers,
        params: {}
      });
    },
    setMegaContainers({ commit }, payload) {
      commit("setMegaContainers", payload);
    },
    setSelectedMegaContainer({ commit }, payload) {
      commit("setSelectedMegaContainer", payload);
    },
    deleteMegaContainer({ commit, dispatch }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .delete(`${apiEndpoints.company.megaContainers}/${id}`)
        .then(() => {
          dispatch("getMegaContainers");
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getMegaContainersContainers({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.megaContainers}/${id}/containers`)
        .then(response => {
          commit("setMegaContainersContainers", response.data);
          commit("setSelectedContainers", null);
          const { data } = response.data;
          if (data) {
            commit("setSelectedContainers", data);
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getContainersList({ state }, params) {
      store.dispatch("pagination/paginate", {
        whatToCall: "megaContainers/getContainersList",
        whereToSave: "megaContainers/setContainersList",
        url: apiEndpoints.company.containers,
        data: state.containersList,
        params: params
      });
      // commit("loader/setScreenLoading", true, { root: true });
      // httpServiceAuth
      //   .get(`${apiEndpoints.company.containers}/all`, { params })
      //   .then(response => {
      //     commit("setContainersList", response.data);
      //   })
      //   .catch(error => {
      //     if (error.response) {
      //       errorHandler(error.response);
      //     }
      //   })
      //   .finally(() => {
      //     commit("loader/setScreenLoading", false, { root: true });
      //   });
    },
    setContainersList({ commit }, payload) {
      commit("setContainersList", payload);
    },
    selectMegaContainerContainer({ commit }, payload) {
      commit("setSelectedContainer", payload);
    },
    addContainersToMegaContainer({ commit }, payload) {
      commit("loader/setScreenLoading", true, { root: true });
      const { container_ids, id } = payload;
      return httpServiceAuth
        .post(`${apiEndpoints.company.megaContainers}/${id}/containers`, {
          container_ids
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    }
  }
};
