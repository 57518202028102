export default {
  path: "/containers-level",
  name: "r_containers-level",
  meta: {
    middlewares: {
      auth: null,
      permission: "LEVELS_CONTAINERS"
    }
  },
  props: {
    containerType: "LEVEL",
    backLinkName: "r_containers-level"
  },
  component: () => import("../../components/containers/Containers"),
  children: [
    {
      path: "add-container",
      name: "r_add-level-container",
      meta: {
        middlewares: {
          auth: null,
          permission: "LEVELS_CONTAINERS"
        }
      },
      props: {
        backLinkName: "r_containers-level",
        containerType: "LEVEL"
      },
      component: () => import("../../components/containers/AddContainer")
    },
    {
      path: "edit-container",
      name: "r_edit-level-container",
      meta: {
        middlewares: {
          auth: null,
          permission: "LEVELS_CONTAINERS"
        }
      },
      props: {
        backLinkName: "r_containers-level",
        containerType: "LEVEL"
      },
      component: () => import("../../components/containers/EditContainer"),
      children: [
        {
          path: "add-levels-to-container",
          name: "r_edit-level-add-level-to-container",
          meta: {
            middlewares: {
              auth: null,
              permission: "LEVELS_CONTAINERS"
            }
          },
          props: {
            backLinkName: "r_edit-level-container",
            containerType: "LEVEL"
          },
          component: () =>
            import("../../components/containers/ContainersAddLevel")
        },
        {
          path: "add-roles-to-container",
          name: "r_edit-level-add-roles-to-container",
          meta: {
            middlewares: {
              auth: null,
              permission: "LEVELS_CONTAINERS"
            }
          },
          props: {
            backLinkName: "r_edit-level-container",
            containerType: "LEVEL"
          },
          component: () =>
            import("../../components/containers/ContainersAddRole")
        }
      ]
    }
  ]
};
