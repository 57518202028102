export default {
  state: {
    sections: ["Applications"],
    selectedSection: "Applications",
    selectedApplications: [],
    searchActiveNum: 0 // track api calls
  },
  mutations: {
    setSelectedSection(state, payload) {
      state.selectedSection = payload;
    },
    setSelectedApplications(state, payload) {
      const arr = [...state.selectedApplications];
      const index = arr.findIndex(item => item === payload);
      if (index === -1) {
        arr.push(payload);
      } else {
        arr.splice(index, 1);
      }
      state.selectedApplications = arr;
    },
    activateSearch(state, payload) {
      if (payload) {
        state.searchActiveNum === 1
          ? (state.searchActiveNum = 2)
          : (state.searchActiveNum = 1);
      } else {
        state.searchActiveNum = 0;
      }
    },
    clearAllFilters(state) {
      state.selectedLanguages = [];
      state.selectedApplications = [];
    }
  },
  actions: {
    setSelectedSection({ commit }, payload) {
      commit("setSelectedSection", payload);
    },
    setSelectedApplications({ commit }, payload) {
      commit("setSelectedApplications", payload);
    },
    activateSearch({ commit }, payload) {
      commit("activateSearch", payload);
    }
  }
};
