export default {
  namespaced: true,
  state: {
    editing: false,
    cameraActive: false,
    recordingStarted: false,
    cameraScreenId: null
  },
  mutations: {
    updateEditingStatus(state, payload) {
      state.editing = payload;
    },
    setCameraActive(state, payload) {
      state.cameraActive = payload;
    },
    setCameraRecordingStatus(state, payload) {
      state.recordingStarted = payload;
    },
    setCameraScreenId(state, payload) {
      state.cameraScreenId = payload;
    }
  },
  actions: {
    updateEditingStatus({ commit }, payload) {
      commit("updateEditingStatus", payload);
    },
    setCameraActive({ commit }, payload) {
      commit("setCameraActive", payload);
    },
    setCameraRecordingStatus({ commit }, payload) {
      commit("setCameraRecordingStatus", payload);
    },
    setCameraScreenId({ commit }, payload) {
      commit("setCameraScreenId", payload);
    }
  }
};
