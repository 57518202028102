export default {
  path: "/resource-attributes",
  name: "r_resource-attributes",
  meta: {
    middlewares: {
      auth: null,
      permission: "RESOURCE_ATTRIBUTES"
    }
  },
  component: () =>
    import("../../components/resource-attributes/ResourceAttributes"),
  children: [
    {
      path: "add",
      name: "r_add-resource-attribute",
      meta: {
        middlewares: {
          auth: null,
          permission: "RESOURCE_ATTRIBUTES"
        }
      },
      props: { backLinkName: "r_resource-attributes" },
      component: () =>
        import("../../components/resource-attributes/AddResourceAttribute"),
      children: [
        {
          path: "name-translations",
          name: "r_add-resource-attribute-translate-name",
          meta: {
            middlewares: {
              auth: null,
              permission: ["RESOURCE_ATTRIBUTES", "create"]
            }
          },
          props: {
            backLinkName: "r_add-resource-attribute",
            componentTitle: "add-resource-attribute"
          },
          component: () =>
            import(
              "../../components/resource-attributes/TranslateResourceAttribute"
            ),
          children: [
            {
              path: "help",
              name: "r_resource-attributes-add-translation-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCE_ATTRIBUTES"
                }
              },
              props: {
                backLinkName: "r_add-resource-attribute-translate-name",
                slug: "resource-attributes-translation"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "help",
          name: "r_resource-attributes-add-help-online",
          meta: {
            middlewares: {
              auth: null,
              permission: "RESOURCE_ATTRIBUTES"
            }
          },
          props: {
            backLinkName: "r_add-resource-attribute",
            slug: "resource-attributes-add"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: ":resource_attribute_id",
      name: "r_edit-resource-attribute",
      meta: {
        middlewares: {
          auth: null,
          permission: "RESOURCE_ATTRIBUTES"
        }
      },
      props: { backLinkName: "r_resource-attributes" },
      component: () =>
        import("../../components/resource-attributes/EditResourceAttribute"),
      children: [
        {
          path: "name-translations",
          name: "r_edit-resource-attribute-translate-name",
          meta: {
            middlewares: {
              auth: null,
              permission: "RESOURCE_ATTRIBUTES"
            }
          },
          props: {
            backLinkName: "r_edit-resource-attribute",
            componentTitle: "edit-resource-attribute"
          },
          component: () =>
            import(
              "../../components/resource-attributes/TranslateResourceAttribute"
            ),
          children: [
            {
              path: "help",
              name: "r_resource-attributes-edit-translation-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCE_ATTRIBUTES"
                }
              },
              props: {
                backLinkName: "r_edit-resource-attribute-translate-name",
                slug: "resource-attributes-translation"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "help",
          name: "r_resource-attributes-edit-help-online",
          meta: {
            middlewares: {
              auth: null,
              permission: "RESOURCE_ATTRIBUTES"
            }
          },
          props: {
            backLinkName: "r_edit-resource-attribute",
            slug: "resource-attributes-edit"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "help",
      name: "r_resource-attributes-help-online",
      meta: {
        middlewares: {
          auth: null,
          permission: "RESOURCE_ATTRIBUTES"
        }
      },
      props: {
        backLinkName: "r_resource-attributes",
        slug: "resource-attributes"
      },
      component: () => import("../../components/help-online/HelpOnline")
    }
  ]
};
