export default {
  path: "/actions/:declaration_id",
  name: "r_actions",
  meta: {
    middlewares: {
      auth: null
    }
  },
  component: () => import("../../components/actions/Action")
};
