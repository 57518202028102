<template>
  <section class="plans-footer-menu icon-foo1">
    <nav class="menu">
      <ul class="menu-items">
        <li class="menu-item calendar">
          <router-link :to="{ name: 'r_calendar' }" active-class="active">
            <icon icon="#cx-foo1-calendar-28x28" />
            {{ displayLabelName("plan", "plan", "calendar") }}</router-link
          >
        </li>
        <li class="menu-item plan">
          <router-link
            :to="{ name: 'r_plan' }"
            custom
            v-slot="{ href, navigate, isActive }"
          >
            <a
              :href="href"
              @click="navigate"
              :class="{
                active: isActive && $route.path.indexOf('maps') === -1,
              }"
            >
              <icon icon="#cx-foo1-plan-28x28" />
              {{ displayLabelName("plan", "plan", "plan") }}
            </a>
          </router-link>
        </li>
        <li
          class="menu-item central-item icon-foo2"
          v-if="
            showCameraButton ||
              calendar ||
              routeName === 'r_plan-resources-slots' ||
              routeName === 'r_plan-maps-slots' ||
              routeName === 'r_one-click-slots'
          "
        >
          <button
            v-if="routeName === 'r_one-click-calendar'"
            @click="openMap"
            :class="{
              disabled: !hasMap || !hasSelectedSlot,
            }"
          >
            <span class="svg-icon"
              ><icon icon="#cx-foo1-5d-maps-28x28" class="maps"
            /></span></button
          ><slot v-else></slot>
        </li>
        <li class="menu-item pass">
          <router-link :to="{ name: 'r_pass' }" active-class="active">
            <icon icon="#cx-foo1-pass-28x28" />
            {{ displayLabelName("plan", "plan", "pass") }}</router-link
          >
        </li>
        <li class="menu-item action">
          <button
            @click="openMap"
            :class="{
              disabled:
                !hasMap ||
                !isTodayAvailable ||
                slotsAreInThePast ||
                (routeName === 'r_one-click-calendar' && !hasSelectedSlot),
              active: $route.path.indexOf('maps') !== -1,
            }"
          >
            <icon icon="#cx-foo1-5d-maps-28x28" />
            {{ displayLabelName("plan", "plan", "5d-maps") }}
          </button>
        </li>
        {{
          plan
        }}
      </ul>
    </nav>
  </section>
</template>

<script>
import dayjs from "dayjs";
import { mapState, mapActions } from "vuex";
import qs from "qs";

export default {
  name: "PlansFooterMenu",
  props: {
    showCameraButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    calendar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState("plan", [
      "selectedLevel",
      "selectedSlot",
      "slots",
      "occupancy",
      "planSelectedDate",
    ]),
    ...mapState("oneClick", ["mapUrl"]),
    hasMap() {
      return this.selectedLevel && this.selectedLevel.map_id;
    },
    hasSelectedSlot() {
      return this.selectedSlot && Object.keys(this.selectedSlot).length;
    },
    routeName() {
      return this.$route.name;
    },
    isTodayAvailable() {
      let response = true;
      if (this.$route.name == "r_plan-resources-slots") {
        if (this.occupancy) {
          this.occupancy.data.forEach((o) => {
            if (o.date == this.planSelectedDate) {
              if (o.occupancy_percentage == 100) {
                response = false;
              }
            }
          });
        }
      }

      return response;
    },
    slotsAreInThePast() {
      let response = false;
      let defaultSlot = null;

      if (this.slots && this.slots.data) {
        this.slots.data.forEach((slot) => {
          if (slot.default) defaultSlot = slot;
        });

        if (defaultSlot == null) {
          defaultSlot = this.slots.data[0];
        }

        if (defaultSlot.slots.length > 0) {
          if (
            dayjs(
              defaultSlot.slots[defaultSlot.slots.length - 1].from
            ).isBefore(dayjs())
          ) {
            response = true;
          }
        }
      }

      return response;
    },
  },
  methods: {
    ...mapActions("plan", ["selectPlanResource"]),
    openMap() {
      if (!this.hasMap) {
        return;
      }
      if (this.$route.path.indexOf("slots") !== -1) {
        if (this.$route.path.indexOf("resources") !== -1) {
          this.$router.push({ name: "r_plan-maps-slots" });
        } else {
          this.$router.push({ name: "r_plan-slots-maps" });
        }
      } else {
        if (this.routeName === "r_one-click-calendar") {
          this.showOneClickMap();
        } else {
          this.selectPlanResource(null);
          this.$router.push({ name: "r_plan-maps" });
        }
      }
    },
    showOneClickMap() {
      if (!this.hasSelectedSlot) {
        return;
      }
      this.$store.commit("plan/setMapData", null, {
        root: true,
      });
      const mapData = qs.stringify({
        from: this.selectedSlot.slots[0].datetime_from,
        to: this.selectedSlot.slots.at(-1).datetime_to,
        type: this.selectedSlot.type,
      });
      this.$store.commit("plan/setMapData", mapData, {
        root: true,
      });
      this.$router.push({
        name: "r_one-click-map-page",
      });
    },
  },
};
</script>
