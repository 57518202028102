export default {
  path: "/linked",
  name: "r_linked",
  meta: {
    middlewares: {
      auth: null,
      permission: "5D_MAPS"
    }
  },
  component: () => import("../../components/linked/Linked")
};
