import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import httpServiceAuth from "@/services/http-service";

export default {
  state: {
    visits: [],
    visit: null
  },
  mutations: {
    setVisits(state, payload) {
      state.visits = payload;
    },
    setVisit(state, payload) {
      state.visit = payload;
    }
  },
  actions: {
    getVisits({ commit }, payload) {
      const url = `${apiEndpoints.company.myvisits}/all`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(url, {
          params: {
            datetime_from: payload.datetime_from
          }
        })
        .then(response => {
          commit("setVisits", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getVisit({ commit }, id) {
      const url = `${apiEndpoints.company.myvisits}/${id}`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setVisit", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    }
  }
};
