<template>
  <ul class="clebex-item-section pill icon-app1">
    <li class="clebex-item-section-label-inner">
      <div class="clebex-label-content-wrapper">
        {{ displayLabelName("plan", "plan", "most-frequently-used-locations") }}
      </div>
    </li>
    <li
      class="clebex-item-section-item"
      v-for="level in frequentLevels.data"
      :key="level.id"
    >
      <button class="clebex-item-content-wrapper" @click="selectLevel(level)">
        <span class="follow-up-icons">
          <button
            class="follow-up-icon-item"
            :class="{
              default: level.is_default,
              favorite: level.is_favorite && !level.is_default
            }"
            :disabled="!canItem(level, 'update')"
            @click.stop="
              !level.is_default
                ? level.is_favorite
                  ? unsetFavoriteLevel(level.id)
                  : setFavoriteLevel(level.id)
                : false
            "
          >
            <icon v-if="level.is_default" icon="#cx-app1-favourite-default" />
            <icon
              v-else-if="level.is_favorite"
              icon="#cx-app1-favourite-fill"
            />
            <icon v-else icon="#cx-app1-favourite" />
          </button>
        </span>
        <span class="label">
          <span class="text">
            <span class="highlight">{{
              level.all_parents && level.all_parents.length
                ? `${level.name},`
                : level.name
            }}</span>
            {{ displayParentNames(level.all_parents) }}
          </span>
          <span class="inner-icons">
            <span v-if="!level.is_declarable" class="declarable-icon"
              ><icon icon="#cx-app1-non-declarable" />
            </span>
            <span v-if="level.map_id" class="declarable-icon"
              ><icon icon="#cx-app1-5d-maps" />
            </span>
          </span>
        </span>
        <span class="follow-up-icons">
          <button
            @click.stop="
              $router.push({
                name: 'r_plan-level',
                params: { ...$route.params, level_id: level.id }
              })
            "
            class="follow-up-icon-item"
          >
            <icon icon="#cx-app1-information" />
          </button>
          <span
            class="follow-up-icon-item"
            :class="{
              active: selectedLevel && selectedLevel.id === level.id,
              hidden: !level.is_declarable
            }"
          >
            <icon
              icon="#cx-app1-checkmark"
              v-if="selectedLevel && selectedLevel.id === level.id"
            />
            <icon
              icon="#cx-app1-arrow-right-12x12"
              width="12"
              height="12"
              v-else
            />
          </span>
        </span>
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: "FrequentLevelsList",
  props: {
    frequentLevels: {
      type: Object,
      required: true
    },
    selectLevel: {
      type: Function,
      required: true
    },
    selectedLevel: {
      type: Object || Boolean,
      required: false
    },
    displayParentNames: {
      type: Function,
      required: true
    },
    setFavoriteLevel: {
      type: Function,
      required: true
    },
    unsetFavoriteLevel: {
      type: Function,
      required: true
    }
  }
};
</script>

<style scoped></style>
