export default {
  path: "/roles-level",
  name: "r_roles-level",
  meta: {
    middlewares: {
      auth: null,
      permission: "LEVELS_ROLES"
    }
  },
  props: {
    containerType: "LEVEL",
    backLinkName: "r_roles-level"
  },
  component: () => import("../../components/roles/Roles"),
  children: [
    {
      path: "add-role",
      name: "r_add-level-role",
      meta: {
        middlewares: {
          auth: null,
          permission: "LEVELS_ROLES"
        }
      },
      props: { backLinkName: "r_roles-level", containerType: "LEVEL" },
      component: () => import("../../components/roles/AddRole")
    },
    {
      path: "edit-role",
      name: "r_edit-level-role",
      meta: {
        middlewares: {
          auth: null,
          permission: "LEVELS_ROLES"
        }
      },
      props: { backLinkName: "r_roles-level", containerType: "LEVEL" },
      component: () => import("../../components/roles/EditRole"),
      children: [
        {
          path: "add-permissions-to-roles",
          name: "r_edit-level-add-permissions-to-role",
          meta: {
            middlewares: {
              auth: null,
              permission: "LEVELS_ROLES"
            }
          },
          props: {
            backLinkName: "r_edit-level-role",
            containerType: "LEVEL"
          },
          component: () => import("../../components/roles/RolesAddPermissions")
        }
      ]
    }
  ]
};
