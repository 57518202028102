export const preferenceValueLabels = {
  START_UP_PAGE: {
    MAIN_MENU: "main-menu",
    "5D_MAPS": "5d-maps",
    // AUTHORIZE: "authorize",
    // BOOKING: "booking",
    // CLEAN: "clean",
    // DISTANCE: "distance",
    // DOCUMENTS: "documents",
    // EVENTS: "events",
    // MESSAGES: "messages",
    OCCUPANCY: "occupancy",
    PLAN: "plan",
    REAL_TIME: "real-time",
    // SERVICES: "services",
    // SURVEY: "survey",
    ONE_CLICK: "One-Click",
    COCKPIT: "Cockpit",
  },
  FIRST_DAY_OF_WEEK: {
    0: "monday",
    1: "tuesday",
    2: "wednesday",
    3: "thursday",
    4: "friday",
    5: "saturday",
    6: "sunday",
  },
  WEEKEND_DAYS: {
    0: "monday",
    1: "tuesday",
    2: "wednesday",
    3: "thursday",
    4: "friday",
    5: "saturday",
    6: "sunday",
  },
  DISPLAY_WEEKEND: {
    1: "yes",
    0: "no",
  },
  DEFAULT_SLOTS: {
    DAY: "all-day",
    HALF_DAY: "half-day",
    HOUR: "hours",
    HALF_HOUR: "30-minutes",
    QUARTER_HOUR: "15-minutes",
  },
  DATE_TIME_MODE: {
    H24: "h24",
    AM_PM: "am-pm",
  },
};
export const preferenceComponentsMap = {
  START_UP_PAGE: "StartUpPage",
  DEFAULT_LOCATION: "DefaultLocation",
  FIRST_DAY_OF_WEEK: "FirstDayOfWeek",
  WEEKEND_DAYS: "WeekendDays",
  DISPLAY_WEEKEND: "DisplayWeekend",
  MORNING_FROM: "MorningFrom",
  AFTERNOON_FROM: "AfternoonFrom",
  EVENING_FROM: "EveningFrom",
  NIGHT_FROM: "NightFrom",
  DATE_FORMAT: "DateFormat",
  TIME_FORMAT: "TimeFormat",
  DATE_TIME_MODE: "DateTimeMode",
  DEFAULT_SLOT: "DefaultSlot",
  AVAILABLE_SLOTS: "AvailableSlots",
  PLAN_HIGH_OCCUPANCY_START_PERCENTAGE: "HighOccupancyPercentage",
  PLAN_AVERAGE_OCCUPANCY_START_PERCENTAGE: "AverageOccupancyPercentage",
  PLAN_HIGH_OCCUPANCY_COLOR: "HighOccupancyColor",
  PLAN_AVERAGE_OCCUPANCY_COLOR: "AverageOccupancyColor",
  PLAN_LOW_OCCUPANCY_COLOR: "LowOccupancyColor",
  DEFAULT_MEETING_TITLE: "DefaultMeetingTitle",
};
export const preferenceCategoriesIconsMap = {
  LANDING_PAGE: "#cx-men1-landing-page",
  LOCATION: "#cx-men1-location",
  CALENDAR: "#cx-men1-calendar",
  DATE_AND_TIME: "#cx-men1-real-time",
  SLOTS: "#cx-men1-slots",
  COLORS: "#cx-men1-resources",
  BOOKING: "#cx-men1-booking",
  MESSAGES: "#cx-men1-messages",
  DELEGATE: "#cx-men1-delegate",
  PORTAL: "#cx-men1-portal",
  GDPR_RULES: "#cx-men1-levels",
  USER_FORM_TEMPLATE: "#cx-men1-profile",
  PLAN: "#cx-men1-plan",
  MENU: "#cx-hea1-menu",
  COUNT: "#cx-men1-count",
  PUSH_NOTIFICATIONS: "#cx-men1-plan",
  GRID: "#cx-men1-plan",
  RESOURCE_INFO: "#cx-men1-resources",
};
export const preferenceAutomaticSave = [
  "START_UP_PAGE",
  "DEFAULT_LOCATION",
  "FIRST_DAY_OF_WEEK",
  "DISPLAY_WEEKEND",
  "DEFAULT_SLOT",
  "DATE_TIME_MODE",
  "MORNING_FROM",
  "AFTERNOON_FROM",
  "EVENING_FROM",
  "NIGHT_FROM",
];
export const preferenceCategoryIds = {
  slots: 5,
  gdprRules: 8,
  userFormTemplate: 9,
  plan: 10,
  menu: 11,
  counter: 12,
  pushNotifications: 13,
  grid: 14,
  resourceInfo: 15,
};
export const preferenceCategoryIdsHelpOnline = {
  1: "preferences-landing-page",
  2: "preferences-location",
  4: "preferences-date-time",
  5: "preferences-slots",
  11: "preferences-menu",
};
export const preferenceSetIdsHelpOnline = {
  DATE_FORMAT: "preferences-date",
  TIME_FORMAT: "preferences-time",
  START_UP_PAGE: "preferences-landing-page-select",
  DEFAULT_LOCATION: "preferences-location-selection",
  DEFAULT_SLOT: "preferences-slots-select",
  AVAILABLE_SLOTS: "preferences-slots-select",
};
