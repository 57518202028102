import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState("translation", ["translations"]),
    ...mapState("global", ["showRawTranslation"])
  },
  methods: {
    ...mapActions("translation", ["getTranslations"]),
    displayLabelName(label, legacyPage, legacyLabel) {
      if (legacyPage && legacyLabel) {
        label = `${label}.${legacyPage}.${legacyLabel}`;
      }
      if (this.translations && this.translations.data) {
        if (this.translations.data[label]) {
          return this.showRawTranslation
            ? label
            : this.translations.data[label];
        } else {
          label = label
            .split(".")
            .splice(2)
            .join(".");
          return label;
        }
      } else {
        return this.showRawTranslation ? label : "";
      }
    },
    findElement() {
      const el = this.$el;
      if (el.nodeName === "#text") {
        return el.nextSibling;
      }
      return el;
    }
  }
};
