import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import router from "@/router";
import store from "@/store";

export default {
  state: {
    resources: null,
    capacity: 0,
    description: null,
    maxCapacity: 0,
    currentCapacity: 0,
    propagateCapacity: false,
    selectedResourceLevel: null,
    resourceTypes: null,
    frequentResourceTypes: null,
    selectedResourceType: null,
    resourceCapacityTypes: null,
    selectedResourceCapacityType: null,
    resourceModels: null,
    selectedResourceModel: null,
    resource: null,
    resourceFunctionTypes: null,
    resourceFunctionType: null,
    resourceResourceFunctions: null,
    resourceResourceFunction: null,
    resourceAttributes: null,
    selectedResourceAttribute: null,
    resourceCapacityTypeDates: null,
    selectedResourceAttributes: [],
    resourceServices: null,
    selectedResourceService: null,
    selectedResourceServices: [],
    resourceTranslations: [], // resetovati
    resourceTypesTranslations: [], // resetovati
    resourceType: null,
    resourceAttributesTranslations: [], // resetovati
    resourceAttribute: null,
    resourceLinked: null,
    resourceDistances: null,
    resourceCalendars: null,
    resourceEvents: null,
    resourceEventName: null,
    resourceEventSyncMessages: {},
    resourceEventSyncInit: null,
    resourcesPaginationData: null,
    resourcesLoading: false,
    resourceImage: null,
    filterResourceTypes: null,
    filterResourceAttributes: null,
    resourceInfo: null,
  },
  getters: {
    activeResourceFunctionTypes(state) {
      const { resourceResourceFunctions, resourceFunctionTypes } = state;

      if (
        resourceFunctionTypes &&
        resourceFunctionTypes.data &&
        resourceFunctionTypes.data.length
      ) {
        if (
          resourceResourceFunctions &&
          resourceResourceFunctions.data &&
          resourceResourceFunctions.data.length
        ) {
          return resourceFunctionTypes.data.map((conn) => {
            const filtered = resourceResourceFunctions.data.filter(
              (res) => res.resource_function_type_id === conn.id
            );

            if (filtered && filtered.length) {
              return {
                ...conn,
                active: conn.active
                  ? Boolean(conn.active)
                  : Boolean(filtered[0].active),
              };
            } else {
              return {
                ...conn,
                active: Boolean(conn.active),
              };
            }
          });
        } else {
          return resourceFunctionTypes.data.map((conn) => {
            return {
              ...conn,
              active: Boolean(conn.active),
            };
          });
        }
      } else {
        return null;
      }
    },
    resourceImage(state) {
      return (
        state.resourceImage &&
        state.resourceImage.data &&
        state.resourceImage.data.url
      );
    },
  },
  mutations: {
    setResourcesData(state, payload) {
      state.resources = payload;
    },
    setDescription(state, payload) {
      state.resource.data.description = payload;
    },
    setCapacity(state, payload) {
      state.capacity = payload;
    },
    setMaxCapacity(state, payload) {
      state.maxCapacity = payload;
    },
    setCurrentCapacity(state, payload) {
      state.currentCapacity = payload;
    },
    setPropagateCapacity(state, payload) {
      state.propagateCapacity = payload;
    },
    setSelectedResourceLevel(state, payload) {
      if (!payload && state.resource && state.resource.data) {
        state.selectedResourceLevel = state.resource.data.level;
      } else {
        state.selectedResourceLevel = payload;
      }
    },
    setSelectedResourceTypes(state, payload) {
      state.resourceTypes = payload;
    },
    setSelectedFrequentResourceTypes(state, payload) {
      state.frequentResourceTypes = payload;
    },
    setSelectedResourceType(state, payload) {
      if (!payload && state.resource && state.resource.data) {
        state.selectedResourceType = state.resource.data.resource_type;
      } else {
        state.selectedResourceType = payload;
      }
    },
    setResourceCapacityTypes(state, payload) {
      state.resourceCapacityTypes = payload;
    },
    setSelectedResourceCapacityType(state, payload) {
      if (!payload && state.resource && state.resource.data) {
        state.selectedResourceCapacityType =
          state.resource.data.resource_capacity_type;
      } else {
        state.selectedResourceCapacityType = payload;
      }
    },
    setResourceCapacityModels(state, payload) {
      state.resourceModels = payload;
    },
    setSelectedResourceModel(state, payload) {
      if (!payload && state.resource && state.resource.data) {
        state.selectedResourceModel = state.resource.data.resource_model;
      } else {
        state.selectedResourceModel = payload;
      }
    },
    setResourceData(state, payload) {
      state.resource = payload;
    },
    setResourceFunctionTypesData(state, payload) {
      state.resourceFunctionTypes = payload;
    },
    setResourceFunctionTypeData(state, payload) {
      state.resourceFunctionType = payload;
    },
    setResourceResourceFunctionsData(state, payload) {
      state.resourceResourceFunctions = payload;
    },
    setResourceResourceFunctionData(state, payload) {
      state.resourceResourceFunction = payload;
    },
    setResourceAttributesData(state, payload) {
      state.resourceAttributes = payload;
    },
    setSelectedResourceAttribute(state, payload) {
      state.selectedResourceService = payload;
    },
    setResourceServicesData(state, payload) {
      state.resourceServices = payload;
    },
    setSelectedResourceService(state, payload) {
      state.selectedResourceAttribute = payload;
    },
    setResourceCapacityDatesData(state, payload) {
      state.resourceCapacityTypeDates = payload;
    },
    setSelectedResourceAttributes(state, payload) {
      if (!payload) {
        state.selectedResourceAttributes = [];
      } else if (Array.isArray(payload)) {
        state.selectedResourceAttributes = payload;
      } else {
        const hasResourceIdx = state.selectedResourceAttributes.findIndex(
          (item) => item.id === payload.id
        );
        if (hasResourceIdx !== -1) {
          state.selectedResourceAttributes.splice(hasResourceIdx, 1);
        } else {
          state.selectedResourceAttributes.push(payload);
        }
      }
    },
    setSelectedResourceServices(state, payload) {
      if (!payload) {
        state.selectedResourceServices = [];
      } else if (Array.isArray(payload)) {
        state.selectedResourceServices = payload;
      } else {
        const hasResourceIdx = state.selectedResourceServices.findIndex(
          (item) => item.id === payload.id
        );
        if (hasResourceIdx !== -1) {
          state.selectedResourceServices.splice(hasResourceIdx, 1);
        } else {
          state.selectedResourceServices.push(payload);
        }
      }
    },
    setResourceTranslations(state, payload) {
      state.resourceTranslations = payload;
    },
    setResourceTypeTranslations(state, payload) {
      state.resourceTypesTranslations = payload;
    },
    setResourceType(state, payload) {
      state.resourceType = payload;
    },
    setResourceTypes(state, payload) {
      state.resourceTypes = payload;
    },
    setResourceAttributeTranslations(state, payload) {
      state.resourceAttributesTranslations = payload;
    },
    setResourceAttribute(state, payload) {
      state.resourceAttribute = payload;
    },
    setResourceLinked(state, payload) {
      state.resourceLinked = payload;
    },
    setResourceDistances(state, payload) {
      state.resourceDistances = payload;
    },
    setResourceCalendars(state, payload) {
      state.resourceCalendars = payload;
    },
    setResourceEvents(state, payload) {
      state.resourceEvents = payload;
    },
    setResourceEventName(state, payload) {
      state.resourceEventName = payload;
    },
    setResourceEventSyncMessages(state, payload) {
      state.resourceEventSyncMessages = payload;
    },
    setResourceEventSyncInit(state, payload) {
      state.resourceEventSyncInit = payload;
    },
    setResourcesPaginationData(state, payload) {
      state.resourcesPaginationData = payload;
    },
    setResourcesLoading(state, payload) {
      state.resourcesLoading = payload;
    },
    setResourceImage(state, payload) {
      state.resourceImage = payload;
    },
    setFilterResourceTypes(state, payload) {
      state.filterResourceTypes = payload;
    },
    setFilterResourceAttributes(state, payload) {
      state.filterResourceAttributes = payload;
    },
    setResourceInfo(state, payload) {
      state.resourceInfo = payload;
    },
  },
  actions: {
    getResources({ rootState, state, commit }) {
      commit("setResourcesLoading", true);
      const params = {
        includes: ["all_parents"],
      };
      if (rootState.search.searchQuery) {
        params.query = rootState.search.searchQuery;
      }

      if (rootState.searchFiltersResources.selectedTypeIds.length) {
        if (params.filters) {
          params.filters.push({
            type: "resource_type",
            value: [...rootState.searchFiltersResources.selectedTypeIds],
          });
        } else {
          params.filters = [
            {
              type: "resource_type",
              value: [...rootState.searchFiltersResources.selectedTypeIds],
            },
          ];
        }
      }

      if (rootState.searchFiltersResources.selectedLevelIds.length) {
        if (params.filters) {
          params.filters.push({
            type: "level",
            value: [...rootState.searchFiltersResources.selectedLevelIds],
          });
        } else {
          params.filters = [
            {
              type: "level",
              value: [...rootState.searchFiltersResources.selectedLevelIds],
            },
          ];
        }
      }

      if (rootState.searchFiltersResources.selectedFunctionTypeIds.length) {
        if (params.filters) {
          params.filters.push({
            type: "resource_function_type",
            value: [
              ...rootState.searchFiltersResources.selectedFunctionTypeIds,
            ],
          });
        } else {
          params.filters = [
            {
              type: "resource_function_type",
              value: [
                ...rootState.searchFiltersResources.selectedFunctionTypeIds,
              ],
            },
          ];
        }
      }

      if (rootState.searchFiltersResources.selectedModelIds.length) {
        if (params.filters) {
          params.filters.push({
            type: "resource_model",
            value: [...rootState.searchFiltersResources.selectedModelIds],
          });
        } else {
          params.filters = [
            {
              type: "resource_model",
              value: [...rootState.searchFiltersResources.selectedModelIds],
            },
          ];
        }
      }

      if (rootState.searchFiltersResources.selectedAttributesIds.length) {
        if (params.filters) {
          params.filters.push({
            type: "resource_attribute",
            value: [...rootState.searchFiltersResources.selectedAttributesIds],
          });
        } else {
          params.filters = [
            {
              type: "resource_attribute",
              value: [
                ...rootState.searchFiltersResources.selectedAttributesIds,
              ],
            },
          ];
        }
      }

      if (Number(rootState.searchFiltersResources.selectedCapacity)) {
        if (params.filters) {
          params.filters.push({
            type: "capacity",
            value: rootState.searchFiltersResources.selectedCapacity,
          });
        } else {
          params.filters = [
            {
              type: "capacity",
              value: rootState.searchFiltersResources.selectedCapacity,
            },
          ];
        }
      }

      store.dispatch("pagination/paginate", {
        whatToCall: "resource/getResources",
        whereToSave: "resource/setResourcesData",
        url: apiEndpoints.company.resources,
        data: state.resources,
        params: params,
      });
    },
    setSelectedResourceLevel({ commit }, payload) {
      commit("setSelectedResourceLevel", payload);
    },
    getResourceTypes({ state }, params) {
      let whereToSave = "";
      if (params && params.filters.find((el) => el.type === "frequent")) {
        whereToSave = "resource/setSelectedFrequentResourceTypes";
      } else {
        whereToSave = "resource/setSelectedResourceTypes";
      }

      store.dispatch("pagination/paginate", {
        whatToCall: "resource/getResourceTypes",
        whereToSave: whereToSave,
        url: apiEndpoints.company.resourceTypes,
        data: state.resourceTypes,
        params: params,
      });
    },
    setSelectedFrequentResourceTypes({ commit }, payload) {
      commit("setSelectedFrequentResourceTypes", payload);
    },
    setSelectedResourceTypes({ commit }, payload) {
      commit("setSelectedResourceTypes", payload);
    },
    setSelectedResourceType({ commit }, payload) {
      commit("setSelectedResourceType", payload);
    },
    deleteResourceType({ commit, dispatch }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .delete(`${apiEndpoints.company.resourceTypes}/${id}`)
        .then(() => {
          dispatch("getResourceTypes");
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getResourceCapacityTypes({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.resourceCapacityType}/all`)
        .then((response) => {
          commit("setResourceCapacityTypes", response.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedResourceCapacityType({ commit }, payload) {
      commit("setSelectedResourceCapacityType", payload);
    },
    getResourceModels({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.resourceModel}/all`)
        .then((response) => {
          commit("setResourceCapacityModels", response.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedResourceModel({ commit }, payload) {
      commit("setSelectedResourceModel", payload);
    },
    setResourceTypes({ commit }, payload) {
      commit("setResourceTypes", payload);
    },
    getResource({ commit, dispatch }, { id, params, noLoaders }) {
      if (!noLoaders) {
        commit("loader/setScreenLoading", true, { root: true });
      }
      commit("setResourceTranslations", []);
      return httpServiceAuth
        .get(`${apiEndpoints.company.resources}/${id}`, { params })
        .then((response) => {
          commit("setResourceData", response.data);
          dispatch("setOtherResourceData", response.data);
          const { resource_attributes, resource_services } = response.data.data;

          if (resource_attributes && resource_attributes.length) {
            commit("setSelectedResourceAttributes", resource_attributes);
          } else {
            commit("setSelectedResourceAttributes", []);
          }
          if (resource_services && resource_services.length) {
            commit("setSelectedResourceServices", resource_services);
          } else {
            commit("setSelectedResourceServices", []);
          }
          if (!noLoaders) {
            commit("loader/setScreenLoading", false, { root: true });
          }
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    getResourceInfo({ commit }, { id, params, noLoaders }) {
      if (!noLoaders) {
        commit("loader/setScreenLoading", true, { root: true });
      }
      return httpServiceAuth
        .get(`${apiEndpoints.company.resourceInfo}/${id}`, { params })
        .then((response) => {
          commit("setResourceInfo", response.data);
          if (!noLoaders) {
            commit("loader/setScreenLoading", false, { root: true });
          }
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    setOtherResourceData({ commit }, data) {
      const {
        capacity,
        max_capacity,
        propagate_capacity,
        current_capacity,
        level,
        resource_type,
        resource_capacity_type,
        resource_model,
      } = data.data;

      if (capacity) {
        commit("setCapacity", capacity);
      }

      if (max_capacity) {
        commit("setMaxCapacity", max_capacity);
      }

      if (propagate_capacity) {
        commit("setPropagateCapacity", Boolean(propagate_capacity));
      } else {
        commit("setPropagateCapacity", false);
      }

      if (current_capacity) {
        commit("setCurrentCapacity", current_capacity);
      }

      if (level) {
        commit("setSelectedResourceLevel", level);
      }

      if (resource_type) {
        commit("setSelectedResourceType", resource_type);
      }

      if (resource_capacity_type) {
        commit("setSelectedResourceCapacityType", resource_capacity_type);
      }

      if (resource_model) {
        commit("setSelectedResourceModel", resource_model);
      }
    },
    duplicateResourceApi({ commit }, { id, name }) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .post(`${apiEndpoints.company.resources}/${id}/duplicate`, {
          name,
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    deleteResource({ commit, dispatch }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .delete(`${apiEndpoints.company.resources}/${id}`)
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
          dispatch("getResources", { model_ids: null, reset: true });
        });
    },
    checkDeleteResource({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.resources}/checkdelete/${id}`)
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getResourceFunctionTypes({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.resourceFunctionsTypes}/all`)
        .then((response) => {
          commit("setResourceFunctionTypesData", response.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getResourceFunctionType({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.resourceFunctionsTypes}/${id}`)
        .then((response) => {
          commit("setResourceFunctionTypeData", response.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getResourceResourceFunctions({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.resources}/${id}/resourcefunctions/all`, {
          params: {
            includes: ["codes"],
          },
        })
        .then((response) => {
          commit("setResourceResourceFunctionsData", response.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getResourceResourceFunction({ commit }, { resourceId, functionId }) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(
          `${apiEndpoints.company.resources}/${resourceId}/resourcefunctions/${functionId}`
        )
        .then((response) => {
          commit("setResourceResourceFunctionData", response.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getResourceAttributes({ state }) {
      store.dispatch("pagination/paginate", {
        whatToCall: "resource/getResourceAttributes",
        whereToSave: "resource/setResourceAttributesData",
        url: apiEndpoints.company.resourceAttributes,
        data: state.resourceAttributes,
        params: {},
      });
    },
    setResourceAttributesData({ commit }, payload) {
      commit("setResourceAttributesData", payload);
    },
    setSelectedResourceAttribute({ commit }, payload) {
      commit("setSelectedResourceAttribute", payload);
    },
    getResourceServices({ commit }, id = null) {
      let url = id != null ? "?resource_id=" + id : "";

      // if (id != null) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.serviceItems}${url}`)
        .then((response) => {
          commit("setResourceServicesData", response.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
      // }
    },
    setResourceServices({ commit }, payload) {
      commit("setResourceServicesData", payload);
    },
    setSelectedResourceService({ commit }, payload) {
      commit("setSelectedResourceService", payload);
    },
    setSelectedResourceServices({ commit }, payload) {
      commit("setSelectedResourceServices", payload);
    },
    deleteResourceAttribute({ commit, dispatch }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .delete(`${apiEndpoints.company.resourceAttributes}/${id}`)
        .then(() => {
          dispatch("getResourceAttributes");
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getResourceCapacityTypeDateData({ commit }, resource_id) {
      httpServiceAuth
        .get(
          `${apiEndpoints.company.resources}/${resource_id}/resourcedatecapacities/all`
        )
        .then((response) => {
          commit("setResourceCapacityDatesData", response.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    selectResourceAttribute({ commit }, attribute) {
      commit("setSelectedResourceAttributes", attribute);
    },
    selectResourceService({ commit }, service) {
      commit("setSelectedResourceServices", service);
    },
    setResourceResourceIds(context, payload) {
      const { resourceId, attributeIds } = payload;
      return httpServiceAuth.post(
        `${apiEndpoints.company.resources}/${resourceId}/resourceattributes`,
        {
          resource_attribute_ids: attributeIds,
        }
      );
    },
    favoriteResource({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .post(`${apiEndpoints.company.resources}/${id}/favor`)
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    unFavoriteResource({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .post(`${apiEndpoints.company.resources}/${id}/disfavor`)
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    favoriteResourceType({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .post(`${apiEndpoints.company.resourceTypes}/${id}/favor`)
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    unFavoriteResourceType({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .post(`${apiEndpoints.company.resourceTypes}/${id}/disfavor`)
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getResourceType({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      commit("setResourceTypeTranslations", []);
      httpServiceAuth
        .get(`${apiEndpoints.company.resourceTypes}/${id}`, {
          params: {
            includes: ["translations"],
          },
        })
        .then((response) => {
          commit("setResourceType", response.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getResourceAttribute({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      commit("setResourceAttributeTranslations", []);
      httpServiceAuth
        .get(`${apiEndpoints.company.resourceAttributes}/${id}`, {
          params: {
            includes: ["translations"],
          },
        })
        .then((response) => {
          commit("setResourceAttribute", response.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getResourceLinked({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.resourceLinked}/${id}`)
        .then((response) => {
          commit("setResourceLinked", response.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getResourceDistances({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.resourceDistances}/${id}`)
        .then((response) => {
          commit("setResourceDistances", response.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getResourceCalendars({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.resourceCalendars}`)
        .then((response) => {
          response.data.data.forEach((element) => {
            element.level_parents.reverse();
          });
          commit("setResourceCalendars", response.data.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getResourceEvents({ commit }, eventDetails) {
      commit("loader/setScreenLoading", true, { root: true });
      commit("setResourceEventName", eventDetails[1]);
      if (!eventDetails[0].email) {
        const promises = [];
        const resourceCalendars = this.state.resource.resourceCalendars;
        const resourceEvents = [];
        resourceCalendars.forEach((resourceCalendar) => {
          const setEventDetails = eventDetails[0];
          setEventDetails.email = resourceCalendar.email;
          const promise = new Promise((resolve) => {
            httpServiceAuth
              .post(`${apiEndpoints.company.resourceEvents}`, setEventDetails)
              .then((response) => {
                response.data.data.forEach((element) => {
                  element.resourceName = resourceCalendar.name;
                });
                resourceEvents.push(...response.data.data);
              })
              .catch((error) => {
                if (error.response) {
                  errorHandler(error.response);
                }
              })
              .finally(() => {
                resolve(true);
              });
          });
          promises.push(promise);
        });
        Promise.all(promises).then(() => {
          commit("setResourceEventSyncInit", true);
          commit("setResourceEvents", resourceEvents);
          router.push({
            name: "r_event-list",
          });
          commit("loader/setScreenLoading", false, { root: true });
        });
      } else {
        httpServiceAuth
          .post(`${apiEndpoints.company.resourceEvents}`, eventDetails[0])
          .then((response) => {
            commit("setResourceEventSyncInit", false);
            commit("setResourceEvents", response.data.data);
          })
          .catch((error) => {
            if (error.response) {
              errorHandler(error.response);
            }
          })
          .finally(() => {
            router.push({
              name: "r_event-list",
            });
            commit("loader/setScreenLoading", false, { root: true });
          });
      }
    },
    setResourceEventSyncMessages({ commit }, payload) {
      commit("setResourceEventSyncMessages", payload);
    },
    getResourceImage({ commit }, id) {
      return httpServiceAuth
        .get(`${apiEndpoints.company.resources}/${id}/image`)
        .then((response) => {
          commit("setResourceImage", response.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    getResourceFilters({ commit }, payload) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.resources}/filters`, {
          params: payload,
        })
        .then((response) => {
          commit("setFilterResourceTypes", response.data.data.resource_types);
          commit(
            "setFilterResourceAttributes",
            response.data.data.resource_attributes
          );
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
  },
};
