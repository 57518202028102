export default {
  namespaced: true,
  state: {
    step: 1
  },
  getters: {},
  mutations: {
    setStep(state, payload) {
      state.step = payload;
    }
  },
  actions: {
    setStep({ commit }, payload) {
      commit("setStep", payload);
    }
  }
};
