// Storing in variable a context with all files in this folder
// ending with `.js`.
const requireModule = require.context("./modules", false, /\.js$/);
const modules = [];

requireModule.keys().forEach(fileName => {
  if (fileName === "./index.js") return;

  const module = requireModule(fileName).default;
  // create a dynamic object with all modules
  if (module.length) {
    module.forEach(mod => modules.push(mod));
  } else {
    modules.push(module);
  }
});

export default modules;
