export default [
  {
    path: "/qrcode/:code",
    name: "r_qr-code-image",
    meta: {},
    component: () => import("../../components/qr-codes/ImageAndRead/Image.vue")
  },
  {
    path: "/qrcode-read/:code",
    name: "r_qr-code-read",
    meta: {},
    component: () => import("../../components/qr-codes/ImageAndRead/Read.vue")
  },
  {
    path: "/qr-codes",
    name: "r_qr-codes",
    meta: {
      middlewares: {
        auth: null,
        permission: "QR_CODE"
      }
    },
    component: () => import("../../components/qr-codes/QrCodes"),
    children: [
      {
        path: "edit/:qrCodeId",
        name: "r_edit-qr-code",
        meta: {
          middlewares: {
            auth: null,
            permission: "QR_CODE"
          }
        },
        props: {
          backLinkName: "r_qr-codes"
        },
        component: () => import("../../components/qr-codes/QrCodes/Edit"),
        children: [
          {
            path: "template",
            name: "r_select-qr-code-template",
            meta: {
              middlewares: {
                auth: null,
                permission: "QR_CODE"
              }
            },
            props: {
              backLinkName: "r_edit-qr-code"
            },
            component: () =>
              import("../../components/qr-codes/QrCodes/Templates"),
            children: []
          }
        ]
      },
      {
        path: "filters",
        name: "r_qr-code-filters",
        meta: {
          middlewares: {
            auth: null,
            permission: "QR_CODE"
          }
        },
        component: () => import("../../components/qr-codes/QrCodesFilter")
      }
    ]
  }
];
