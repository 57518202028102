import httpServiceAuth, { getSubdomain } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    currencies: null,
    selectedCurrency: null
  },
  mutations: {
    setCurrencyData(state, payload) {
      state.currencies = payload;
    },
    setSelectedCurrency(state, payload) {
      state.selectedCurrency = payload;
    }
  },
  actions: {
    getCurrencies({ commit }) {
      let url = `${apiEndpoints.master.currencies}/all`;
      if (getSubdomain()) {
        url = `${apiEndpoints.company.currencies}/all`;
      }
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setCurrencyData", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    setCurrencyData({ commit }, payload) {
      commit("setCurrencyData", payload);
    },
    setSelectedCurrency({ commit }, payload) {
      commit("setSelectedCurrency", payload);
    }
  }
};
