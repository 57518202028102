import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import store from "@/store";

export default {
  state: {
    intranets: null,
    selectedIntranet: null,
    intranet: null
  },
  mutations: {
    setIntranets(state, payload) {
      state.intranets = payload;
    },
    setSelectedIntranet(state, payload) {
      state.selectedIntranet = payload;
    },
    setIntranet(state, payload) {
      state.intranet = payload;
    }
  },
  actions: {
    /**
     * Get intranets
     */
    getIntranets({ state }) {
      store.dispatch("pagination/paginate", {
        whatToCall: "intranet/getIntranets",
        whereToSave: "intranet/setIntranets",
        url: apiEndpoints.company.intranet,
        data: state.intranets,
        params: {}
      });
    },
    /**
     * Set intranets
     */
    setIntranets({ commit }, payload) {
      commit("setIntranets", payload);
    },
    /**
     * Create intranet
     */
    createIntranet({ commit, dispatch }, values) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.intranet}`;
      return httpServiceAuth
        .post(url, values)
        .then(() => {
          dispatch("getIntranets");
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedIntranet({ commit }, payload) {
      commit("setSelectedIntranet", payload);
    },
    /**
     * Get single intranet
     */
    getIntranet({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.intranet}/${id}`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setIntranet", response.data.data);
          return response.data.data;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    /**
     * Update intranet
     */
    updateIntranet({ commit, dispatch }, { data, id }) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.intranet}/${id}`;

      return httpServiceAuth
        .put(url, data)
        .then(response => {
          commit("setIntranet", response.data.data);
          dispatch("getIntranets");
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    /**
     * Delete Intranet
     */
    deleteIntranet({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .delete(`${apiEndpoints.company.intranet}/${id}`)
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    }
  }
};
