import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    widgets: null,
    widgetTypes: null,
    widgetItems: {}
  },
  mutations: {
    setWidgets(state, payload) {
      state.widgets = payload;
    },
    setWidgetTypes(state, payload) {
      state.widgetTypes = payload;
    },
    setWidgetItems(state, payload) {
      state.widgetItems = payload;
    }
  },
  actions: {
    getWidgets({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.cockpitWidgets}`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setWidgets", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getWidgetItems({ commit, state }, type) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.cockpitWidgetItems}/${
        state.widgets.find(el => el.type === type).id
      }`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          let widgetItems = { ...state.widgetItems };
          widgetItems[type] = response.data;
          widgetItems[type].sort((a, b) => a.order - b.order);
          commit("setWidgetItems", widgetItems);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getWidgetItem({ commit, state }, { type, itemId }) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.cockpitWidgetItems}/${
        state.widgets.find(el => el.type === type).id
      }/${itemId}`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          let widgetItems = { ...state.widgetItems };
          widgetItems[type] = widgetItems[type].map(obj =>
            response.data.id === obj.id ? response.data : obj
          );
          widgetItems[type].sort((a, b) => a.order - b.order);
          commit("setWidgetItems", widgetItems);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getWidgetsTypes({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.cockpitWidgetTypes}`;
      httpServiceAuth
        .get(url)
        .then(response => {
          let widgets = [];
          response.data.data.forEach(element => {
            element.is_active = element.is_active ? true : false;
            widgets.push(element);
          });
          commit("setWidgetTypes", widgets);
          return widgets;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setUserWidget({ commit, dispatch }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.cockpitWidgetActivate}/${id}`;
      return httpServiceAuth
        .post(url)
        .then(() => {
          dispatch("getWidgets");
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setWidgetsOrder({ commit }, widgets) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.cockpitWidgetsOrder}`;
      return httpServiceAuth
        .post(url, { order: widgets })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setActiveWidgetItem({ state, commit, dispatch }, { itemId, widgetType }) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.cockpitWidgetItems}/${
        state.widgets.find(el => el.type === widgetType).id
      }`;
      return httpServiceAuth
        .post(url, { id: itemId })
        .then(() => {
          dispatch("getWidgetItems", widgetType);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setWidgetItemsOrder({ state, commit, dispatch }, { items, widgetType }) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.cockpitWidgetItems}/order/${
        state.widgets.find(el => el.type === widgetType).id
      }`;
      return httpServiceAuth
        .post(url, { order: items })
        .then(() => {
          dispatch("getWidgetItems", widgetType);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    }
  }
};
