export default {
  path: "/devices",
  name: "r_devices",
  meta: {
    middlewares: {
      auth: null,
      permission: "DEVICES"
    }
  },
  component: () => import("../../components/devices/Devices.vue"),
  children: [
    {
      path: "users",
      name: "r_devices-users",
      middlewares: {
        auth: null,
        permission: "DEVICES"
      },
      component: () => import("../../components/devices/Users.vue")
    }
  ]
};
