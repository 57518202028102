import { apiEndpoints } from "@/services/constants";
import httpServiceAuth from "@/services/http-service";
import { errorHandler } from "@/services/error-handler";

export default {
  namespaced: true,
  state: {
    userTimePreferences: null,
    availableSlots: null
  },
  mutations: {
    setUserTimePreferences(state, payload) {
      state.userTimePreferences = payload;
    },
    setAvailableSlots(state, payload) {
      state.availableSlots = payload;
    }
  },
  getters: {
    globalDateFormat(state) {
      const { userTimePreferences } = state;
      let dateFormatPreference;
      if (userTimePreferences && userTimePreferences.length) {
        dateFormatPreference = userTimePreferences.filter(
          item => item.preference === "DATE_FORMAT"
        );
      }
      if (dateFormatPreference) {
        if (dateFormatPreference[0].preference_user_values.length) {
          return dateFormatPreference[0].preference_user_values[0].value;
        } else {
          return dateFormatPreference[0].preference_values[0].value;
        }
      }
      return null;
    },
    globalTimeFormat(state) {
      const { userTimePreferences } = state;
      let dateFormatPreference;
      if (userTimePreferences && userTimePreferences.length) {
        dateFormatPreference = userTimePreferences.filter(
          item => item.preference === "TIME_FORMAT"
        );
      }

      if (dateFormatPreference) {
        let format;
        if (dateFormatPreference[0].preference_user_values.length) {
          format = dateFormatPreference[0].preference_user_values[0].value;
        } else {
          format = dateFormatPreference[0].preference_values[0].value;
        }
        if (format.indexOf("h") !== -1) {
          format = format + " A";
        }
        return format;
      }
      return null;
    }
  },
  actions: {
    getAvailableSlots({ commit }) {
      return httpServiceAuth
        .get(apiEndpoints.company.slotsAvailable)
        .then(response => {
          commit("setAvailableSlots", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    setUserTimePreferences({ commit }, payload) {
      commit("setUserTimePreferences", payload);
    }
  }
};
