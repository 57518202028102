export default [
  {
    path: "/two-factor-authentication",
    name: "two-factor-authentication",
    meta: {
      middlewares: {
        auth: null
      }
    },
    component: () => import("../../components/global/TwoFactorAuthentication"),
    children: [
      {
        path: "enable-2fa",
        name: "r_add-2fa",
        meta: {
          middlewares: {
            auth: null
          }
        }
      },
      {
        path: "disable-2fa",
        name: "r_delete-2fa",
        meta: {
          middlewares: {
            auth: null
          }
        }
      }
    ]
  }
];
