export default {
  path: "/cx-translations",
  name: "r_translations",
  meta: {
    middlewares: {
      auth: null,
      permission: false
    }
  },
  component: () => import("../../components/translations/Translations"),
  children: [
    {
      path: "select-language",
      name: "r_translations-select-language",
      meta: {
        middlewares: {
          auth: null,
          permission: false
        }
      },
      props: { backLinkName: "r_translations" },
      component: () => import("../../components/global/LanguageSelection")
    },
    {
      path: "sections",
      name: "r_translations-select-section",
      meta: {
        middlewares: {
          auth: null,
          permission: false
        }
      },
      component: () => import("../../components/translations/Sections"),
      children: [
        {
          path: "pages",
          name: "r_translations-select-page",
          meta: {
            middlewares: {
              auth: null,
              permission: false
            }
          },
          component: () => import("../../components/translations/Pages"),
          children: [
            {
              path: "labels",
              name: "r_translations-select-label",
              meta: {
                middlewares: {
                  auth: null,
                  permission: false
                }
              },
              component: () => import("../../components/translations/Labels")
            },
            {
              path: "label",
              name: "r_selected-label",
              meta: {
                middlewares: {
                  auth: null,
                  permission: false
                }
              },
              component: () => import("../../components/translations/Label")
            }
          ]
        },
        {
          path: "page",
          name: "r_selected-page",
          meta: {
            middlewares: {
              auth: null,
              permission: false
            }
          },
          component: () => import("../../components/translations/Page")
        }
      ]
    },
    {
      path: "section",
      name: "r_selected-section",
      meta: {
        middlewares: {
          auth: null,
          permission: false
        }
      },
      component: () => import("../../components/translations/Section")
    },
    {
      path: "add-translation",
      name: "r_add-translation",
      meta: {
        middlewares: {
          auth: null,
          permission: false
        }
      },
      component: () => import("../../components/translations/AddTranslation")
    },
    {
      path: "edit-translation",
      name: "r_edit-translation",
      meta: {
        middlewares: {
          auth: null,
          permission: false
        }
      },
      component: () => import("../../components/translations/EditTranslation")
    }
  ]
};
