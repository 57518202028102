export default {
  path: "/containers-users-manager",
  name: "r_containers-manager-users",
  meta: {
    middlewares: {
      auth: null,
      permission: "USER_MANAGER_CONTAINERS"
    }
  },
  props: {
    containerType: "USER_MANAGER",
    backLinkName: "r_containers-manager-users"
  },
  component: () => import("../../components/containers/Containers"),
  children: [
    {
      path: "add-container",
      name: "r_add-user-manage-container",
      meta: {
        middlewares: {
          auth: null,
          permission: "USER_MANAGER_CONTAINERS"
        }
      },
      props: {
        backLinkName: "r_containers-manager-users",
        containerType: "USER_MANAGER"
      },
      component: () => import("../../components/containers/AddContainer")
    },
    {
      path: "edit-container",
      name: "r_edit-user-manage-container",
      meta: {
        middlewares: {
          auth: null,
          permission: "USER_MANAGER_CONTAINERS"
        }
      },
      props: {
        backLinkName: "r_containers-manager-users",
        containerType: "USER_MANAGER"
      },
      component: () => import("../../components/containers/EditContainer"),
      children: [
        {
          path: "add-users-to-container",
          name: "r_edit-user-add-users-manager-to-container",
          meta: {
            middlewares: {
              auth: null,
              permission: "USER_MANAGER_CONTAINERS"
            }
          },
          props: {
            backLinkName: "r_edit-user-manage-container",
            containerType: "USER_MANAGER"
          },
          component: () =>
            import("../../components/containers/ContainersAddUser")
        },
        {
          path: "add-roles-to-container",
          name: "r_edit-user-manage-container-add-roles-to-container",
          meta: {
            middlewares: {
              auth: null,
              permission: "USER_MANAGER_CONTAINERS"
            }
          },
          props: {
            backLinkName: "r_edit-user-manage-container",
            containerType: "USER_MANAGER"
          },
          component: () =>
            import("../../components/containers/ContainersAddRole")
        }
      ]
    }
  ]
};
