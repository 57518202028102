// import { errorHandler } from "@/services/error-handler";
import httpServiceAuth from "@/services/http-service";
import store from "@/store";

export default {
  state: {
    paginationData: null
  },
  mutations: {
    setPaginationData(state, payload) {
      state.paginationData = payload;
    }
  },
  actions: {
    paginate(
      { commit, state },
      { whatToCall, whereToSave, url, data, params }
    ) {
      let pagination = "";
      if (!state.paginationData) {
        pagination = "&page=1";
        commit("loader/setScreenLoading", true, { root: true });
      } else {
        pagination = `&page=${state.paginationData.current_page + 1}`;
      }

      let delimiter = url.includes("?") ? "&" : "?";
      return httpServiceAuth
        .get(`${url}${delimiter}per_page=200${pagination}`, {
          params: params
        })
        .then(response => {
          store.dispatch("pagination/setPaginationData", response.data.meta);
          if (state.paginationData.current_page == 1) {
            commit("loader/setScreenLoading", false, { root: true });
            store.commit(whereToSave, response.data.data);
          } else {
            const newData = [...data, ...response.data.data];
            store.commit(whereToSave, newData);
          }

          if (
            response.data.meta.current_page < 3 &&
            response.data.meta.last_page &&
            response.data.meta.last_page > response.data.meta.current_page
          ) {
            store.dispatch(whatToCall, params);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setPaginationData({ commit }, payload) {
      commit("setPaginationData", payload);
    }
  }
};
