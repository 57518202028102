import dayjs from "dayjs";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  namespaced: true,
  state: {
    declarations: null,
    startDate: dayjs(new Date(new Date().setHours(0, 0, 0, 0))).format(
      "YYYY-MM-DD"
    ),
    selectedDeclaration: null
  },
  getters: {},
  mutations: {
    setDeclarationsData(state, payload) {
      state.declarations = payload;
    },
    setSelectedDeclaration(state, payload) {
      state.selectedDeclaration = payload;
    },
    setStartDate(state, payload) {
      state.startDate = dayjs(payload).format("YYYY-MM-DD");
    }
  },
  actions: {
    getDeclarations({ state, commit }, { startDate, noLoaders }) {
      if (!noLoaders) {
        commit("loader/setScreenLoading", true, { root: true });
      }
      const date = startDate || state.startDate;
      httpServiceAuth
        .get(apiEndpoints.company.declarations, {
          params: {
            date_from: date,
            includes: ["all_parents"]
          }
        })
        .then(response => {
          commit("setDeclarationsData", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedDeclaration({ commit }, payload) {
      commit("setSelectedDeclaration", payload);
    },
    setStartDate({ commit }, payload) {
      commit("setStartDate", payload);
    },
    deleteDeclaration({ state, dispatch, commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .delete(`${apiEndpoints.company.declarations}/${id}`)
        .then(() => commit("setSelectedDeclaration", null))
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
          dispatch("getDeclarations", state.startDate);
        });
    }
  }
};
