export default {
  path: "/help-and-documents",
  name: "r_help-and-documents",
  meta: {
    middlewares: {
      auth: null
    }
  },
  component: () =>
    import("../../components/help-and-documents/HelpAndDocuments")
};
