export default {
  path: "/reports",
  name: "r_reports",
  meta: {
    middlewares: {
      auth: null,
      permission: "REPORTS",
    },
  },
  component: () => import("../../components/reports/Reports"),
  children: [
    {
      path: ":report",
      name: "r_report",
      meta: {
        middlewares: {
          auth: null,
          permission: "REPORTS",
        },
      },
      props: { backLinkName: "r_reports" },
      component: () => import("../../components/reports/Report"),
      children: [
        {
          path: "auto-release",
          name: "r_report-auto-release",
          meta: {
            middlewares: {
              auth: null,
              permission: "REPORTS",
            },
          },
          props: { backLinkName: "r_report" },
          component: () => import("../../components/reports/AutoRelease"),
          children: [
            {
              path: "search-filters",
              name: "r_report-auto-release-search-filters",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "REPORTS",
                },
              },
              props: {
                backLinkName: "r_report-auto-release",
              },
              component: () => import("../../components/search/SearchFilters"),
            },
          ],
        },
      ],
    },
    {
      path: "help",
      name: "r_reports-help-online",
      meta: {
        middlewares: {
          auth: null,
          permission: "REPORTS",
        },
      },
      props: {
        backLinkName: "r_reports-types",
        slug: "reports",
      },
      component: () => import("../../components/help-online/HelpOnline"),
    },
  ],
};
