import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import globalMixin from "@/services/mixins/global/global";
import { DateTime, Interval } from "luxon";

export default {
  state: {
    settings: null,
    payload: null,
    countData: null,
    weekdays: null
  },
  mutations: {
    setSettings(state, payload) {
      state.settings = payload;
    },
    setPayload(state, payload) {
      state.payload = payload;
    },
    setCountData(state, payload) {
      state.countData = payload;
    },
    setWeekdays(state, payload) {
      state.weekdays = payload;
    }
  },
  actions: {
    getSettings({ commit }) {
      return httpServiceAuth
        .get(apiEndpoints.company.countSettings)
        .then(response => {
          commit("setSettings", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    setSettings({ commit }, settingsObject) {
      commit("loader/setScreenLoading", true, { root: true });
      let postObject = JSON.parse(JSON.stringify(settingsObject));
      postObject.demo = postObject.demo ? 1 : 0;
      return httpServiceAuth
        .post(apiEndpoints.company.countSettings, postObject)
        .then(response => {
          commit(
            "setSettings",
            response.data.data.length ? response.data.data[0] : null
          );
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    getFilters({ commit, dispatch }, section) {
      const url = `${apiEndpoints.company.countFilters}${
        section ? "?section=" + section : ""
      }`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          let filters = JSON.parse(JSON.stringify(response.data)).filters;
          let filterPresets = JSON.parse(JSON.stringify(response.data))
            .filterPresets;
          commit(
            "searchFilters/setShowAddPresetButton",
            response.data.demo ? false : true,
            {
              root: true
            }
          );
          commit("searchFilters/setFilterPage", "count", {
            root: true
          });
          commit("searchFilters/setFilterSection", section, {
            root: true
          });
          if (filterPresets && filterPresets.length) {
            filterPresets.forEach(pr => {
              if (section === "min_max_average") {
                pr.filters.forEach(el => {
                  if (el.slug === "dates" && el.date_start && el.date_end) {
                    const start = DateTime.fromISO(el.date_start);
                    const end = DateTime.fromISO(el.date_end);
                    el.hoursInterval = Interval.fromDateTimes(
                      start,
                      end
                    ).length("hours");
                  }
                });
              }
            });
            commit("searchFilters/setFilterPresets", filterPresets, {
              root: true
            });
          }
          if (filters && filters.length) {
            if (section === "min_max_average") {
              filters.forEach(el => {
                if (el.slug === "dates" && el.date_start && el.date_end) {
                  const start = DateTime.fromISO(el.date_start);
                  const end = DateTime.fromISO(el.date_end);
                  el.hoursInterval = Interval.fromDateTimes(start, end).length(
                    "hours"
                  );
                }
              });
            }
            commit("searchFilters/setFilters", filters, {
              root: true
            });
          }
          dispatch("searchFilters/selectFilterPreset", false, {
            root: true
          });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    getExport({ commit, dispatch, state }, type) {
      commit("loader/setScreenLoading", true, { root: true });
      dispatch("setPayload").then(() => {
        let payload = state.payload;
        payload.section = type;
        return httpServiceAuth
          .post(apiEndpoints.company.countExport, state.payload, {
            exposedHeaders: ["Content-Disposition"]
          })
          .then(response => {
            let blob = new Blob(["\ufeff" + response.data], {
              type: response.headers["content-type"]
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            const contentDispositionHeader =
              response.headers["content-disposition"];
            let fileName = "CurrentExport.csv";
            if (contentDispositionHeader) {
              const headerFileName = globalMixin.methods.extractHeaderFileName(
                contentDispositionHeader
              );
              fileName = headerFileName ? headerFileName : fileName;
            }
            link.download = fileName;
            link.click();
            link.remove();
          })
          .catch(error => {
            if (error.response) {
              errorHandler(error.response);
            }
          })
          .finally(() => {
            commit("loader/setScreenLoading", false, { root: true });
          });
      });
    },
    setPayload({ commit, rootState, state }, section) {
      const filters = rootState.searchFilters.filters;
      if (filters && filters.length) {
        const payloadObject = {
          query: rootState.search.searchQuery,
          location_ids: [],
          resource_ids: [],
          type_ids: [],
          capacity: 0,
          alarm: [],
          days: state.weekdays
        };
        if (section) {
          payloadObject.section = section;
        }
        filters
          .filter(
            filter =>
              (filter.selected && filter.value === undefined) ||
              (filter.value && filter.value > 0)
          )
          .forEach(filter => {
            switch (filter.slug) {
              case "locations":
                payloadObject.location_ids.push(filter.id);
                break;
              case "resources":
                payloadObject.resource_ids.push(filter.id);
                break;
              case "types":
                payloadObject.type_ids.push(filter.id);
                break;
              case "capacity":
                payloadObject.capacity = filter.value;
                break;
              case "dates":
                payloadObject.datetime_from = filter.date_start;
                payloadObject.datetime_to = filter.date_end;
                break;
              case "intervals":
                payloadObject.interval = filter.id;
                break;
              case "alarm":
                payloadObject.alarm.push(filter.id);
                break;

              default:
                break;
            }
          });
        commit("setPayload", payloadObject);
      }
    },
    // calculate method calls count/calculate EP that responds with data for count pages (Dashboard and Reports)
    calculate({ commit, dispatch, state }, { section, initial = false }) {
      commit("loader/setScreenLoading", true, { root: true });
      commit("setCountData", null);
      if (!initial) {
        dispatch("setPayload", section).then(() => {
          return httpServiceAuth
            .post(apiEndpoints.company.countCalculate, state.payload)
            .then(response => {
              commit("setCountData", response.data);
            })
            .catch(error => {
              if (error.response) {
                errorHandler(error.response);
              }
            })
            .finally(() => {
              commit("loader/setScreenLoading", false, { root: true });
            });
        });
      } else {
        return httpServiceAuth
          .post(apiEndpoints.company.countCalculate, { section: section })
          .then(response => {
            commit("setCountData", response.data);
          })
          .catch(error => {
            if (error.response) {
              errorHandler(error.response);
            }
          })
          .finally(() => {
            commit("loader/setScreenLoading", false, { root: true });
          });
      }
    }
  }
};
