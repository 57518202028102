export default [
  {
    path: "/profile",
    name: "r_profile",
    meta: {
      middlewares: {
        auth: null,
        permission: ["USER_PROFILE", "read"]
      }
    },
    component: () => import("../../components/profile/Profile"),
    children: [
      {
        path: "language",
        name: "r_language",
        meta: {
          middlewares: {
            auth: null,
            permission: ["USER_PROFILE", "read"]
          }
        },
        props: { backLinkName: "r_profile" },
        component: () => import("../../components/global/LanguageSelection")
      },
      {
        path: "time-zone",
        name: "r_time-zone",
        meta: {
          middlewares: {
            auth: null,
            permission: ["USER_PROFILE", "read"]
          }
        },
        props: { backLinkName: "r_profile" },
        component: () => import("../../components/global/TimezoneSelection")
      },
      {
        path: "change-password",
        name: "r_change-profile-password",
        meta: {
          middlewares: {
            auth: null,
            permission: ["USER_PROFILE", "read"]
          }
        },
        component: () =>
          import("../../components/change-password/ChangePassword")
      },
      {
        path: "groups",
        name: "r_groups",
        meta: {
          middlewares: {
            auth: null,
            permission: ["USER_PROFILE", "read"]
          }
        },
        component: () => import("../../components/groups/Groups")
      },
      {
        path: "roles",
        name: "r_roles",
        meta: {
          middlewares: {
            auth: null,
            permission: ["USER_PROFILE", "read"]
          }
        },
        component: () => import("../../components/roles/Roles")
      },
      {
        path: "select-country",
        name: "r_profile-select-country",
        meta: {
          middlewares: {
            auth: null,
            permission: ["USER_PROFILE", "read"]
          }
        },
        props: {
          backLinkName: "r_profile"
        },
        component: () => import("../../components/global/CountriesSelection")
      },
      {
        path: "sync-with-outlook",
        name: "r_sync-with-outlook",
        meta: {
          middlewares: {
            auth: null,
            permission: ["USER_PROFILE", "read"]
          }
        },
        props: { backLinkName: "r_profile" },
        component: () => import("../../components/profile/SyncWithOutlook"),
        children: [
          {
            path: "help",
            name: "r_profile-help-online-sync-outlook",
            meta: {
              middlewares: {
                auth: null,
                permission: ["USER_PROFILE", "read"]
              }
            },
            props: {
              backLinkName: "r_sync-with-outlook",
              slug: "profile-sync"
            },
            component: () => import("../../components/help-online/HelpOnline")
          }
        ]
      },
      {
        path: "sync-with-google",
        name: "r_sync-with-google",
        meta: {
          middlewares: {
            auth: null,
            permission: ["USER_PROFILE", "read"]
          }
        },
        props: { backLinkName: "r_profile" },
        component: () => import("../../components/profile/GoogleCalendarAuth"),
        children: [
          {
            path: "help",
            name: "r_profile-help-online-sync-google",
            meta: {
              middlewares: {
                auth: null,
                permission: ["USER_PROFILE", "read"]
              }
            },
            props: {
              backLinkName: "r_sync-with-google",
              slug: "profile-sync"
            },
            component: () => import("../../components/help-online/HelpOnline")
          }
        ]
      },
      {
        path: "profile-photo",
        name: "r_profile-change-photo",
        meta: {
          middlewares: {
            auth: null,
            permission: ["USER_PROFILE", "read"]
          }
        },
        props: { backLinkName: "r_profile" },
        component: () => import("../../components/profile-photo/ProfilePhoto"),
        children: [
          {
            path: "help",
            name: "r_profile-help-online-photo",
            meta: {
              middlewares: {
                auth: null,
                permission: ["USER_PROFILE", "read"]
              }
            },
            props: {
              backLinkName: "r_profile-change-photo",
              slug: "profile-photo"
            },
            component: () => import("../../components/help-online/HelpOnline")
          }
        ]
      },
      {
        path: "assigned-resources",
        name: "r_profile-assigned-resources",
        meta: {
          middlewares: {
            auth: null,
            permission: ["USER_PROFILE", "read"]
          }
        },
        props: { backLinkName: "r_profile" },
        component: () => import("../../components/profile/AssignedResources"),
        children: [
          {
            path: ":assignedResourceId",
            name: "r_profile-edit-assigned-resources",
            meta: {
              middlewares: {
                auth: null,
                permission: ["USER_PROFILE", "read"]
              }
            },
            props: { backLinkName: "r_profile-assigned-resources" },
            component: () =>
              import("../../components/profile/EditAssignedResource"),
            children: [
              {
                path: "add-break",
                name: "r_profile-add-break",
                meta: {
                  middlewares: {
                    auth: null,
                    permission: ["USER_PROFILE", "read"]
                  }
                },
                props: { backLinkName: "r_profile-edit-assigned-resources" },
                component: () =>
                  import("../../components/profile/AssignedResourceBreak")
              },
              {
                path: ":breakId",
                name: "r_profile-edit-break",
                meta: {
                  middlewares: {
                    auth: null,
                    permission: ["USER_PROFILE", "read"]
                  }
                },
                props: {
                  backLinkName: "r_profile-edit-assigned-resources",
                  actionType: "edit"
                },
                component: () =>
                  import("../../components/profile/AssignedResourceBreak")
              }
            ]
          },
          {
            path: "help",
            name: "r_profile-help-online-assigned",
            meta: {
              middlewares: {
                auth: null,
                permission: ["USER_PROFILE", "read"]
              }
            },
            props: {
              backLinkName: "r_profile-assigned-resources",
              slug: "profile-assigned"
            },
            component: () => import("../../components/help-online/HelpOnline")
          }
        ]
      },
      {
        path: "my-delegates",
        name: "r_profile-my-delegates",
        meta: {
          middlewares: {
            auth: null,
            permission: ["USER_PROFILE", "read"]
          }
        },
        props: { backLinkName: "r_profile" },
        component: () => import("../../components/profile/MyDelegates"),
        children: [
          {
            path: "add",
            name: "r_profile-my-delegates-add",
            meta: {
              middlewares: {
                auth: null,
                permission: ["USER_PROFILE", "read"]
              }
            },
            props: { backLinkName: "r_profile-my-delegates" },
            component: () => import("../../components/profile/MyDelegatesAdd"),
            children: [
              {
                path: ":userId",
                name: "r_profile-my-delegates-add-permission",
                meta: {
                  middlewares: {
                    auth: null,
                    permission: ["USER_PROFILE", "read"]
                  }
                },
                props: { backLinkName: "r_profile-my-delegates-add" },
                component: () =>
                  import("../../components/profile/MyDelegatesAddPermission")
              }
            ]
          },
          {
            path: ":delegationId",
            name: "r_profile-my-delegates-update",
            meta: {
              middlewares: {
                auth: null,
                permission: ["USER_PROFILE", "read"]
              }
            },
            props: { backLinkName: "r_profile-my-delegates" },
            component: () =>
              import("../../components/profile/MyDelegatesUpdate")
          },
          {
            path: "help",
            name: "r_profile-help-online-delegates",
            meta: {
              middlewares: {
                auth: null,
                permission: ["USER_PROFILE", "read"]
              }
            },
            props: {
              backLinkName: "r_profile-my-delegates",
              slug: "profile-delegates"
            },
            component: () => import("../../components/help-online/HelpOnline")
          }
        ]
      },
      {
        path: "delegated-by",
        name: "r_profile-delegated-by",
        meta: {
          middlewares: {
            auth: null,
            permission: ["USER_PROFILE", "read"]
          }
        },
        props: { backLinkName: "r_profile" },
        component: () => import("../../components/profile/DelegatedBy"),
        children: [
          {
            path: "help",
            name: "r_profile-help-online-delegations",
            meta: {
              middlewares: {
                auth: null,
                permission: ["USER_PROFILE", "read"]
              }
            },
            props: {
              backLinkName: "r_profile-delegated-by",
              slug: "profile-delegations"
            },
            component: () => import("../../components/help-online/HelpOnline")
          }
        ]
      },
      {
        path: "help",
        name: "r_profile-help-online",
        meta: {
          middlewares: {
            auth: null,
            permission: ["USER_PROFILE", "read"]
          }
        },
        props: {
          backLinkName: "r_profile",
          slug: "profile"
        },
        component: () => import("../../components/help-online/HelpOnline")
      }
    ]
  }
];
