import { mapState, mapActions } from "vuex";
import { getSubdomain } from "@/services/http-service";

export default {
  created() {
    const subdomain = getSubdomain();
    if (subdomain) {
      this.getHelpOnline(this.helpSlug);
    }
  },
  computed: {
    ...mapState("helpOnline", ["helpOnline"])
  },
  methods: {
    ...mapActions("helpOnline", ["getHelpOnline"])
  }
};
