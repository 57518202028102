import httpServiceAuth, {
  getLang,
  getSubdomain,
  setLang
} from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    languages: null,
    selectedLanguage: null
  },
  mutations: {
    setLanguageData(state, payload) {
      state.languages = payload;
    },
    setSelectedLanguage(state, payload) {
      state.selectedLanguage = payload;
    }
  },
  actions: {
    getLanguages({ commit }) {
      let url = `${apiEndpoints.master.languages}/all`;
      if (getSubdomain()) {
        url = `${apiEndpoints.company.languages}/all`;
      }
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setLanguageData", response.data);
          const userLangIdInStorage = localStorage.getItem("appUserLangId");
          const langInStorage = getLang();
          if (userLangIdInStorage) {
            const { data } = response.data;
            if (data && data.length) {
              const filtered = data.filter(
                item => parseInt(userLangIdInStorage, 10) === item.id
              );
              if (filtered && filtered.length) {
                if (filtered[0].locale !== langInStorage) {
                  setLang(filtered[0].locale);
                  // setTimeout(() => {
                  //   window.location.reload();
                  // }, 0);
                } else {
                  setLang(langInStorage);
                }
              }
            }
          } else {
            setLang(langInStorage);
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    setSelectedLanguage({ commit }, payload) {
      commit("setSelectedLanguage", payload);
    },
    setLanguageData({ commit }, payload) {
      commit("setLanguageData", payload);
    }
  }
};
