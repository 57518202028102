import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import store from "@/store";

export default {
  state: {
    editCompanyDocument: null,
    companyDocuments: [],
    nameTranslations: [],
    contentTranslations: [],
    selectedApplications: null,
    selectedLanguage: null,
    publishDate: null,
    unacceptedCompanyDocuments: [],
    redirectRoute: null
  },
  mutations: {
    setEditCompanyDocument(state, payload) {
      state.editCompanyDocument = payload;
    },
    setCompanyDocuments(state, payload) {
      state.companyDocuments = payload;
    },
    setNameTranslations(state, payload) {
      state.nameTranslations = payload;
    },
    setSelectedLanguage(state, payload) {
      state.selectedLanguage = payload;
    },
    setContentTranslations(state, payload) {
      state.contentTranslations = payload;
    },
    setSelectedApplications(state, payload) {
      state.selectedApplications = payload;
    },
    setSelectedApplication(state, payload) {
      if (state.selectedApplications && state.selectedApplications.length) {
        const index = state.selectedApplications.findIndex(
          item => item.id === payload.id
        );
        if (index !== -1) {
          state.selectedApplications.splice(index, 1);
        } else {
          state.selectedApplications.push(payload);
        }
      } else {
        state.selectedApplications = [payload];
      }
    },
    setPublishDate(state, payload) {
      state.publishDate = payload;
    },
    setRedirectRoute(state, payload) {
      state.redirectRoute = payload;
    },
    setUnacceptedCompanyDocuments(state, payload) {
      state.unacceptedCompanyDocuments = payload;
    }
  },
  actions: {
    prepareTranslations({ commit }, payload) {
      const { translations } = payload;
      const nameTranslations = {};
      const contentTranslations = {};
      translations.forEach(t => {
        nameTranslations[t.locale] = t.attributes.name;
        contentTranslations[t.locale] = t.attributes.content;
      });
      commit("setNameTranslations", nameTranslations);
      commit("setContentTranslations", contentTranslations);
    },
    getCompanyDocuments({ state }, params) {
      store.dispatch("pagination/paginate", {
        whatToCall: "companyDocuments/getCompanyDocuments",
        whereToSave: "companyDocuments/setCompanyDocuments",
        url: apiEndpoints.company.companyDocuments,
        data: state.companyDocuments,
        params: params
      });
    },
    setCompanyDocuments({ commit }, payload) {
      commit("setCompanyDocuments", payload);
    },
    getCompanyDocument({ commit, dispatch }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.companyDocuments}/${id}`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          const companyDocument = { ...response.data.data };
          commit("setEditCompanyDocument", companyDocument);
          commit("setPublishDate", response.data.data.publish_date);
          dispatch("prepareTranslations", {
            translations: response.data.data.translations
          });
          return response.data.data;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    addCompanyDocument({ commit }, payload) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.companyDocuments}`;
      return httpServiceAuth
        .post(url, payload)
        .then(response => {
          return response.data.data;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    putEditCompanyDocument({ commit }, { id, payload }) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.companyDocuments}/${id}`;
      return httpServiceAuth
        .put(url, payload)
        .then(response => {
          return response.data.data;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    deleteCompanyDocument({ commit }, payload) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.companyDocuments}/${payload}`;
      return httpServiceAuth
        .delete(url)
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedApplication({ commit }, payload) {
      commit("setSelectedApplication", payload);
    },
    setSelectedApplications({ commit }, payload) {
      commit("setSelectedApplications", payload);
    },
    getUnacceptedCompanyDocuments({ commit }, documentId) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.companyDocuments}/unaccepted/${documentId}`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setUnacceptedCompanyDocuments", response.data.data);
          return response.data.data;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    acceptCompanyDocuments({ commit }, payload) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.userAcceptance}`;
      return httpServiceAuth
        .post(url, payload)
        .then(response => {
          return response.data.data;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setRedirectRoute({ commit }, payload) {
      commit("setRedirectRoute", payload);
    }
  }
};
