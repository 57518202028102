import httpServiceAuth, { getSubdomain } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    connectors: null,
    connectorTypes: null,
    selectedConnectorType: null,
    selectedConnector: null,
    linkedUsers: null
  },
  mutations: {
    setConnectorTypesData(state, payload) {
      state.connectorTypes = payload;
    },
    setConnectorsData(state, payload) {
      state.connectors = payload;
    },
    setSelectedConnectorType(state, payload) {
      state.selectedConnectorType = payload;
    },
    setSelectedConnector(state, payload) {
      state.selectedConnector = payload;
    },
    setLinkedUsers(state, payload) {
      state.linkedUsers = payload;
    }
  },
  actions: {
    getConnectorTypes({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      let url = `${apiEndpoints.master.connectorTypes}/all`;
      const subdomain = getSubdomain();
      if (subdomain) {
        url = `${apiEndpoints.company.connectorTypes}/all`;
      }
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setConnectorTypesData", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getConnectors({ commit }, connectorType) {
      commit("loader/setScreenLoading", true, { root: true });
      let url = `${apiEndpoints.master.connectorTypes}/${connectorType}/connectors`;
      const subdomain = getSubdomain();
      if (subdomain) {
        url = `${apiEndpoints.company.connectorTypes}/${connectorType}/connectors`;
      }
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setConnectorsData", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getLinkedUsers({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      let url = `${apiEndpoints.master.linkedUsers}`;
      const subdomain = getSubdomain();
      if (subdomain) {
        url = `${apiEndpoints.company.linkedUsers}/all`;
      }
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit(
            "setLinkedUsers",
            response.data && response.data.data.length
              ? response.data.data
              : null
          );
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedConnectorType({ commit }, payload) {
      commit("setSelectedConnectorType", payload);
    },
    setSelectedConnector({ commit }, payload) {
      commit("setSelectedConnector", payload);
    }
  }
};
