export default {
  path: "/cx-manage-companies",
  name: "r_manage-companies",
  meta: {
    middlewares: {
      auth: null
    }
  },
  component: () => import("../../components/companies/Companies"),
  children: [
    {
      path: "add-company",
      name: "r_add-company",
      meta: {
        middlewares: {
          auth: null
        }
      },
      component: () => import("../../components/companies/AddCompany"),
      children: [
        {
          path: "select-country",
          name: "r_add-company-select-country",
          meta: {
            middlewares: {
              auth: null
            }
          },
          props: {
            backLinkName: "r_add-company"
          },
          component: () => import("../../components/global/CountriesSelection")
        },
        {
          path: "select-language",
          name: "r_add-company-select-language",
          meta: {
            middlewares: {
              auth: null
            }
          },
          props: { backLinkName: "r_add-company" },
          component: () => import("../../components/global/LanguageSelection")
        },
        {
          path: "select-timezone",
          name: "r_add-company-select-timezone",
          meta: {
            middlewares: {
              auth: null
            }
          },
          props: { backLinkName: "r_add-company" },
          component: () => import("../../components/global/TimezoneSelection")
        },
        {
          path: "select-company-data-region",
          name: "r_add-company-select-data-region",
          meta: {
            middlewares: {
              auth: null
            }
          },
          props: {
            backLinkName: "r_add-company"
          },
          component: () => import("../../components/global/DataRegionSelection")
        },
        {
          path: "select-company-type",
          name: "r_add-company-select-company-type",
          meta: {
            middlewares: {
              auth: null
            }
          },
          props: {
            backLinkName: "r_add-company"
          },
          component: () =>
            import("../../components/companies/CompaniesTypeSelection")
        }
      ]
    },
    {
      path: "edit-company",
      name: "r_edit-company",
      meta: {
        middlewares: {
          auth: null
        }
      },
      component: () => import("../../components/companies/EditCompany"),
      children: [
        {
          path: "select-country",
          name: "r_edit-company-select-country",
          meta: {
            middlewares: {
              auth: null
            }
          },
          props: {
            backLinkName: "r_edit-company"
          },
          component: () => import("../../components/global/CountriesSelection")
        },
        {
          path: "select-language",
          name: "r_edit-company-select-language",
          meta: {
            middlewares: {
              auth: null
            }
          },
          props: { backLinkName: "r_edit-company" },
          component: () => import("../../components/global/LanguageSelection")
        },
        {
          path: "select-timezone",
          name: "r_edit-company-select-timezone",
          meta: {
            middlewares: {
              auth: null
            }
          },
          props: { backLinkName: "r_edit-company" },
          component: () => import("../../components/global/TimezoneSelection")
        },
        {
          path: "select-data-region",
          name: "r_edit-company-select-data-region",
          meta: {
            middlewares: {
              auth: null
            }
          },
          props: { backLinkName: "r_edit-company" },
          component: () => import("../../components/global/DataRegionSelection")
        }
      ]
    }
  ]
};
