import httpServiceAuth, { getSubdomain } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    timezones: null,
    selectedTimezone: null,
    automaticTimezoneOffset: -(new Date().getTimezoneOffset() / 60),
    automatic: false,
    unsetTimezone: false
  },
  getters: {
    automaticTimezone(state) {
      if (state.timezones) {
        return state.timezones.data.filter(
          zone => parseInt(zone.offset, 10) === state.automaticTimezoneOffset
        )[0];
      } else {
        return null;
      }
    }
  },
  mutations: {
    setTimezoneData(state, payload) {
      state.timezones = payload;
    },
    setSelectedTimezone(state, payload) {
      state.selectedTimezone = payload;
    },
    setAutomaticTimezoneSelection(state, payload) {
      state.automatic = payload;
    },
    setUnsetTimezoneStatus(state, payload) {
      state.unsetTimezone = payload;
    }
  },
  actions: {
    getTimezones({ commit }) {
      let url = `${apiEndpoints.master.timezones}/all`;
      if (getSubdomain()) {
        url = `${apiEndpoints.company.timezones}/all`;
      }
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setTimezoneData", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    setTimezoneData({ commit }, payload) {
      commit("setTimezoneData", payload);
    },
    setSelectedTimezone({ commit }, payload) {
      commit("setSelectedTimezone", payload);
      if (payload) {
        commit("setUnsetTimezoneStatus", false);
      }
    },
    setAutomaticTimezoneSelection({ commit }, payload) {
      commit("setAutomaticTimezoneSelection", payload);
    },
    setUnsetTimezoneStatus({ commit }, payload) {
      commit("setUnsetTimezoneStatus", payload);
    }
  }
};
