export default {
  path: "/about",
  name: "r_about",
  meta: {
    middlewares: {
      auth: null
    }
  },
  component: () => import("../../components/about/About"),
  children: [
    {
      path: "add-version",
      name: "r_add-version",
      meta: {
        middlewares: {
          auth: null
        }
      },
      component: () => import("../../components/about/AddVersion"),
      children: [
        {
          path: "select-application",
          name: "r_add-version-select-application",
          meta: {
            middlewares: {
              auth: null
            }
          },
          props: { backLinkName: "r_add-version" },
          component: () =>
            import("../../components/global/ApplicationsSelection")
        }
      ]
    },
    {
      path: "application-versions",
      name: "r_application-versions",
      meta: {
        middlewares: {
          auth: null
        }
      },
      component: () => import("../../components/about/AppVersions"),
      children: [
        {
          path: "application-version",
          name: "r_application-version",
          meta: {
            middlewares: {
              auth: null
            }
          },
          component: () => import("../../components/about/AppVersion")
        }
      ]
    }
  ]
};
