import httpServiceAuth, { getSubdomain } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    translations: null,
    sections: null,
    selectedSection: null,
    pages: null,
    selectedPage: null,
    labels: null,
    selectedLabel: null,
    getTranslationsPending: false
  },
  mutations: {
    setTranslations(state, payload) {
      state.translations = payload;
    },
    setSectionData(state, payload) {
      state.sections = payload;
    },
    setSelectedSection(state, payload) {
      state.selectedSection = payload;
    },
    setPageData(state, payload) {
      state.pages = payload;
    },
    setSelectedPage(state, payload) {
      state.selectedPage = payload;
    },
    setLabelData(state, payload) {
      state.labels = payload;
    },
    setSelectedLabel(state, payload) {
      state.selectedLabel = payload;
    },
    setGetTranslationsPending(state, payload) {
      state.getTranslationsPending = payload;
    }
  },
  actions: {
    getTranslations({ commit }) {
      commit("setGetTranslationsPending", true);
      commit("loader/setScreenLoading", true, { root: true });
      let url = `${apiEndpoints.master.labels}/all`;
      const subdomain = getSubdomain();
      if (subdomain) {
        url = `${apiEndpoints.master.companyLabels}/all`;
      }
      return httpServiceAuth(url)
        .then(response => {
          commit("setTranslations", response.data);
          commit("setGetTranslationsPending", false);
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getSections({ commit }, lang) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.master.sections}/all`, {
          params: {
            lang
          }
        })
        .then(response => {
          commit("setSectionData", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedSection({ commit }, payload) {
      commit("setSelectedSection", payload);
    },
    getPages({ commit }, payload) {
      commit("loader/setScreenLoading", true, { root: true });
      const { sectionId, lang } = payload;
      return httpServiceAuth
        .get(`${apiEndpoints.master.sections}/${sectionId}/labelpages`, {
          params: {
            lang
          }
        })
        .then(response => {
          commit("setPageData", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedPage({ commit }, payload) {
      commit("setSelectedPage", payload);
    },
    getLabels({ commit }, payload) {
      commit("loader/setScreenLoading", true, { root: true });
      const { pageId, lang } = payload;
      return httpServiceAuth
        .get(`${apiEndpoints.master.labelpages}/${pageId}/labels`, {
          params: {
            lang
          }
        })
        .then(response => {
          commit("setLabelData", response.data);
        })
        .catch()
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedLabel({ commit }, payload) {
      commit("setSelectedLabel", payload);
    },
    setTranslations({ commit }, payload) {
      commit("setTranslations", payload);
    }
  }
};
