import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import store from "@/store";

export default {
  state: {
    companyNotifications: null,
    companyNotification: null,
    companyNotificationTranslations: [],
    datetimeFrom: null,
    datetimeTo: null,
    day: null,
    allUsers: false,
    users: [],
    userContainers: [],
    sendTo: null,
    sendAt: "",
  },
  mutations: {
    setCompanyNotifications(state, payload) {
      state.companyNotifications = payload;
    },
    setCompanyNotification(state, payload) {
      state.companyNotification = payload;
    },
    setCompanyNotificationTranslations(state, payload) {
      state.companyNotificationTranslations = payload;
    },
    setDatetimeFrom(state, payload) {
      state.datetimeFrom = payload;
    },
    setDatetimeTo(state, payload) {
      state.datetimeTo = payload;
    },
    setDay(state, payload) {
      state.day = payload;
    },
    setAllUsers(state, payload) {
      state.allUsers = payload;
    },
    setUsers(state, payload) {
      state.users = payload;
    },
    setUserContainers(state, payload) {
      state.userContainers = payload;
    },
    setSendTo(state, payload) {
      state.sendTo = payload;
    },
    setSendAt(state, payload) {
      state.sendAt = payload;
    },
  },
  actions: {
    getCompanyNotifications({ state }, params) {
      store.dispatch("pagination/paginate", {
        whatToCall: "companyNotifications/getCompanyNotifications",
        whereToSave: "companyNotifications/setCompanyNotifications",
        url: apiEndpoints.company.companyNotifications,
        data: state.companyNotifications,
        params: params,
      });
    },
    setCompanyNotifications({ commit }, payload) {
      commit("service/setCompanyNotifications", payload, { root: true });
    },
    prepareTranslations({ commit }, payload) {
      const { translations } = payload;
      const nameTranslations = {};
      const contentTranslations = {};
      translations.forEach((t) => {
        nameTranslations[t.locale] = t.attributes.name;
        contentTranslations[t.locale] = t.attributes.content;
      });
      commit("setNameTranslations", nameTranslations);
      commit("setContentTranslations", contentTranslations);
    },
    getCompanyNotification({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      commit("setCompanyNotificationTranslations", []);
      httpServiceAuth
        .get(`${apiEndpoints.company.companyNotifications}/${id}`)
        .then((response) => {
          commit("setCompanyNotification", response.data);
          commit(
            "setCompanyNotificationTranslations",
            response.data.data.translations
          );
          commit("setDatetimeFrom", response.data.data.datetime_from);
          commit("setDatetimeTo", response.data.data.datetime_to);
          commit("setDay", response.data.data.day);
          commit("setAllUsers", response.data.data.all_users);
          commit("setUserContainers", response.data.data.user_containers);
          commit("setUsers", response.data.data.users);
          commit("setSendTo", response.data.data.send_to);
          commit("setSendAt", response.data.data.send_at);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setCompanyNotification({ commit }, payload) {
      commit("companyNotifications/setCompanyNotification", payload, {
        root: true,
      });
    },
    setCompanyNotificationTranslations({ commit }, translations) {
      commit(
        "companyNotifications/setCompanyNotificationTranslations",
        translations,
        {
          root: true,
        }
      );
    },
    addCompanyNotification({ commit }, payload) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.companyNotififications}`;
      return httpServiceAuth
        .post(url, payload)
        .then((response) => {
          return response.data.data;
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    updateCompanyNotification({ commit }, { id, payload }) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.companyNotififications}/${id}`;
      return httpServiceAuth
        .put(url, payload)
        .then((response) => {
          return response.data.data;
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    deleteCompanyNotification({ commit }, payload) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.companyNotifications}/${payload}`;
      return httpServiceAuth
        .delete(url)
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setDatetimeFrom({ commit }, payload) {
      commit("setDatetimeFrom", payload);
    },
    setDatetimeTo({ commit }, payload) {
      commit("setDatetimeTo", payload);
    },
    setDay({ commit }, payload) {
      commit("setDay", payload);
    },
    setAllUsers({ commit }, payload) {
      commit("setAllUsers", payload);
    },
    setUsers({ commit }, payload) {
      commit("setUsers", payload);
    },
    setUserContainers({ commit }, payload) {
      commit("setUserContainers", payload);
    },
    setSendTo({ commit }, payload) {
      commit("setSendTo", payload);
    },
    setSendAt({ commit }, payload) {
      commit("setSendAt", payload);
    },
  },
};
