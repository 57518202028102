import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import {
  L_VISITS_PAST,
  L_VISITS_TODAY,
  L_VISITS_FUTURE
} from "@/services/consts/modules/visitor-management/visits";

export default {
  namespaced: true,
  state: {
    visit: null,
    visits: {}
  },
  mutations: {
    setVisit(state, payload) {
      state.visit = payload;
    },
    setVisits(state, { group, visits }) {
      state.visits[group] = visits;
    }
  },
  actions: {
    postVisit({ commit }, params) {
      const url = `${apiEndpoints.company.visits}`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .post(url, params)
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    putVisit({ commit }, { visit_id, ...payload }) {
      const url = `${apiEndpoints.company.visits}/${visit_id}`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .put(url, payload)
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getVisits(
      { commit },
      { payload, groups = [L_VISITS_PAST, L_VISITS_TODAY, L_VISITS_FUTURE] }
    ) {
      commit("loader/setScreenLoading", true, { root: true });
      let promises = [];
      groups.forEach(group => {
        const url = `${apiEndpoints.company.visits}/group`;
        let data = { group: group, ...payload };
        const promise = httpServiceAuth.post(url, data).then(response => {
          const visits = response && response.data && response.data.data;
          commit("setVisits", { group, visits });
          return visits;
        });
        promises.push(promise);
      });
      return Promise.all(promises)
        .then(response => {
          return response;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setVisit({ commit }, payload) {
      commit("setVisit", payload);
    },
    getVisit({ commit }, { visitId, receptionDeskId }) {
      const url = `${apiEndpoints.company.visits}/${visitId}/${receptionDeskId}`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setVisit", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    deleteVisit({ commit }, payload) {
      const { visitId, receptionDeskId } = payload;
      const url = `${apiEndpoints.company.visits}/${visitId}/${receptionDeskId}`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .delete(url)
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    }
  }
};
