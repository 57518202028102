export default {
  state: {
    navigationInUse: "navigation"
  },
  mutations: {
    setNavigationInUse(state, payload) {
      state.navigationInUse = payload;
    }
  }
};
