export default {
  path: "/permissions-user",
  name: "r_permissions-user",
  meta: {
    middlewares: {
      auth: null,
      permission: false
    }
  },
  props: {
    containerType: "USER",
    backLinkName: "r_permissions-user"
  },
  component: () => import("../../components/permissions/Permissions"),
  children: [
    {
      path: "edit-permission",
      name: "r_edit-user-permission",
      meta: {
        middlewares: {
          auth: null,
          permission: false
        }
      },
      props: { backLinkName: "r_permissions-user", containerType: "USER" },
      component: () => import("../../components/permissions/EditPermission")
    }
  ]
};
