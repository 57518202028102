import { APPLICATIONS } from "@/services/consts/application";

const COUNT_MIDDLEWARES = {
  auth: null,
  permission: "COUNT",
  document: { applicationId: APPLICATIONS.APP_COUNT.id },
  menuPreferences: "COUNT"
};

export default {
  path: "/count",
  name: "r_count",
  meta: {
    middlewares: COUNT_MIDDLEWARES
  },
  component: () => import("../../components/global/HiddenScreen"),
  children: [
    {
      path: "dashboard",
      name: "r_count-dashboard",
      meta: {
        middlewares: COUNT_MIDDLEWARES
      },
      component: () => import("../../components/count/Dashboard"),
      children: [
        {
          path: "search-filters",
          name: "r_count-dashboard-search-filters",
          meta: {
            middlewares: COUNT_MIDDLEWARES
          },
          props: {
            backLinkName: "r_count-dashboard",
            savePresetLinkName: "r_count-dashboard-search-filters-preset"
          },
          component: () => import("../../components/search/SearchFilters"),
          children: [
            {
              path: "save-preset",
              name: "r_count-dashboard-search-filters-preset",
              meta: {
                middlewares: COUNT_MIDDLEWARES
              },
              props: {
                backLinkName: "r_count-dashboard-search-filters"
              },
              component: () =>
                import("../../components/search/SearchFiltersPresets")
            }
          ]
        },
        {
          path: "export",
          name: "r_count-export-dashboard",
          meta: {
            middlewares: COUNT_MIDDLEWARES
          },
          props: {
            backLinkName: "r_count-dashboard",
            helpLinkName: "r_count-export-dashboard-help-online"
          },
          component: () => import("../../components/count/Export"),
          children: [
            {
              path: "help",
              name: "r_count-export-dashboard-help-online",
              meta: {
                middlewares: COUNT_MIDDLEWARES
              },
              props: {
                backLinkName: "r_count-export-dashboard",
                slug: "count-export"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "help",
          name: "r_count-dashboard-help-online",
          meta: {
            middlewares: COUNT_MIDDLEWARES
          },
          props: {
            backLinkName: "r_count-dashboard",
            slug: "count"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "min-average-max",
      name: "r_count-min-average-max",
      meta: {
        middlewares: COUNT_MIDDLEWARES
      },
      component: () => import("../../components/count/reports/MinAverageMax"),
      children: [
        {
          path: "search-filters",
          name: "r_count-min-average-max-search-filters",
          meta: {
            middlewares: COUNT_MIDDLEWARES
          },
          props: {
            backLinkName: "r_count-min-average-max",
            savePresetLinkName: "r_count-min-average-max-search-filters-preset"
          },
          component: () => import("../../components/search/SearchFilters"),
          children: [
            {
              path: "save-preset",
              name: "r_count-min-average-max-search-filters-preset",
              meta: {
                middlewares: COUNT_MIDDLEWARES
              },
              props: {
                backLinkName: "r_count-min-average-max-search-filters"
              },
              component: () =>
                import("../../components/search/SearchFiltersPresets")
            }
          ]
        },
        {
          path: "export",
          name: "r_count-export-min-average-max",
          meta: {
            middlewares: COUNT_MIDDLEWARES
          },
          props: {
            backLinkName: "r_count-min-average-max",
            helpLinkName: "r_count-export-min-average-max-help-online"
          },
          component: () => import("../../components/count/Export"),
          children: [
            {
              path: "help",
              name: "r_count-export-min-average-max-help-online",
              meta: {
                middlewares: COUNT_MIDDLEWARES
              },
              props: {
                backLinkName: "r_count-export-min-average-max",
                slug: "count-export"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "help",
          name: "r_count-min-average-max-help-online",
          meta: {
            middlewares: COUNT_MIDDLEWARES
          },
          props: {
            backLinkName: "r_count-min-average-max",
            slug: "count-min-average-max"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "prediction-daily",
      name: "r_count-prediction-daily",
      meta: {
        middlewares: COUNT_MIDDLEWARES
      },
      component: () => import("../../components/count/reports/PredictionDaily"),
      children: [
        {
          path: "search-filters",
          name: "r_count-prediction-daily-search-filters",
          meta: {
            middlewares: COUNT_MIDDLEWARES
          },
          props: {
            backLinkName: "r_count-prediction-daily",
            savePresetLinkName: "r_count-prediction-daily-search-filters-preset"
          },
          component: () => import("../../components/search/SearchFilters"),
          children: [
            {
              path: "save-preset",
              name: "r_count-prediction-daily-search-filters-preset",
              meta: {
                middlewares: COUNT_MIDDLEWARES
              },
              props: {
                backLinkName: "r_count-prediction-daily-search-filters"
              },
              component: () =>
                import("../../components/search/SearchFiltersPresets")
            }
          ]
        },
        {
          path: "export",
          name: "r_count-export-prediction-daily",
          meta: {
            middlewares: COUNT_MIDDLEWARES
          },
          props: {
            backLinkName: "r_count-prediction-daily",
            helpLinkName: "r_count-export-prediction-daily-help-online"
          },
          component: () => import("../../components/count/Export"),
          children: [
            {
              path: "help",
              name: "r_count-export-prediction-daily-help-online",
              meta: {
                middlewares: COUNT_MIDDLEWARES
              },
              props: {
                backLinkName: "r_count-export-prediction-daily",
                slug: "count-export"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "help",
          name: "r_count-prediction-daily-help-online",
          meta: {
            middlewares: COUNT_MIDDLEWARES
          },
          props: {
            backLinkName: "r_count-prediction-daily",
            slug: "count-prediction-daily"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "prediction-weekday",
      name: "r_count-prediction-weekday",
      meta: {
        middlewares: COUNT_MIDDLEWARES
      },
      component: () =>
        import("../../components/count/reports/PredictionWeekday"),
      children: [
        {
          path: "search-filters",
          name: "r_count-prediction-weekday-search-filters",
          meta: {
            middlewares: COUNT_MIDDLEWARES
          },
          props: {
            backLinkName: "r_count-prediction-weekday",
            savePresetLinkName:
              "r_count-prediction-weekday-search-filters-preset"
          },
          component: () => import("../../components/search/SearchFilters"),
          children: [
            {
              path: "save-preset",
              name: "r_count-prediction-weekday-search-filters-preset",
              meta: {
                middlewares: COUNT_MIDDLEWARES
              },
              props: {
                backLinkName: "r_count-prediction-weekday-search-filters"
              },
              component: () =>
                import("../../components/search/SearchFiltersPresets")
            }
          ]
        },
        {
          path: "export",
          name: "r_count-export-prediction-weekday",
          meta: {
            middlewares: COUNT_MIDDLEWARES
          },
          props: {
            backLinkName: "r_count-prediction-weekday",
            helpLinkName: "r_count-export-prediction-weekday-help-online"
          },
          component: () => import("../../components/count/Export"),
          children: [
            {
              path: "help",
              name: "r_count-export-prediction-weekday-help-online",
              meta: {
                middlewares: COUNT_MIDDLEWARES
              },
              props: {
                backLinkName: "r_count-export-prediction-weekday",
                slug: "count-export"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "help",
          name: "r_count-prediction-weekday-help-online",
          meta: {
            middlewares: COUNT_MIDDLEWARES
          },
          props: {
            backLinkName: "r_count-prediction-weekday",
            slug: "count-prediction-weekly"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "settings",
      name: "r_count-settings",
      meta: {
        middlewares: {
          auth: null,
          permission: "COUNT",
          document: { applicationId: APPLICATIONS.APP_COUNT.id }
        }
      },
      component: () => import("../../components/count/Settings"),
      children: [
        {
          path: "help",
          name: "r_count-settings-help-online",
          meta: {
            middlewares: COUNT_MIDDLEWARES
          },
          props: {
            backLinkName: "r_count-settings",
            slug: "count-settings"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "technical-list",
      name: "r_count-technical-list",
      meta: {
        middlewares: COUNT_MIDDLEWARES
      },
      component: () => import("../../components/count/reports/TechnicalList"),
      children: [
        {
          path: "search-filters",
          name: "r_count-technical-list-search-filters",
          meta: {
            middlewares: COUNT_MIDDLEWARES
          },
          props: {
            backLinkName: "r_count-technical-list",
            savePresetLinkName: "r_count-technical-list-search-filters-preset"
          },
          component: () => import("../../components/search/SearchFilters"),
          children: [
            {
              path: "save-preset",
              name: "r_count-technical-list-search-filters-preset",
              meta: {
                middlewares: COUNT_MIDDLEWARES
              },
              props: {
                backLinkName: "r_count-technical-list-search-filters"
              },
              component: () =>
                import("../../components/search/SearchFiltersPresets")
            }
          ]
        },
        {
          path: "export",
          name: "r_count-export-technical-list",
          meta: {
            middlewares: COUNT_MIDDLEWARES
          },
          props: {
            backLinkName: "r_count-technical-list",
            helpLinkName: "r_count-export-technical-list-help-online"
          },
          component: () => import("../../components/count/Export"),
          children: [
            {
              path: "help",
              name: "r_count-export-technical-list-help-online",
              meta: {
                middlewares: COUNT_MIDDLEWARES
              },
              props: {
                backLinkName: "r_count-export-technical-list",
                slug: "count-export"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "help",
          name: "r_count-technical-list-help-online",
          meta: {
            middlewares: COUNT_MIDDLEWARES
          },
          props: {
            backLinkName: "r_count-technical-list",
            slug: "count-technical-list"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    }
  ]
};
