<template>
  <section
    class="screen"
    :data-id="modalId"
    :class="[{ [screenClass]: screenClass }, initialClass]"
  >
    <header class="screen-header" v-if="header">
      <slot name="header"></slot>
    </header>
    <screen-loader-wrapper />
    <main class="screen-content">
      <slot></slot>
    </main>
    <footer class="screen-footer" v-if="footer">
      <slot name="footer"></slot>
    </footer>
    <screen-modal
      v-if="show"
      :id="`modalId${modalId}`"
      class="confirm-modal"
      type="warning"
      :confirm-action="deleteError"
      :confirm-button-label="displayLabelName('global', 'mobile-screen', 'ok')"
      :cancel-button-label="
        displayLabelName('global', 'mobile-screen', 'cancel')
      "
      :show="show"
      @close="deleteError"
      >{{ errorMessage }}</screen-modal
    >
    <html-qr-code-interface
      v-if="qrActive && qrScreenId && Number(qrScreenId) === Number(modalId)"
    />
    <take-photo-interface
      v-if="
        cameraActive &&
          cameraScreenId &&
          Number(cameraScreenId) === Number(modalId)
      "
    />
    <qr-code-interface
      v-if="
        qrScannerActive && qrScreenId && Number(qrScreenId) === Number(modalId)
      "
    />
  </section>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { getSubdomain } from "@/services/http-service";
import { mapActions, mapState } from "vuex";

export default {
  name: "Screen",
  components: {
    ScreenLoaderWrapper: defineAsyncComponent(() =>
      import("@/components/global/ScreenLoaderWrapper")
    ),
    ScreenModal: defineAsyncComponent(() =>
      import("@/components/global/ScreenModal")
    ),
    htmlQrCodeInterface: defineAsyncComponent(() =>
      import("@/components/groups/htmlQrCodeInterface")
    ),
    TakePhotoInterface: defineAsyncComponent(() =>
      import("@/components/take-photo-interface/TakePhotoInterface")
    ),
    QrCodeInterface: defineAsyncComponent(() =>
      import("@/components/visitor-management/QrCodeInterface")
    )
  },
  data() {
    return {
      modalId: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.setModalId();

      const elements = document.querySelectorAll(
        ".top-header-menu .component-title:not([style])"
      );

      if (elements.length) {
        elements.forEach(el => {
          const charsLen = el.textContent.length;
          if (charsLen > 17) {
            if (charsLen <= 20) {
              el.style.fontSize = "15px";
            } else if (charsLen <= 22) {
              el.style.fontSize = "14px";
            } else if (charsLen <= 25) {
              el.style.fontSize = "13px";
            }
          }
        });
      }
    });
  },
  computed: {
    ...mapState("global", ["errorMessage", "screenId", "errorRouteName"]),
    ...mapState("qrCode", ["qrActive", "qrScreenId"]),
    ...mapState("camera", ["cameraActive", "cameraScreenId"]),
    ...mapState("visitorManagement", ["qrScannerActive", "qrScannerScreenId"]),
    initialClass() {
      const subdomain = getSubdomain();
      // alternate login theme class
      // theme-alternate

      if (subdomain) {
        return "theme-company";
      }
      return "theme-empire";
    },
    show() {
      return this.errorMessage && this.screenId === this.modalId;
    }
  },
  methods: {
    ...mapActions("global", [
      "setErrorMessage",
      "setScreenId",
      "setErrorRouteName"
    ]),
    deleteError() {
      this.setErrorMessage(null);
      this.setScreenId(null);
      if (this.errorRouteName) {
        const routeName = this.errorRouteName;
        this.setErrorRouteName(null);
        this.$router.push({ name: routeName });
      }
    },
    setModalId() {
      // calculate screen index to show error message
      // in right window - index of screen element plus 1 (it's starting from 1)
      let el = this.$el;
      if (el) {
        let i = 0;
        while ((el = el.previousSibling) != null) ++i;
        this.modalId = i;
      }
    }
  },
  props: {
    header: {
      required: false,
      type: Boolean,
      default: true
    },
    footer: {
      required: false,
      type: Boolean,
      default: true
    },
    screenClass: {
      required: false,
      type: String
    }
  }
};
</script>
