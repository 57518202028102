import { defineRule, configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import { required, email, confirmed, min, numeric } from "@vee-validate/rules";

defineRule("required", required);
defineRule("email", email);
defineRule("confirmed", confirmed);
defineRule("min", min);
defineRule("numeric", numeric);

configure({
  // Generates an English message locale generator
  generateMessage: localize("en", {
    messages: {
      required: "This field is required",
      email: "Please enter valid email",
      confirmed: "Passwords do not match",
      min: "The field must contain 0:{min} or more characters",
      numeric: "Only numbers allowed"
    }
  })
});
