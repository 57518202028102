export default {
  state: {
    sections: [
      "levels",
      "functions",
      "types",
      "models",
      "capacity",
      "attributes"
    ],
    selectedSection: "types",
    selectedLevelIds: [],
    selectedFunctionTypeIds: [],
    selectedTypeIds: [],
    selectedModelIds: [],
    selectedAttributesIds: [],
    selectedCapacity: 0,
    searchActiveNum: 0 // track api calls
  },
  mutations: {
    setSelectedSection(state, payload) {
      state.selectedSection = payload;
    },
    setSelectedTypeIds(state, payload) {
      const arr = [...state.selectedTypeIds];
      const index = arr.findIndex(item => item === payload);
      if (index === -1) {
        arr.push(payload);
      } else {
        arr.splice(index, 1);
      }
      state.selectedTypeIds = arr;
    },
    setSelectedLevelIds(state, payload) {
      const arr = [...state.selectedLevelIds];
      const index = arr.findIndex(item => item === payload);
      if (index === -1) {
        arr.push(payload);
      } else {
        arr.splice(index, 1);
      }
      state.selectedLevelIds = arr;
    },
    setSelectedFunctionTypeIds(state, payload) {
      const arr = [...state.selectedFunctionTypeIds];
      const index = arr.findIndex(item => item === payload);
      if (index === -1) {
        arr.push(payload);
      } else {
        arr.splice(index, 1);
      }
      state.selectedFunctionTypeIds = arr;
    },
    setSelectedModelIds(state, payload) {
      const arr = [...state.selectedModelIds];
      const index = arr.findIndex(item => item === payload);
      if (index === -1) {
        arr.push(payload);
      } else {
        arr.splice(index, 1);
      }
      state.selectedModelIds = arr;
    },
    setSelectedAttributeIds(state, payload) {
      const arr = [...state.selectedAttributesIds];
      const index = arr.findIndex(item => item === payload);
      if (index === -1) {
        arr.push(payload);
      } else {
        arr.splice(index, 1);
      }
      state.selectedAttributesIds = arr;
    },
    setSelectedCapacity(state, payload) {
      state.selectedCapacity = payload;
    },
    activateSearch(state, payload) {
      if (payload) {
        state.searchActiveNum === 1
          ? (state.searchActiveNum = 2)
          : (state.searchActiveNum = 1);
      } else {
        state.searchActiveNum = 0;
      }
    },
    clearAllFilters(state) {
      state.selectedTypeIds = [];
      state.selectedLevelIds = [];
      state.selectedFunctionTypeIds = [];
      state.selectedModelIds = [];
      state.selectedAttributesIds = [];
      state.selectedCapacity = 0;
      state.searchActivated = 0;
    }
  },
  actions: {
    setSelectedSection({ commit }, payload) {
      commit("setSelectedSection", payload);
    },
    setSelectedTypeIds({ commit }, payload) {
      commit("setSelectedTypeIds", payload);
    },
    setSelectedLevelIds({ commit }, payload) {
      commit("setSelectedLevelIds", payload);
    },
    setSelectedFunctionTypeIds({ commit }, payload) {
      commit("setSelectedFunctionTypeIds", payload);
    },
    setSelectedModelIds({ commit }, payload) {
      commit("setSelectedModelIds", payload);
    },
    setSelectedAttributeIds({ commit }, payload) {
      commit("setSelectedAttributeIds", payload);
    },
    setSelectedCapacity({ commit }, payload) {
      commit("setSelectedCapacity", payload);
    },
    activateSearch({ commit }, payload) {
      commit("activateSearch", payload);
    }
  }
};
