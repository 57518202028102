export default {
  path: "/qr-code-templates",
  name: "r_qr-code-templates",
  meta: {
    middlewares: {
      auth: null,
      permission: "QR_CODE"
    }
  },
  component: () => import("../../components/qr-codes/QrCodeTemplates"),
  children: [
    {
      path: "add",
      name: "r_add-qr-code-template",
      meta: {
        middlewares: {
          auth: null,
          permission: "QR_CODE"
        }
      },
      props: {
        backLinkName: "r_qr-code-templates"
      },
      component: () => import("../../components/qr-codes/QrCodeTemplates/Add"),
      children: []
    },
    {
      path: "edit/:templateId",
      name: "r_edit-qr-code-template",
      meta: {
        middlewares: {
          auth: null,
          permission: "QR_CODE"
        }
      },
      props: {
        backLinkName: "r_qr-code-templates"
      },
      component: () => import("../../components/qr-codes/QrCodeTemplates/Edit"),
      children: []
    }
  ]
};
