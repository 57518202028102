import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    resourceAssignmentBreak: null,
    resourceAssignmentBreaks: null
  },
  getters: {
    resourceAssignmentBreak(state) {
      return state.resourceAssignmentBreak;
    },
    resourceAssignmentBreaks(state) {
      return state.resourceAssignmentBreaks;
    }
  },
  mutations: {
    setResourceAssignmentBreak(state, payload) {
      state.resourceAssignmentBreak = payload;
    },
    setResourceAssignmentBreaks(state, payload) {
      state.resourceAssignmentBreaks = payload;
    }
  },
  actions: {
    getResourceAssignmentBreak({ commit }, { resAssignmentId, breakId }) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(
          `${apiEndpoints.company.resourceAssignments}/${resAssignmentId}/resourceassignmentbreaks/${breakId}`
        )
        .then(response => {
          commit("setResourceAssignmentBreak", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getResourceAssignmentBreaks({ commit }, resAssignmentId) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(
          `${apiEndpoints.company.resourceAssignments}/${resAssignmentId}/resourceassignmentbreaks`
        )
        .then(response => {
          commit("setResourceAssignmentBreaks", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    postResourceAssignmentBreak({ commit }, { resAssignmentId, params }) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .post(
          `${apiEndpoints.company.resourceAssignments}/${resAssignmentId}/resourceassignmentbreaks`,
          params
        )
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    putResourceAssignmentBreak(
      { commit },
      { resAssignmentId, breakId, params }
    ) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .put(
          `${apiEndpoints.company.resourceAssignments}/${resAssignmentId}/resourceassignmentbreaks/${breakId}`,
          params
        )
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    deleteResourceAssignmentBreaks({ commit }, { resAssignmentId, items }) {
      commit("loader/setScreenLoading", true, { root: true });

      return httpServiceAuth
        .delete(
          `${apiEndpoints.company.resourceAssignments}/${resAssignmentId}/resourceassignmentbreaks`,
          {
            data: {
              resource_assignment_break_ids: items
            }
          }
        )
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    }
  }
};
