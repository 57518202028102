import { APPLICATIONS } from "@/services/consts/application";

const MY_VISITS_MIDDLEWARES = {
  auth: null,
  permission: "MY_VISITS",
  document: { applicationId: APPLICATIONS.APP_MY_VISITS.id },
  menuPreferences: "MY_VISITS"
};

export default {
  path: "/my-visits",
  name: "r_my-visits",
  meta: {
    middlewares: MY_VISITS_MIDDLEWARES
  },
  component: () => import("../../components/my-visits/Visits"),
  children: [
    {
      path: "visit/:visitId",
      name: "r_view-my-visit",
      meta: {
        middlewares: MY_VISITS_MIDDLEWARES
      },
      component: () => import("../../components/my-visits/Visit")
    }
  ]
};
