const CLEAN_MIDDLEWARES = {
  auth: null,
  permission: "CLEAN",
  menuPreferences: "CLEAN"
};

export default [
  {
    path: "/clean",
    name: "r_clean",
    meta: {
      middlewares: CLEAN_MIDDLEWARES
    },
    component: () => import("../../components/clean/CleanResources"),
    children: [
      {
        path: "map",
        name: "r_clean-resources-map",
        meta: {
          middlewares: CLEAN_MIDDLEWARES
        },
        component: () => import("../../components/clean/CleanResourcesMap"),
        children: [
          {
            path: "clean",
            name: "r_clean-resources-map-clean",
            meta: {
              middlewares: CLEAN_MIDDLEWARES
            },
            component: () =>
              import("../../components/clean/CleanResourcesClean")
          }
        ]
      },
      {
        path: "clean",
        name: "r_clean-resources-clean",
        meta: {
          middlewares: CLEAN_MIDDLEWARES
        },
        component: () => import("../../components/clean/CleanResourcesClean")
      }
    ]
  }
];
