export default {
  path: "/containers-reception-desk",
  name: "r_containers-reception-desk",
  meta: {
    middlewares: {
      auth: null,
      permission: "RECEPTION_DESK_CONTAINERS"
    }
  },
  props: {
    containerType: "RECEPTION_DESK",
    backLinkName: "r_containers-reception-desk"
  },
  component: () => import("../../components/containers/Containers"),
  children: [
    {
      path: "add-container",
      name: "r_add-reception-desk-container",
      meta: {
        middlewares: {
          auth: null,
          permission: "RECEPTION_DESK_CONTAINERS"
        }
      },
      props: {
        backLinkName: "r_containers-reception-desk",
        containerType: "RECEPTION_DESK"
      },
      component: () => import("../../components/containers/AddContainer")
    },
    {
      path: "edit-container",
      name: "r_edit-reception-desk-container",
      meta: {
        middlewares: {
          auth: null,
          permission: "RECEPTION_DESK_CONTAINERS"
        }
      },
      props: {
        backLinkName: "r_containers-reception-desk",
        containerType: "RECEPTION_DESK"
      },
      component: () => import("../../components/containers/EditContainer"),
      children: [
        {
          path: "add-reception-desks-to-container",
          name: "r_edit-reception-desk-add-reception-desk-to-container",
          meta: {
            middlewares: {
              auth: null,
              permission: "RECEPTION_DESK_CONTAINERS"
            }
          },
          props: {
            backLinkName: "r_edit-reception-desk-container",
            containerType: "RECEPTION_DESK"
          },
          component: () =>
            import("../../components/containers/ContainersAddReceptionDesk")
        },
        {
          path: "add-roles-to-container",
          name: "r_edit-reception-desk-add-roles-to-container",
          meta: {
            middlewares: {
              auth: null,
              permission: "RECEPTION_DESK_CONTAINERS"
            }
          },
          props: {
            backLinkName: "r_edit-reception-desk-container",
            containerType: "RECEPTION_DESK"
          },
          component: () =>
            import("../../components/containers/ContainersAddRole")
        }
      ]
    }
  ]
};
