import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    companyProfile: null,
    companyProfilePhoto: null,
    companyLogo: null
  },
  getters: {
    companyPhoto(state) {
      return (
        state.companyProfilePhoto &&
        state.companyProfilePhoto.data &&
        state.companyProfilePhoto.data.url
      );
    }
  },
  mutations: {
    setCompanyProfile(state, payload) {
      state.companyProfile = payload;
    },
    setCompanyProfilePhoto(state, payload) {
      state.companyProfilePhoto = payload;
    },
    setCompanyLogo(state, payload) {
      state.companyLogo = payload;
    }
  },
  actions: {
    getCompanyProfile({ commit }) {
      return httpServiceAuth
        .get(apiEndpoints.company.companyProfile)
        .then(response => {
          commit("setCompanyProfile", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    setCompanyProfile({ commit }, payload) {
      commit("setCompanyProfile", payload);
    },
    setCompanyProfilePhoto(state, payload) {
      state.companyProfilePhoto = payload;
    },
    getCompanyProfilePhoto({ commit }) {
      return httpServiceAuth
        .get(apiEndpoints.company.companyProfileImage)
        .then(response => {
          commit("setCompanyProfilePhoto", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    getCompanyLogo({ commit }) {
      return httpServiceAuth
        .get(apiEndpoints.company.companyLogo)
        .then(response => {
          commit("setCompanyLogo", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    }
  }
};
