export default {
  path: "/reception-desks",
  name: "r_reception-desks",
  meta: {
    middlewares: {
      auth: null,
      permission: "RECEPTIONS"
    }
  },
  component: () => import("../../components/receptions/Receptions"),
  children: [
    {
      path: "add",
      name: "r_add-reception",
      meta: {
        middlewares: {
          auth: null,
          permission: "RECEPTIONS"
        }
      },
      props: {
        backLinkName: "r_reception-desks"
      },
      component: () => import("../../components/receptions/AddReception"),
      children: [
        {
          path: "select-level",
          name: "r_add-reception-select-level",
          meta: {
            middlewares: {
              auth: null,
              permission: "RECEPTIONS"
            }
          },
          props: {
            backLinkName: "r_add-reception"
          },
          component: () => import("../../components/receptions/ReceptionLevels")
        },
        {
          path: "select-parent",
          name: "r_add-reception-select-parent",
          meta: {
            middlewares: {
              auth: null,
              permission: "RECEPTIONS"
            }
          },
          props: {
            backLinkName: "r_add-reception"
          },
          component: () =>
            import("../../components/receptions/ReceptionParents")
        },
        {
          path: "select-container",
          name: "r_add-reception-select-container",
          meta: {
            middlewares: {
              auth: null,
              permission: "RECEPTIONS"
            }
          },
          props: {
            backLinkName: "r_add-reception"
          },
          component: () =>
            import("../../components/receptions/AllowedUserContainers")
        },
        {
          path: "select-allowed-containers",
          name: "r_add-reception-select-allowed-containers",
          meta: {
            middlewares: {
              auth: null,
              permission: "RECEPTIONS"
            }
          },
          props: {
            backLinkName: "r_add-reception"
          },
          component: () => import("../../components/receptions/UserContainers")
        },
        {
          path: "name-translations",
          name: "r_add-reception-translate-name",

          meta: {
            middlewares: {
              auth: null,
              permission: "RECEPTIONS"
            }
          },
          props: {
            backLinkName: "r_add-reception",
            componentTitle: "manage-reception-desks",
            componentLabel: "add-reception-desk"
          },
          component: () =>
            import("../../components/receptions/TranslateReception")
        },
        {
          path: "select-working-hours",
          name: "r_add-reception-select-working-hours",
          meta: {
            middlewares: {
              auth: null,
              permission: "RECEPTIONS"
            }
          },
          props: { backLinkName: "r_add-reception" },
          component: () =>
            import("../../components/receptions/SelectWorkingHours")
        },
        {
          path: "language",
          name: "r_add-reception-language",
          meta: {
            middlewares: {
              auth: null,
              permission: ["RECEPTIONS"]
            }
          },
          props: { backLinkName: "r_add-reception" },
          component: () => import("../../components/global/LanguageSelection")
        },
        {
          path: "device-template",
          name: "r_add-reception-device-template",
          meta: {
            middlewares: {
              auth: null,
              permission: ["RECEPTIONS"]
            }
          },
          props: { backLinkName: "r_add-reception" },
          component: () => import("../../components/global/DeviceTemplates")
        }
      ]
    },
    {
      path: ":receptionId",
      name: "r_edit-reception",
      meta: {
        middlewares: {
          auth: null,
          permission: "RECEPTIONS"
        }
      },
      component: () => import("../../components/receptions/EditReception"),
      children: [
        {
          path: "select-level",
          name: "r_edit-reception-select-level",
          meta: {
            middlewares: {
              auth: null,
              permission: "RECEPTIONS"
            }
          },
          props: {
            backLinkName: "r_edit-reception"
          },
          component: () => import("../../components/receptions/ReceptionLevels")
        },
        {
          path: "select-parent",
          name: "r_edit-reception-select-parent",
          meta: {
            middlewares: {
              auth: null,
              permission: "RECEPTIONS"
            }
          },
          props: {
            backLinkName: "r_edit-reception"
          },
          component: () =>
            import("../../components/receptions/ReceptionParents")
        },
        {
          path: "select-container",
          name: "r_edit-reception-select-container",
          meta: {
            middlewares: {
              auth: null,
              permission: "RECEPTIONS"
            }
          },
          props: {
            backLinkName: "r_edit-reception"
          },
          component: () =>
            import("../../components/receptions/AllowedUserContainers")
        },
        {
          path: "select-allowed-containers",
          name: "r_edit-reception-select-allowed-containers",
          meta: {
            middlewares: {
              auth: null,
              permission: "RECEPTIONS"
            }
          },
          props: {
            backLinkName: "r_edit-reception"
          },
          component: () => import("../../components/receptions/UserContainers")
        },
        {
          path: "edit-email/:typeId",
          name: "r_edit-reception-email",
          meta: {
            middlewares: {
              auth: null,
              permission: "RECEPTIONS"
            }
          },
          component: () => import("../../components/receptions/ReceptionEmail")
        },
        {
          path: "name-translations",
          name: "r_edit-reception-translate-name",
          meta: {
            middlewares: {
              auth: null,
              permission: "RECEPTIONS"
            }
          },
          props: {
            backLinkName: "r_edit-reception",
            componentTitle: "manage-reception-desks",
            componentLabel: "edit-reception-desk"
          },
          component: () =>
            import("../../components/receptions/TranslateReception")
        },
        {
          path: "select-working-hours",
          name: "r_edit-reception-select-working-hours",
          meta: {
            middlewares: {
              auth: null,
              permission: "RECEPTIONS"
            }
          },
          props: { backLinkName: "r_edit-reception" },
          component: () =>
            import("../../components/receptions/SelectWorkingHours")
        },
        {
          path: "language",
          name: "r_edit-reception-language",
          meta: {
            middlewares: {
              auth: null,
              permission: ["RECEPTIONS"]
            }
          },
          props: { backLinkName: "r_edit-reception" },
          component: () => import("../../components/global/LanguageSelection")
        },
        {
          path: "device-template",
          name: "r_edit-reception-device-template",
          meta: {
            middlewares: {
              auth: null,
              permission: ["RECEPTIONS"]
            }
          },
          props: { backLinkName: "r_edit-reception" },
          component: () => import("../../components/global/DeviceTemplates")
        }
      ]
    }
  ]
};
