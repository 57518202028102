import store from "@/store";
import { apiEndpoints } from "@/services/constants";
import httpServiceAuth, {
  clearAccessToken,
  getAccessToken,
  isLoggedIn,
  refreshToken,
  saveAccessToken,
  getSubdomain,
  setLang,
} from "@/services/http-service";

export default async function auth({ to }) {
  if (isLoggedIn()) {
    if (!store.state.language.languages) {
      await getInitialData();
    }
    return;
  }
  if (!getAccessToken()) {
    clearAccessToken();
    // no access token -> go to login
    return {
      name: "r_login",
      query: to.query,
    };
  }
  store.commit("loader/setScreenLoading", true, { root: true });
  return await refreshToken()
    .then((response) => {
      const { access_token } = response.data;

      if (access_token) {
        saveAccessToken(access_token);
        store.commit("auth/setLoggedInStatus", true, { root: true });

        const messageObj = { message: access_token };
        const stringifiedMessageObj = JSON.stringify(messageObj);
        console.log("pre-postmessage call on webkit");
        if (window.webkit && Window.webkit.messageHandlers) {
          console.log("postmessage call on webkit");
          window.webkit.messageHandlers.cordova_iab.postMessage(
            stringifiedMessageObj
          );
        }
      }
      return getInitialData();
    })
    .catch(() => {
      return {
        name: "r_login",
        query: to.query,
      };
    })
    .finally(() => {
      store.commit("loader/setScreenLoading", false, { root: true });
    });
}

/**
 * We need generalPermissions for permissions middleware and main menu but we can fetch those data only when user is successfuly logged in.
 * TODO: In future maybe we should have some dedicate middleware for fetching required data like generalPermissions, labels, preferenceCategories, languages etc.
 */
async function getInitialData() {
  if (isLoggedIn()) {
    if (!getSubdomain()) {
      return httpServiceAuth
        .get(apiEndpoints.master.initial)
        .then((response) => {
          // Set languages - START
          store.dispatch(
            "language/setLanguageData",
            response.data.data.languages,
            {
              root: true,
            }
          );
          // Set languages - END
          // Set countries - START
          store.dispatch(
            "country/setCountryData",
            response.data.data.countries,
            {
              root: true,
            }
          );
          // Set countries - END
          // Set timezones - START
          store.dispatch(
            "timezone/setTimezoneData",
            response.data.data.timezones,
            {
              root: true,
            }
          );
          // Set timezones - END
          // Set currencies - START
          store.dispatch(
            "currency/setCurrencyData",
            response.data.data.currencies,
            {
              root: true,
            }
          );
          // Set currencies - END
        });
    } else if (getSubdomain()) {
      return httpServiceAuth
        .get(apiEndpoints.company.initial)
        .then((response) => {
          if (response.data.data && response.data.data.device) {
            window.location.href = response.data.data.device;
          } else {
            // Set startup page - START
            if (response.data.data.startup_page) {
              store.commit(
                "preferences/setLandingPagePreferenceUrl",
                store.state.auth.startupPage[response.data.data.startup_page],
                {
                  root: true,
                }
              );
            }
            // Set startup page - END
            // Set general permissions - START
            store.commit(
              "auth/setGeneralPermissions",
              response.data.data.general_permissions
            );
            // Set general permissions - END
            // Set labels - START
            store.dispatch(
              "translation/setTranslations",
              response.data.data.labels,
              {
                root: true,
              }
            );
            // Set labels - END
            // Set languages - START
            setLang(response.data.data.user.language);
            store.dispatch(
              "language/setLanguageData",
              response.data.data.languages,
              {
                root: true,
              }
            );
            // Set languages - END
            // Set countries - START
            store.dispatch(
              "country/setCountryData",
              response.data.data.countries,
              {
                root: true,
              }
            );
            // Set countries - END
            // Set timezones - START
            store.dispatch(
              "timezone/setTimezoneData",
              response.data.data.timezones,
              {
                root: true,
              }
            );
            // Set timezones - END
            // Set currencies - START
            store.dispatch(
              "currency/setCurrencyData",
              response.data.data.currencies,
              {
                root: true,
              }
            );
            // Set currencies - END
            // Set preference categories - START
            store.dispatch(
              "preferences/setPreferenceCategories",
              response.data.data.preferencecategories,
              {
                root: true,
              }
            );
            // Set preference categories - END
            // Set user time preference - START
            store.dispatch(
              "settings/setUserTimePreferences",
              response.data.data.usertimepreference,
              {
                root: true,
              }
            );
            // Set user time preference - END
            // Set device templates - START
            store.dispatch(
              "deviceTemplates/setDeviceTemplates",
              response.data.data.devicetemplates,
              {
                root: true,
              }
            );
            // Set device templates - END
          }
        });
    }
  }
  return;
}
