export default {
  path: "/containers-resource",
  name: "r_containers-resource",
  meta: {
    middlewares: {
      auth: null,
      permission: "RESOURCES_CONTAINERS"
    }
  },
  props: {
    containerType: "RESOURCE",
    backLinkName: "r_containers-resource"
  },
  component: () => import("../../components/containers/Containers"),
  children: [
    {
      path: "add-container",
      name: "r_add-resource-container",
      meta: {
        middlewares: {
          auth: null,
          permission: "RESOURCES_CONTAINERS"
        }
      },
      props: {
        backLinkName: "r_containers-resource",
        containerType: "RESOURCE"
      },
      component: () => import("../../components/containers/AddContainer")
    },
    {
      path: "edit-container",
      name: "r_edit-resource-container",
      meta: {
        middlewares: {
          auth: null,
          permission: "RESOURCES_CONTAINERS"
        }
      },
      props: {
        backLinkName: "r_containers-resource",
        containerType: "RESOURCE"
      },
      component: () => import("../../components/containers/EditContainer"),
      children: [
        {
          path: "add-resources-to-container",
          name: "r_edit-resource-add-resources-to-container",
          meta: {
            middlewares: {
              auth: null,
              permission: "RESOURCES_CONTAINERS"
            }
          },
          props: {
            backLinkName: "r_edit-resource-container",
            containerType: "RESOURCE"
          },
          component: () =>
            import("../../components/containers/ContainersAddResource"),
          children: [
            {
              path: "add-resources-to-container-search-filters",
              name: "r_edit-resource-add-resources-to-container-search-filters",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES_CONTAINERS"
                }
              },
              props: {
                backLinkName: "r_edit-resource-add-resources-to-container",
                containerType: "RESOURCE"
              },
              component: () =>
                import(
                  "../../components/search/SearchFiltersResourceContainers"
                )
            }
          ]
        },
        {
          path: "add-roles-to-container",
          name: "r_edit-resource-add-roles-to-container",
          meta: {
            middlewares: {
              auth: null,
              permission: "RESOURCES_CONTAINERS"
            }
          },
          props: {
            backLinkName: "r_edit-resource-container",
            containerType: "RESOURCE"
          },
          component: () =>
            import("../../components/containers/ContainersAddRole")
        }
      ]
    }
  ]
};
