import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import globalMixin from "@/services/mixins/global/global";
import { rgbToHex } from "@/services/helpers";
import { DateTime, Interval } from "luxon";

export default {
  state: {
    settings: null,
    isOutdated: false,
    payload: null,
    occupancyData: null,
    weekdays: null,
    mapUrl: null,
    paginationData: null,
    paginationLoading: false,
    helpOnLine: null,
    finderData: null,
    finderLevels: null,
    finderSelectedLevelId: null,
    selectedResourceId: null,
    selectedResourceTypeIds: null,
    selectedResourceTypeIdsNum: 0,
  },
  mutations: {
    setSettings(state, payload) {
      state.settings = payload;
    },
    setIsOutdated(state, payload) {
      state.isOutdated = payload;
    },
    setPayload(state, payload) {
      state.payload = payload;
    },
    setOccupancyData(state, payload) {
      state.occupancyData = payload;
    },
    setWeekdays(state, payload) {
      state.weekdays = payload;
    },
    setMapUrl(state, payload) {
      state.mapUrl = payload;
    },
    setPaginationData(state, payload) {
      state.paginationData = payload;
    },
    setPaginationLoading(state, payload) {
      state.paginationLoading = payload;
    },
    setHelpOnLine(state, payload) {
      state.helpOnLine = payload;
    },
    setFinderData(state, payload) {
      state.finderData = payload;
    },
    setFinderLevels(state, payload) {
      state.finderLevels = payload;
    },
    setFinderSelectedLevelId(state, payload) {
      state.finderSelectedLevelId = payload;
    },
    setSelectedResourceId(state, payload) {
      state.selectedResourceId = payload;
    },
    setSelectedResourceTypeIds(state, payload) {
      state.selectedResourceTypeIds = payload;
    },
    setSelectedResourceTypeIdsNum(state, payload) {
      state.selectedResourceTypeIdsNum = payload;
    },
  },
  actions: {
    getSettings({ commit }) {
      return httpServiceAuth
        .get(apiEndpoints.company.occupancySettings)
        .then(({ data }) => {
          // fixes for rgb data start
          if (data.data.min_max_report.indexOf("#") < 0) {
            data.data.min_max_report = rgbToHex(data.data.min_max_report);
          }
          if (data.data.prediction_day_report.indexOf("#") < 0) {
            data.data.prediction_day_report = rgbToHex(
              data.data.prediction_day_report
            );
          }
          if (data.data.prediction_weekday_report.indexOf("#") < 0) {
            data.data.prediction_weekday_report = rgbToHex(
              data.data.prediction_weekday_report
            );
          }
          if (data.data.historical_occupancy_report.indexOf("#") < 0) {
            data.data.historical_occupancy_report = rgbToHex(
              data.data.historical_occupancy_report
            );
          }
          if (data.data.historical_real_use_report.indexOf("#") < 0) {
            data.data.historical_real_use_report = rgbToHex(
              data.data.historical_real_use_report
            );
          }
          // fixes for rgb data end

          // Check and set if outdated Data
          let outdated = false;
          if (
            data.data.last_record &&
            !data.data.demo &&
            DateTime.now()
              .diff(DateTime.fromISO(data.data.last_record), "minutes")
              .as("minutes") > 300
          ) {
            outdated = true;
          }
          commit("setIsOutdated", outdated);

          commit("setSettings", data.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    setSettings({ commit }, settingsObject) {
      commit("loader/setScreenLoading", true, { root: true });
      let postObject = JSON.parse(JSON.stringify(settingsObject));
      postObject.demo = postObject.demo ? 1 : 0;
      return httpServiceAuth
        .post(apiEndpoints.company.occupancySettings, postObject)
        .then(({ data }) => {
          commit("setSettings", data.data.length ? data.data[0] : null);
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    setHelpOnLine({ commit }, slug) {
      const url = `${apiEndpoints.company.helpOnLine}${
        slug ? "?slug=" + slug : ""
      }`;
      return httpServiceAuth
        .get(url)
        .then(({ data }) => {
          commit("setHelpOnLine", data.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    getOccupancyFilters({ commit, dispatch }, section) {
      const url = `${apiEndpoints.company.occupancyFilters}${
        section ? "?section=" + section : ""
      }`;
      return httpServiceAuth
        .get(url)
        .then((response) => {
          let filters = JSON.parse(JSON.stringify(response.data)).filters;
          let filterPresets = JSON.parse(JSON.stringify(response.data))
            .filterPresets;
          commit(
            "searchFilters/setShowAddPresetButton",
            response.data.demo ? false : true,
            {
              root: true,
            }
          );
          commit("searchFilters/setFilterPage", "occupancy", {
            root: true,
          });
          commit("searchFilters/setFilterSection", section, {
            root: true,
          });
          if (filterPresets && filterPresets.length) {
            filterPresets.forEach((pr) => {
              if (
                section === "min_max_average" ||
                section === "occupancy_historical" ||
                section === "occupancy_real_use"
              ) {
                pr.filters.forEach((el) => {
                  if (el.slug === "dates" && el.date_start && el.date_end) {
                    const start = DateTime.fromISO(el.date_start);
                    const end = DateTime.fromISO(el.date_end);
                    el.hoursInterval = Interval.fromDateTimes(
                      start,
                      end
                    ).length("hours");
                  }
                });
              }
            });
            commit("searchFilters/setFilterPresets", filterPresets, {
              root: true,
            });
          }
          if (filters && filters.length) {
            if (
              section === "min_max_average" ||
              section === "occupancy_historical" ||
              section === "historical-real-use"
            ) {
              filters.forEach((el) => {
                if (el.slug === "dates" && el.date_start && el.date_end) {
                  const start = DateTime.fromISO(el.date_start);
                  const end = DateTime.fromISO(el.date_end);
                  el.hoursInterval = Interval.fromDateTimes(start, end).length(
                    "hours"
                  );
                }
              });
            }
            commit("searchFilters/setFilters", filters, {
              root: true,
            });
          }
          dispatch("searchFilters/selectFilterPreset", false, {
            root: true,
          });
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    getExport({ commit, dispatch, state }, type) {
      commit("loader/setScreenLoading", true, { root: true });
      dispatch("setPayload").then(() => {
        let payload = state.payload;
        payload.section = type;
        return httpServiceAuth
          .post(apiEndpoints.company.occupancyExport, state.payload, {
            exposedHeaders: [
              "Content-Disposition",
              "Content-type",
              "Content-Encoding",
            ],
          })
          .then((response) => {
            let blob = new Blob(["\ufeff" + response.data], {
              type: response.headers["content-type"],
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            const contentDispositionHeader =
              response.headers["content-disposition"];
            let fileName = "CurrentExport.csv";
            if (contentDispositionHeader) {
              const headerFileName = globalMixin.methods.extractHeaderFileName(
                contentDispositionHeader
              );
              fileName = headerFileName ? headerFileName : fileName;
            }
            link.download = fileName;
            link.click();
            link.remove();
          })
          .catch((error) => {
            if (error.response) {
              errorHandler(error.response);
            }
          })
          .finally(() => {
            commit("loader/setScreenLoading", false, { root: true });
          });
      });
    },
    setPayload({ commit, rootState, state }, section) {
      const filters = rootState.searchFilters.filters;
      if (filters && filters.length) {
        const payloadObject = {
          query: rootState.search.searchQuery,
          location_ids: [],
          type_ids: [],
          alarm: [],
          days: state.weekdays,
        };
        if (section) {
          payloadObject.section = section;
        }
        filters
          .filter(
            (filter) =>
              (filter.selected && filter.value === undefined) ||
              (filter.value && filter.value > 0)
          )
          .forEach((filter) => {
            switch (filter.slug) {
              case "locations":
                payloadObject.location_ids.push(filter.id);
                break;
              case "types":
                payloadObject.type_ids.push(filter.id);
                break;
              case "dates":
                payloadObject.datetime_from = filter.date_start;
                payloadObject.datetime_to = filter.date_end;
                break;
              case "intervals":
                payloadObject.interval_id = filter.id;
                break;
              case "alarm":
                payloadObject.alarm.push(filter.id);
                break;

              default:
                break;
            }
          });
        commit("setPayload", payloadObject);
      }
    },
    // calculate method calls occupancy/calculate EP that responds with data for occupancy pages (Dashboard and Reports)
    calculate(
      { commit, dispatch, state },
      { section, initial = false, pagination = false, reset = false }
    ) {
      let paginationNum = "";
      let url = apiEndpoints.company.occupancyCalculate;
      let paginationDataBool = !state.paginationData;
      if (pagination) {
        commit("setPaginationLoading", true);
        if (paginationDataBool || reset) {
          paginationNum = "&page=1";
          commit("setOccupancyData", null);
        } else {
          paginationNum = `&page=${state.paginationData.current_page + 1}`;
        }
        if (paginationDataBool || reset) {
          commit("loader/setScreenLoading", true, { root: true });
        }
        url = url + `?per_page=100${paginationNum}`;
      } else {
        commit("loader/setScreenLoading", true, { root: true });
        commit("setOccupancyData", null);
      }
      if (!initial) {
        dispatch("setPayload", section).then(() => {
          return httpServiceAuth
            .post(url, state.payload)
            .then((response) => {
              if (pagination) {
                if (paginationDataBool || reset) {
                  commit("setOccupancyData", response.data.data);
                } else {
                  const newData = [
                    ...state.occupancyData,
                    ...response.data.data,
                  ];
                  commit("setOccupancyData", newData);
                }
                commit("setPaginationData", response.data.meta);
                if (
                  response.data.meta.current_page < 3 &&
                  response.data.meta.last_page &&
                  response.data.meta.last_page > response.data.meta.current_page
                ) {
                  dispatch("calculate", {
                    section: section,
                    initial: initial,
                    pagination: pagination,
                    reset: false,
                  });
                }
              } else {
                commit("setOccupancyData", response.data);
              }
            })
            .catch((error) => {
              if (error.response) {
                errorHandler(error.response);
              }
            })
            .finally(() => {
              commit("setPaginationLoading", false);
              if (
                (pagination && (paginationDataBool || reset)) ||
                !pagination
              ) {
                commit("loader/setScreenLoading", false, { root: true });
              }
            });
        });
      } else {
        return httpServiceAuth
          .post(url, { section: section })
          .then((response) => {
            if (pagination) {
              if (!state.paginationData || reset) {
                commit("setOccupancyData", response.data.data);
              } else {
                const newData = [...state.occupancyData, ...response.data.data];
                commit("setOccupancyData", newData);
              }
              commit("setPaginationData", response.data.meta);
              if (
                response.data.meta.current_page < 3 &&
                response.data.meta.last_page &&
                response.data.meta.last_page > response.data.meta.current_page
              ) {
                dispatch("calculate", {
                  section: section,
                  initial: initial,
                  pagination: pagination,
                  reset: false,
                });
              }
            } else {
              commit("setOccupancyData", response.data);
            }
          })
          .catch((error) => {
            if (error.response) {
              errorHandler(error.response);
            }
          })
          .finally(() => {
            commit("setPaginationLoading", false);
            if ((pagination && (paginationDataBool || reset)) || !pagination) {
              commit("loader/setScreenLoading", false, { root: true });
            }
          });
      }
    },
    getFinderData({ commit, state }, { types, filter }) {
      commit("loader/setScreenLoading", true, { root: true });
      commit("setSelectedResourceId", null);
      const params = {};
      if (types && types.length) {
        params.resource_types = types;
      }
      if (filter) {
        params.filter = filter;
      }
      return httpServiceAuth
        .get(
          `${apiEndpoints.company.occupancyLevels}/${state.finderSelectedLevelId}/finder`,
          {
            params: {
              ...params,
            },
          }
        )
        .then(({ data }) => {
          commit("setFinderData", data.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getFinderLevels({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.occupancyLevels}/finder`)
        .then(({ data }) => {
          commit("setFinderLevels", data.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
  },
};
