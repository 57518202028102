<template>
  <div class="svg-icon">
    <svg v-bind="$attrs" :style="iconStyle">
      <use :xlink:href="icon" />
    </svg>
  </div>
</template>

<script>
export default {
  name: "icon",
  inheritAttrs: false,
  computed: {
    iconStyle() {
      const { width, height } = this.$attrs;

      if (width || height) {
        const style = {};
        if (width) style.width = `${width}px`;
        if (height) style.height = `${height}px`;
        return style;
      }

      return null;
    }
  },
  props: {
    icon: {
      required: true,
      type: String
    }
  }
};
</script>
