import { apiEndpoints } from "@/services/constants";
import httpServiceAuth from "@/services/http-service";
import { errorHandler } from "@/services/error-handler";
import store from "@/store";

export default {
  state: {
    myDelegates: null,
    delegatedBy: null,
    delegateUsers: null,
    permissions: null
  },
  mutations: {
    setMyDelegates(state, payload) {
      state.myDelegates = payload;
    },
    setDelegatedBy(state, payload) {
      state.delegatedBy = payload;
    },
    setPermissions(state, payload) {
      state.permissions = payload;
    },
    setDelegateUsers(state, payload) {
      state.delegateUsers = payload;
    }
  },
  actions: {
    getDelegations({ state }, delegators = false) {
      let url = apiEndpoints.company.delegatePermissions;
      let whereToSave = "delegates/setMyDelegates";
      let data = state.myDelegates;

      if (delegators) {
        url = `${url}?type=delegators`;
        whereToSave = "delegates/setDelegatedBy";
        data = state.delegatedBy;
      }

      store.dispatch("pagination/paginate", {
        whatToCall: "delegates/getDelegations",
        whereToSave: whereToSave,
        url: url,
        data: data,
        params: delegators
      });
    },
    setMyDelegates({ commit }, payload) {
      commit("setMyDelegates", payload);
    },
    setDelegatedBy({ commit }, payload) {
      commit("setDelegatedBy", payload);
    },
    getDelegatePermissions({ commit }, userId) {
      commit("loader/setScreenLoading", true, { root: true });
      let url = `${apiEndpoints.company.delegatePermissions}/available-permissions/${userId}`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setPermissions", response.data);
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    setDelegation({ commit, dispatch }, postObject) {
      commit("loader/setScreenLoading", true, { root: true });
      let url = `${apiEndpoints.company.delegatePermissions}`;
      return httpServiceAuth
        .post(url, postObject)
        .then(() => {
          dispatch("getDelegations");
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    updateDelegation({ commit, dispatch }, postObject) {
      commit("loader/setScreenLoading", true, { root: true });
      let url = `${apiEndpoints.company.delegatePermissions}/${postObject.id}`;
      return httpServiceAuth
        .put(url, postObject)
        .then(() => {
          dispatch("getDelegations");
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    deleteDelegations({ commit, dispatch }, delegationIds) {
      let promises = [];
      delegationIds.forEach(element => {
        commit("loader/setScreenLoading", true, { root: true });
        const url = `${apiEndpoints.company.delegatePermissions}/${element}`;
        const promise = new Promise(resolve => {
          return httpServiceAuth
            .delete(url)
            .catch(error => {
              if (error.response) {
                errorHandler(error.response);
              }
            })
            .finally(() => {
              resolve(true);
              commit("loader/setScreenLoading", false, { root: true });
            });
        });
        promises.push(promise);
      });
      Promise.all(promises).then(() => {
        dispatch("getDelegations");
      });
    },
    getDelegateUsers({ state }) {
      store.dispatch("pagination/paginate", {
        whatToCall: "delegates/getDelegateUsers",
        whereToSave: "delegates/setDelegateUsers",
        url: apiEndpoints.company.delegateUsers,
        data: state.delegateUsers,
        params: null
      });
    },
    setDelegateUsers({ commit }, payload) {
      commit("setDelegateUsers", payload);
    }
  }
};
