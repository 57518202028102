export default {
  path: "/sync",
  name: "r_sync",
  meta: {
    middlewares: {
      auth: null,
      permission: "SYNC"
    }
  },
  component: () => import("../../components/sync/Sync"),
  children: [
    {
      path: "event-list",
      name: "r_event-list",
      meta: {
        middlewares: {
          auth: null,
          permission: "SYNC"
        }
      },
      component: () => import("../../components/sync/EventList")
    }
  ]
};
