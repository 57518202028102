export default {
  path: "/containers-user",
  name: "r_containers-user",
  meta: {
    middlewares: {
      auth: null,
      permission: "USERS_CONTAINERS"
    }
  },
  props: {
    containerType: "USER",
    backLinkName: "r_containers-user"
  },
  component: () => import("../../components/containers/Containers"),
  children: [
    {
      path: "add-container",
      name: "r_add-user-container",
      meta: {
        middlewares: {
          auth: null,
          permission: "USERS_CONTAINERS"
        }
      },
      props: { backLinkName: "r_containers-user", containerType: "USER" },
      component: () => import("../../components/containers/AddContainer")
    },
    {
      path: "edit-container",
      name: "r_edit-user-container",
      meta: {
        middlewares: {
          auth: null,
          permission: "USERS_CONTAINERS"
        }
      },
      props: { backLinkName: "r_containers-user", containerType: "USER" },
      component: () => import("../../components/containers/EditContainer"),
      children: [
        {
          path: "add-users-to-container",
          name: "r_edit-user-add-users-to-container",
          meta: {
            middlewares: {
              auth: null,
              permission: "USERS_CONTAINERS"
            }
          },
          props: {
            backLinkName: "r_edit-user-container",
            containerType: "USER"
          },
          component: () =>
            import("../../components/containers/ContainersAddUser")
        },
        {
          path: "add-roles-to-container",
          name: "r_edit-user-add-roles-to-container",
          meta: {
            middlewares: {
              auth: null,
              permission: "USERS_CONTAINERS"
            }
          },
          props: {
            backLinkName: "r_edit-user-container",
            containerType: "USER"
          },
          component: () =>
            import("../../components/containers/ContainersAddRole")
        }
      ]
    }
  ]
};
