import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {},
  mutations: {},
  actions: {
    deleteUserAcceptances({ commit }, documentId) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.userAcceptance}/application/delete`;
      return httpServiceAuth
        .delete(url, {
          data: {
            company_document_id: documentId
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    }
  }
};
