export default {
  namespaced: true,
  state: {
    loading: 0
  },
  getters: {},
  mutations: {
    setScreenLoading(state, payload) {
      if (payload) {
        state.loading++;
      } else if (state.loading) {
        state.loading--;
      }
    }
  },
  actions: {}
};
