import httpServiceAuth, {
  getSubdomain,
  getLang,
  setLang,
  setLangId
} from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import { toCamelCase } from "@/services/helpers";
import store from "@/store";

/**
 * @param id - Language id returned from user data call
 * @param rootState - store root state from which we return languages if languages loaded first
 */
function handleLanguages(id, rootState) {
  if (id) {
    // save language id in session storage
    setLangId(id);

    // if language call has returned data before this call there should be languages in
    // root state
    if (rootState.language.languages && rootState.language.languages.data) {
      // retrieve lang attribute or language saved in session storage
      // this returns lang locale
      const langFromStorage = getLang();
      // filter languages by language_id from user object passed
      // to this function
      const filtered = rootState.language.languages.data.filter(
        item => item.id === id
      );
      // if filtered found language with id
      if (filtered && filtered.length) {
        // get lang locale to compare from saved locale in session storage
        // or locale in HTML file
        const langLocale = filtered[0].code;

        // if lang locale from session storage is not the same as
        // lang locale from filtered languages by language id
        if (langLocale !== langFromStorage) {
          // set new lang
          setLang(filtered[0].code);

          // reload the page
          // setTimeout(() => {
          //   if (!rootState.auth.isOutlookPage) {
          //     window.location.reload();
          //   }
          // }, 0);
        }
      }
    }
  }
}

export default {
  namespaced: true,
  state: {
    userData: null,
    userList: null,
    company: null,
    listOfAssignableContainers: null,
    selectedContainers: null,
    outlookSyncUrl: {},
    googleCalendarAuth: {},
    isOutlookSynced: false,
    userProfilePhoto: null,
    userResourceAssigned: null,
    userResourcesAssigned: null,
    userFormFields: {},
    users: null,
    userFilters: null
  },
  getters: {
    userPhoto(state) {
      return (
        state.userProfilePhoto &&
        state.userProfilePhoto.data &&
        state.userProfilePhoto.data.url
      );
    },
    resourcesAssigned(state) {
      return state.userResourcesAssigned;
    },
    resourceAssigned(state) {
      return state.userResourceAssigned;
    }
  },
  mutations: {
    setUserData(state, payload) {
      state.userData = payload;
    },
    setUserListData(state, payload) {
      state.userList = payload;
    },
    setCompany(state, payload) {
      state.company = payload;
    },
    setAssignableContainers(state, payload) {
      state.listOfAssignableContainers = payload;
    },
    setSelectedContainers(state, payload) {
      state.selectedContainers = payload;
    },
    setSelectedContainer(state, payload) {
      if (state.selectedContainers && state.selectedContainers.length) {
        const index = state.selectedContainers.findIndex(
          item => item.id === payload.id
        );
        if (index !== -1) {
          state.selectedContainers.splice(index, 1);
        } else {
          state.selectedContainers.push(payload);
        }
      } else {
        state.selectedContainers = [payload];
      }
    },
    setOutlookSyncUrl(state, payload) {
      state.outlookSyncUrl = payload;
    },
    setGoogleCalendarAuthUrl(state, payload) {
      state.googleCalendarAuth = payload;
    },
    setOutlookSynced(state, payload) {
      state.isOutlookSynced = payload;
    },
    setUserProfilePhoto(state, payload) {
      state.userProfilePhoto = payload;
    },
    setUserResourcesAssigned(state, payload) {
      state.userResourcesAssigned = payload;
    },
    setUserResourceAssigned(state, payload) {
      state.userResourceAssigned = payload;
    },
    setUserFormFields(state, payload) {
      payload.forEach(pref => {
        state.userFormFields[toCamelCase(pref.preference)] = Number(
          pref.preference_values[0].value
        );
      });
    },
    setUsers(state, payload) {
      state.users = payload;
    },
    setUserFilters(state, payload) {
      state.userFilters = payload;
    }
  },
  actions: {
    getMasterUserData({ commit, rootState }, id) {
      // commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.master.users}/${id}`)
        .then(response => {
          const data = response && response.data && response.data.data;
          if (data) {
            commit("setUserData", response.data);
            handleLanguages(data.language_id, rootState);
          }
        })
        .finally(() => {
          // commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getCompanyUserData({ commit, dispatch, rootState }, id) {
      // commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.users}/${id}/profile`)
        .then(response => {
          dispatch("getCurrentCompany");
          commit("setUserData", response.data);
          const { data } = response.data;
          if (data) {
            handleLanguages(data.language_id, rootState);
          }
        })
        .finally(() => {
          // commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getMasterUserList({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.master.users}/all`)
        .then(response => {
          commit("setUserListData", response.data);
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getCompanyUserList({ rootState, state }) {
      const params = {};

      if (rootState.searchFiltersUsers.selectedUserContainersIds.length) {
        params.user_containers = [
          ...rootState.searchFiltersUsers.selectedUserContainersIds
        ];
      }

      if (rootState.searchFiltersUsers.selectedAssignedResourcesIds.length) {
        params.assigned_resources = [
          ...rootState.searchFiltersUsers.selectedAssignedResourcesIds
        ];
      }

      if (rootState.searchFiltersUsers.sso) {
        params.sso = rootState.searchFiltersUsers.sso;
      }

      if (rootState.searchFiltersUsers.visitor) {
        params.is_visitor = rootState.searchFiltersUsers.visitor;
      }

      if (rootState.search.searchQuery) {
        params.query = rootState.search.searchQuery;
      }

      store.dispatch("pagination/paginate", {
        whatToCall: "user/getCompanyUserList",
        whereToSave: "user/setUserListData",
        url: apiEndpoints.company.users,
        data: state.userList,
        params: params
      });
    },
    deleteUser({ dispatch, commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      const subdomain = getSubdomain();
      let url = `${apiEndpoints.master.users}/${id}`;

      if (subdomain) {
        url = `${apiEndpoints.company.users}/${id}`;
      }
      return httpServiceAuth
        .delete(url)
        .then(() => {
          if (subdomain) {
            dispatch("getCompanyUserList");
          } else {
            dispatch("getMasterUserList");
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    userBulkDelete({ dispatch, commit }, users) {
      commit("loader/setScreenLoading", true, { root: true });
      const subdomain = getSubdomain();
      let url = `${apiEndpoints.master.userBulkDelete}`;

      if (subdomain) {
        url = `${apiEndpoints.company.userBulkDelete}`;
      }
      return httpServiceAuth
        .delete(url, { params: users })
        .then(() => {
          if (subdomain) {
            dispatch("getCompanyUserList");
          } else {
            dispatch("getMasterUserList");
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getCurrentCompany({ commit }) {
      return httpServiceAuth
        .get(apiEndpoints.company.currentCompany)
        .then(response => {
          commit("setCompany", response.data);
        });
    },
    getUserAssignableContainers({ commit }, params) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(apiEndpoints.company.userAssignableContainers, {
          params
        })
        .then(response => {
          commit("setAssignableContainers", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedContainers({ commit }, payload) {
      commit("setSelectedContainers", payload);
    },
    setSelectedContainer({ commit }, payload) {
      commit("setSelectedContainer", payload);
    },
    getCompanyUserOutlookSyncUrl({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(apiEndpoints.company.outlookSync)
        .then(response => {
          commit("setOutlookSyncUrl", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getCompanyUserGoogleCalendarAuthUrl({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(apiEndpoints.company.googleCalendarAuth)
        .then(response => {
          commit("setGoogleCalendarAuthUrl", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setOutlookSynced({ commit }, payload) {
      commit("setOutlookSynced", payload);
    },
    getUserProfilePhoto({ commit }) {
      return httpServiceAuth
        .get(apiEndpoints.company.userProfileImage)
        .then(response => {
          commit("setUserProfilePhoto", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    getUserResourceAssigned({ commit }, { userId, id }) {
      return httpServiceAuth
        .get(
          `${apiEndpoints.company.users}/${userId}/resourceassignments/${id}`
        )
        .then(response => {
          commit("setUserResourceAssigned", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    getUserResourcesAssigned({ commit }, { id, params }) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.users}/${id}/resourceassignments/all`, {
          params
        })
        .then(response => {
          commit("setUserResourcesAssigned", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    postResourceAssignment({ commit }, { userId, params }) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .post(
          `${apiEndpoints.company.users}/${userId}/resourceassignments`,
          params
        )
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    putResourceAssignment({ commit }, { userId, id, params }) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .put(
          `${apiEndpoints.company.users}/${userId}/resourceassignments/${id}`,
          params
        )
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    deleteResourceAssignment({ commit }, { userId, id }) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .delete(
          `${apiEndpoints.company.users}/${userId}/resourceassignments/${id}`
        )
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getUserFormFields({ commit }, catId) {
      const url = `${apiEndpoints.company.companyPreferences}/all`;
      return httpServiceAuth
        .get(url, { params: { preference_category_ids: [catId] } })
        .then(response => {
          commit("setUserFormFields", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    getUsers({ state }) {
      store.dispatch("pagination/paginate", {
        whatToCall: "user/getUsers",
        whereToSave: "user/setUsers",
        url: apiEndpoints.company.users,
        data: state.users,
        params: {}
      });
    },
    setUsers({ commit }, payload) {
      commit("setUsers", payload, { root: true });
    },
    getUserFilters({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.users}/filters`)
        .then(response => {
          commit("setUserFilters", response.data.data);
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setUserListData({ commit }, query) {
      commit("setUserListData", query);
    }
  }
};
