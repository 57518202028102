const COMAPANY_DOCUMENTS_MIDDLEWARES = {
  auth: null,
  permission: "DOCUMENTS"
};
export default {
  path: "/company-documents",
  name: "r_company-documents",
  meta: {
    middlewares: COMAPANY_DOCUMENTS_MIDDLEWARES
  },
  component: () =>
    import("../../components/company-documents/CompanyDocuments"),
  children: [
    // add
    {
      path: "add-document",
      name: "r_company-documents-add-document",
      meta: {
        middlewares: COMAPANY_DOCUMENTS_MIDDLEWARES
      },
      props: {
        backLinkName: "r_company-documents"
      },
      component: () =>
        import("../../components/company-documents/AddCompanyDocument"),
      children: [
        {
          path: "select-language",
          name: "r_company-documents-add-select-language",
          meta: {
            middlewares: COMAPANY_DOCUMENTS_MIDDLEWARES
          },
          props: {
            backLinkName: "r_company-documents-add-document",
            componentTitle: "select-language",
            componentLabel: "name-translation"
          },
          component: () =>
            import("../../components/company-documents/SelectLanguage")
        },
        {
          path: "add-applications",
          name: "r_company-documents-add-applications",

          meta: {
            middlewares: COMAPANY_DOCUMENTS_MIDDLEWARES
          },
          props: {
            backLinkName: "r_company-documents-add-document"
          },
          component: () =>
            import("../../components/company-documents/AddApplications")
        }
      ]
    },
    // edit
    {
      path: "edit-document/:documentId",
      name: "r_company-documents-edit-document",
      meta: {
        middlewares: COMAPANY_DOCUMENTS_MIDDLEWARES
      },
      props: {
        backLinkName: "r_company-documents"
      },
      component: () =>
        import("../../components/company-documents/EditCompanyDocument"),
      children: [
        {
          path: "select-language",
          name: "r_company-documents-edit-select-language",
          meta: {
            middlewares: COMAPANY_DOCUMENTS_MIDDLEWARES
          },
          props: {
            backLinkName: "r_company-documents-edit-document",
            componentTitle: "select-language",
            componentLabel: "name-translation"
          },
          component: () =>
            import("../../components/company-documents/SelectLanguage")
        },
        {
          path: "add-applications",
          name: "r_company-documents-edit-applications",

          meta: {
            middlewares: COMAPANY_DOCUMENTS_MIDDLEWARES
          },
          props: {
            backLinkName: "r_company-documents-edit-document"
          },
          component: () =>
            import("../../components/company-documents/AddApplications")
        }
      ]
    },
    //search
    {
      path: "search-filters",
      name: "r_company-documents-search-filters",
      meta: {
        middlewares: COMAPANY_DOCUMENTS_MIDDLEWARES
      },
      props: {
        backLinkName: "r_company-documents",
        searchFiltersStore: "searchFiltersCompanyDocuments"
      },
      component: () =>
        import("../../components/search/SearchFilterCompanyDocuments")
    }
  ]
};
