import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import store from "@/store";

export default {
  namespaced: true,
  state: {
    roles: null,
    selectedRole: null,
    permissionsList: null,
    permissionsInRole: null,
    selectedPermissions: null
  },
  getters: {},
  mutations: {
    setRoles(state, payload) {
      state.roles = payload;
    },
    setSelectedRole(state, payload) {
      state.selectedRole = payload;
    },
    setPermissionsList(state, payload) {
      state.permissionsList = payload;
    },
    setPermissionsInRole(state, payload) {
      state.permissionsInRole = payload;
    },
    setSelectedPermissions(state, payload) {
      state.selectedPermissions = payload;
    },
    setSelectedPermission(state, payload) {
      if (state.selectedPermissions && state.selectedPermissions.length) {
        const index = state.selectedPermissions.findIndex(
          item => item.permission_id === payload.id || item.id === payload.id
        );
        if (index !== -1) {
          state.selectedPermissions.splice(index, 1);
        } else {
          payload.is_active = true;
          state.selectedPermissions.push(payload);
        }
      } else {
        payload.is_active = true;
        state.selectedPermissions = [payload];
      }
    },
    updateSelectedPermission(state, payload) {
      if (state.selectedPermissions && state.selectedPermissions.length) {
        const index = state.selectedPermissions.findIndex(
          item => item.permission_id === payload.id || item.id === payload.id
        );
        if (index !== -1) {
          state.selectedPermissions[index] = {
            ...state.selectedPermissions[index],
            ...payload,
            is_active: true
          };
        }
      }
    }
  },
  actions: {
    getRoles({ state }, params) {
      store.dispatch("pagination/paginate", {
        whatToCall: "roles/getRoles",
        whereToSave: "roles/setRoles",
        url: apiEndpoints.company.roles,
        data: state.roles,
        params: params
      });
    },
    setRoles({ commit }, payload) {
      commit("setRoles", payload);
    },
    setSelectedRole({ commit }, payload) {
      commit("setSelectedRole", payload);
    },
    deleteRole({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .delete(`${apiEndpoints.company.roles}/${id}`)
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getPermissionsList({ commit, dispatch }, params) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.permissions}/all`, { params })
        .then(response => {
          commit("setPermissionsList", response.data);
          setTimeout(() => {
            dispatch("syncPermissionValues");
          });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getPermissionsInRole({ commit, dispatch }, id) {
      // commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.roles}/${id}/rolepermissions`, {
          params: {
            includes: ["permission"]
          }
        })
        .then(response => {
          commit("setPermissionsInRole", response.data);
          const { data } = response.data;

          if (data && data.length) {
            commit("setSelectedPermissions", [...data]);
          } else {
            commit("setSelectedPermissions", null);
          }
          setTimeout(() => {
            dispatch("syncPermissionValues");
          });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedPermission({ commit }, payload) {
      commit("setSelectedPermission", payload);
    },
    updateSelectedPermission({ commit }, payload) {
      commit("updateSelectedPermission", payload);
    },
    updatePermissionsInRoles({ state, commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      const permissions = state.selectedPermissions.map(item => {
        if (item.permission_type) {
          if (item.permission_type.type === "CRUD") {
            return {
              permission_id: item.id,
              create: Number(item.create) || 0,
              read: Number(item["read"]) || 0,
              update: Number(item.update) || 0,
              delete: Number(item.delete) || 0,
              value: null,
              is_active: item.is_active
            };
          }

          if (item.permission_type.type === "LOGICAL") {
            return {
              permission_id: item.id,
              value: null,
              is_active: item.is_active
            };
          }

          if (item.permission_type.type === "NUMERICAL") {
            return {
              permission_id: item.id,
              value: item.value,
              is_active: item.is_active
            };
          }
        } else {
          const data = {
            is_active: true
          };

          if (item.permission_id) {
            data.permission_id = item.permission_id;
          }

          const keys = Object.keys(item);

          if (
            keys.includes("create") ||
            keys.includes("read") ||
            keys.includes("update") ||
            keys.includes("delete")
          ) {
            data.create = Number(item.create) || 0;
            data["read"] = Number(item["read"]) || 0;
            data.update = Number(item.update) || 0;
            data.delete = Number(item.delete) || 0;
          }

          if (item.value) {
            data.value = Number(item.value) || 0;
          }

          return data;
        }
      });
      return httpServiceAuth
        .put(`${apiEndpoints.company.roles}/${id}/rolepermissions/bulk`, {
          permissions
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    syncPermissionValues({ state }) {
      const inRole = state.permissionsInRole;
      const permissions = state.permissionsList;
      if (inRole && inRole.data && inRole.data.length) {
        if (permissions && permissions.data && permissions.data.length) {
          inRole.data.forEach(item => {
            const index = permissions.data.findIndex(
              perm => perm.id === item.permission_id
            );
            if (index !== -1) {
              permissions.data[index].value = item.value;
              permissions.data[index].create = Boolean(item.create);
              permissions.data[index]["read"] = Boolean(item["read"]);
              permissions.data[index].update = Boolean(item.update);
              permissions.data[index].delete = Boolean(item.delete);
            }
          });
        }
      }
    }
  }
};
