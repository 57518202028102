export default {
  path: "/company-notifications",
  name: "r_company-notifications",
  meta: {
    middlewares: {
      auth: null,
      permission: "MANAGE_NOTIFICATIONS",
    },
  },
  component: () =>
    import("../../components/company-notifications/CompanyNotifications"),
  children: [
    {
      path: "add",
      name: "r_add-company-notification",
      meta: {
        middlewares: {
          auth: null,
          permission: ["MANAGE_NOTIFICATIONS", "create"],
        },
      },
      props: { backLinkName: "r_company-notifications" },
      component: () =>
        import("../../components/company-notifications/AddCompanyNotification"),
      children: [
        {
          path: "name-translations",
          name: "r_add-company-notification-translate-name",
          meta: {
            middlewares: {
              auth: null,
              permission: ["MANAGE_NOTIFICATIONS", "create"],
            },
          },
          props: {
            backLinkName: "r_add-company-notification",
          },
          component: () =>
            import(
              "../../components/company-notifications/TranslateCompanyNotificationName"
            ),
          children: [
            {
              path: "help",
              name: "r_add-company-notification-translate-name-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: ["MANAGE_NOTIFICATIONS", "create"],
                },
              },
              props: {
                backLinkName: "r_add-company-notification-translate-name",
                slug: "company-notification-translation",
              },
              component: () =>
                import("../../components/help-online/HelpOnline"),
            },
          ],
        },
        {
          path: "description-translations",
          name: "r_add-company-notification-translate-description",
          meta: {
            middlewares: {
              auth: null,
              permission: ["MANAGE_NOTIFICATIONS", "create"],
            },
          },
          props: {
            backLinkName: "r_add-company-notification",
          },
          component: () =>
            import(
              "../../components/company-notifications/TranslateCompanyNotificationDescription"
            ),
          children: [
            {
              path: "help",
              name:
                "r_add-company-notification-translate-description-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: ["MANAGE_NOTIFICATIONS", "create"],
                },
              },
              props: {
                backLinkName:
                  "r_add-company-notification-translate-description",
                slug: "company-notification-translation",
              },
              component: () =>
                import("../../components/help-online/HelpOnline"),
            },
          ],
        },
        {
          path: "send-to",
          name: "r_add-company-notification-send-to",
          meta: {
            middlewares: {
              auth: null,
              permission: ["MANAGE_NOTIFICATIONS", "create"],
            },
          },
          props: {
            backLinkName: "r_add-company-notification",
            componentTitle: "add-company-notification",
          },
          component: () =>
            import("../../components/company-notifications/SendTo"),
          children: [
            {
              path: "help",
              name: "r_add-company-notification-send-to-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: ["MANAGE_NOTIFICATIONS", "create"],
                },
              },
              props: {
                backLinkName: "r_add-company-notification-send-to",
                slug: "company-notification-translation",
              },
              component: () =>
                import("../../components/help-online/HelpOnline"),
            },
            {
              path: "user-containers",
              name: "r_add-company-notification-send-to-user-containers",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "MANAGE_NOTIFICATIONS",
                },
              },
              props: {
                backLinkName: "r_add-company-notification-send-to",
                slug: "company-notification-translation",
              },
              component: () =>
                import("../../components/company-notifications/UserContainers"),
              children: [
                {
                  path: "help",
                  name:
                    "r_add-company-notification-send-to-user-containers-help-online",
                  meta: {
                    middlewares: {
                      auth: null,
                      permission: ["MANAGE_NOTIFICATIONS", "create"],
                    },
                  },
                  props: {
                    backLinkName:
                      "r_add-company-notification-send-to-user-containers",
                    slug: "company-notification-translation",
                  },
                  component: () =>
                    import("../../components/help-online/HelpOnline"),
                },
              ],
            },
            {
              path: "users",
              name: "r_add-company-notification-send-to-users",
              meta: {
                middlewares: {
                  auth: null,
                  permission: ["MANAGE_NOTIFICATIONS", "create"],
                },
              },
              props: {
                backLinkName: "r_add-company-notification-send-to",
                slug: "company-notification-translation",
              },
              component: () =>
                import("../../components/company-notifications/Users"),
              children: [
                {
                  path: "help",
                  name: "r_add-company-notification-send-to-users-help-online",
                  meta: {
                    middlewares: {
                      auth: null,
                      permission: ["MANAGE_NOTIFICATIONS", "create"],
                    },
                  },
                  props: {
                    backLinkName: "r_add-company-notification-send-to-users",
                    slug: "company-notification-translation",
                  },
                  component: () =>
                    import("../../components/help-online/HelpOnline"),
                },
              ],
            },
          ],
        },
        {
          path: "send-at",
          name: "r_add-company-notification-send-at",
          meta: {
            middlewares: {
              auth: null,
              permission: ["MANAGE_NOTIFICATIONS", "create"],
            },
          },
          props: {
            backLinkName: "r_add-company-notification",
            componentTitle: "add-company-notification",
          },
          component: () =>
            import("../../components/company-notifications/SendAt"),
          children: [
            {
              path: "help",
              name: "r_add-company-notification-send-to-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: ["MANAGE_NOTIFICATIONS", "create"],
                },
              },
              props: {
                backLinkName: "r_add-company-notification-send-at",
                slug: "company-notification-translation",
              },
              component: () =>
                import("../../components/help-online/HelpOnline"),
            },
          ],
        },
        {
          path: "help",
          name: "r_company-notifications-add-help-online",
          meta: {
            middlewares: {
              auth: null,
              permission: ["MANAGE_NOTIFICATIONS", "create"],
            },
          },
          props: {
            backLinkName: "r_add-company-notification",
            slug: "company-notifications-add",
          },
          component: () => import("../../components/help-online/HelpOnline"),
        },
      ],
    },
    {
      path: ":company_notification_id",
      name: "r_edit-company-notification",
      meta: {
        middlewares: {
          auth: null,
          permission: ["MANAGE_NOTIFICATIONS", "read"],
        },
      },
      props: { backLinkName: "r_company-notifications" },
      component: () =>
        import(
          "../../components/company-notifications/EditCompanyNotification"
        ),
      children: [
        {
          path: "name-translations",
          name: "r_edit-company-notification-translate-name",
          meta: {
            middlewares: {
              auth: null,
              permission: ["MANAGE_NOTIFICATIONS", "read"],
            },
          },
          props: {
            backLinkName: "r_edit-company-notification",
            componentTitle: "edit-company-notification",
          },
          component: () =>
            import(
              "../../components/company-notifications/TranslateCompanyNotificationName"
            ),
          children: [
            {
              path: "help",
              name: "r_company-notifications-translation-edit-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: ["MANAGE_NOTIFICATIONS", "read"],
                },
              },
              props: {
                backLinkName: "r_edit-company-notification-translate-name",
                slug: "company-notification-translation",
              },
              component: () =>
                import("../../components/help-online/HelpOnline"),
            },
          ],
        },
        {
          path: "description-translations",
          name: "r_edit-company-notification-translate-description",
          meta: {
            middlewares: {
              auth: null,
              permission: ["MANAGE_NOTIFICATIONS", "read"],
            },
          },
          props: {
            backLinkName: "r_edit-company-notification",
            componentTitle: "edit-company-notification",
          },
          component: () =>
            import(
              "../../components/company-notifications/TranslateCompanyNotificationDescription"
            ),
          children: [
            {
              path: "help",
              name: "r_company-notifications-translation-edit-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: ["MANAGE_NOTIFICATIONS", "read"],
                },
              },
              props: {
                backLinkName:
                  "r_edit-company-notification-translate-description",
                slug: "company-notification-translation",
              },
              component: () =>
                import("../../components/help-online/HelpOnline"),
            },
          ],
        },
        {
          path: "send-to",
          name: "r_edit-company-notification-send-to",
          meta: {
            middlewares: {
              auth: null,
              permission: ["MANAGE_NOTIFICATIONS", "read"],
            },
          },
          props: {
            backLinkName: "r_edit-company-notification",
            componentTitle: "edit-company-notification",
          },
          component: () =>
            import("../../components/company-notifications/SendTo"),
          children: [
            {
              path: "help",
              name: "r_company-notifications-send-to-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: ["MANAGE_NOTIFICATIONS", "read"],
                },
              },
              props: {
                backLinkName: "r_edit-company-notification-send-to",
                slug: "company-notification-translation",
              },
              component: () =>
                import("../../components/help-online/HelpOnline"),
            },
            {
              path: "user-containers",
              name: "r_edit-company-notification-send-to-user-containers",
              meta: {
                middlewares: {
                  auth: null,
                  permission: ["MANAGE_NOTIFICATIONS", "read"],
                },
              },
              props: {
                backLinkName: "r_edit-company-notification-send-to",
                slug: "company-notification-translation",
              },
              component: () =>
                import("../../components/company-notifications/UserContainers"),
              children: [
                {
                  path: "help",
                  name:
                    "r_edit-company-notification-send-to-user-containers-help-online",
                  meta: {
                    middlewares: {
                      auth: null,
                      permission: ["MANAGE_NOTIFICATIONS", "read"],
                    },
                  },
                  props: {
                    backLinkName:
                      "r_edit-company-notification-send-to-user-containers",
                    slug: "company-notification-translation",
                  },
                  component: () =>
                    import("../../components/help-online/HelpOnline"),
                },
              ],
            },
            {
              path: "users",
              name: "r_edit-company-notification-send-to-users",
              meta: {
                middlewares: {
                  auth: null,
                  permission: ["MANAGE_NOTIFICATIONS", "read"],
                },
              },
              props: {
                backLinkName: "r_edit-company-notification-send-to",
                slug: "company-notification-translation",
              },
              component: () =>
                import("../../components/company-notifications/Users"),
              children: [
                {
                  path: "help",
                  name: "r_edit-company-notification-send-to-users-help-online",
                  meta: {
                    middlewares: {
                      auth: null,
                      permission: ["MANAGE_NOTIFICATIONS", "read"],
                    },
                  },
                  props: {
                    backLinkName: "r_edit-company-notification-send-to-users",
                    slug: "company-notification-translation",
                  },
                  component: () =>
                    import("../../components/help-online/HelpOnline"),
                },
              ],
            },
          ],
        },
        {
          path: "send-at",
          name: "r_edit-company-notification-send-at",
          meta: {
            middlewares: {
              auth: null,
              permission: ["MANAGE_NOTIFICATIONS", "read"],
            },
          },
          props: {
            backLinkName: "r_edit-company-notification",
            componentTitle: "edit-company-notification",
          },
          component: () =>
            import("../../components/company-notifications/SendAt"),
          children: [
            {
              path: "help",
              name: "r_company-notifications-send-at-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: ["MANAGE_NOTIFICATIONS", "read"],
                },
              },
              props: {
                backLinkName: "r_edit-company-notification-send-at",
                slug: "company-notification-translation",
              },
              component: () =>
                import("../../components/help-online/HelpOnline"),
            },
          ],
        },
        {
          path: "help",
          name: "r_company-notifications-edit-help-online",
          meta: {
            middlewares: {
              auth: null,
              permission: ["MANAGE_NOTIFICATIONS", "read"],
            },
          },
          props: {
            backLinkName: "r_edit-company-notification",
            slug: "company-notifications-edit",
          },
          component: () => import("../../components/help-online/HelpOnline"),
        },
      ],
    },
    {
      path: "help",
      name: "r_company-notifications-help-online",
      meta: {
        middlewares: {
          auth: null,
          permission: ["MANAGE_NOTIFICATIONS", "read"],
        },
      },
      props: {
        backLinkName: "r_company-notifications",
        slug: "company-notifications",
      },
      component: () => import("../../components/help-online/HelpOnline"),
    },
  ],
};
