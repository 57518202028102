export default [
  {
    path: "/connectors",
    name: "r_connector-types",
    meta: {
      middlewares: {
        auth: null,
        permission: "CONNECTORS"
      }
    },
    component: () => import("../../components/connectors/ConnectorTypes"),
    children: [
      {
        path: "connectors-overview",
        name: "r_connectors",
        meta: {
          middlewares: {
            auth: null,
            permission: "CONNECTORS"
          }
        },
        component: () => import("../../components/connectors/Connectors"),
        children: [
          {
            path: "connector",
            name: "r_connector",
            meta: {
              middlewares: {
                auth: null,
                permission: "CONNECTORS"
              }
            },
            component: () => import("../../components/connectors/Connector")
          }
        ]
      },
      {
        path: "connectors-intranet",
        name: "r_connectors-intranet",
        meta: {
          middlewares: {
            auth: null,
            permission: "CONNECTORS"
          }
        },
        component: () => import("../../components/connectors/Intranets"),
        children: [
          {
            path: "add",
            name: "r_connectors-intranet-add",
            meta: {
              middlewares: {
                auth: null,
                permission: "CONNECTORS"
              }
            },
            component: () => import("../../components/connectors/AddIntranet")
          },
          {
            path: "edit/:intranetId",
            name: "r_connectors-intranet-edit",
            meta: {
              middlewares: {
                auth: null,
                permission: "CONNECTORS"
              }
            },
            component: () => import("../../components/connectors/EditIntranet")
          }
        ]
      }
    ]
  },
  {
    path: "/company-connectors",
    name: "r_connector-types-company",
    meta: {
      middlewares: {
        auth: null,
        permission: "CONNECTORS"
      }
    },
    component: () => import("../../components/connectors/ConnectorTypes"),
    children: [
      {
        path: "connectors-overview",
        name: "r_connectors-company",
        meta: {
          middlewares: {
            auth: null,
            permission: "CONNECTORS"
          }
        },
        component: () => import("../../components/connectors/Connectors"),
        children: [
          {
            path: "connector",
            name: "r_connector-company",
            meta: {
              middlewares: {
                auth: null,
                permission: "CONNECTORS"
              }
            },
            component: () => import("../../components/connectors/Connector")
          }
        ]
      }
    ]
  }
];
