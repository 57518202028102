import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    widgets: null,
    contacts: null,
    plans: null,
    maps: null,
    periods: null,
    info: null,
    oneClickWidgets: null,
    setPlan: null,
    selectedResource: null,
    findPersonDeclarations: null,
    findPersonAssignments: null,
    findPersonUrl: null,
    mapTypes: [],
    mapUrl: null,
    infoUrl: null,
    infoName: null,
    findPersonMapPath: null
  },
  mutations: {
    setWidgets(state, payload) {
      state.widgets = payload;
    },
    setContacts(state, payload) {
      state.contacts = payload;
    },
    setPlans(state, payload) {
      state.plans = payload;
    },
    setMaps(state, payload) {
      state.maps = payload;
    },
    setPeriods(state, payload) {
      state.periods = payload;
    },
    setInfo(state, payload) {
      state.info = payload;
    },
    setOneClickWidgets(state, payload) {
      state.oneClickWidgets = payload;
    },
    setSetPlan(state, payload) {
      state.setPlan = payload;
    },
    setSelectedResource(state, payload) {
      state.selectedResource = payload;
    },
    setFindPersonDeclarations(state, payload) {
      state.findPersonDeclarations = payload;
    },
    setFindPersonAssignments(state, payload) {
      state.findPersonAssignments = payload;
    },
    setFindPersonUrl(state, payload) {
      state.findPersonUrl = payload;
    },
    setMapTypes(state, payload) {
      state.mapTypes = payload;
    },
    setMapUrl(state, payload) {
      state.mapUrl = payload;
    },
    setInfoName(state, payload) {
      state.infoName = payload;
    },
    setInfoUrl(state, payload) {
      state.infoUrl = payload;
    },
    setFindPersonMapPath(state, payload) {
      state.findPersonMapPath = payload;
    }
  },
  actions: {
    getWidgets({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.widgets}`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          const userWidgets = [];
          response.data.data.forEach(element => {
            if (element.user.length) {
              element.user[0].name = element.name;
              element.user[0].is_active = element.user[0].is_active
                ? true
                : false;
              userWidgets.push(element.user[0]);
            }
          });
          commit("setWidgets", userWidgets);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getContacts({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.widgetContacts}`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          response.data.persons.forEach(element => {
            element.is_active = element.is_active ? true : false;
          });
          commit("setContacts", response.data.persons);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getPlans({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.widgetPlans}`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setPlans", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getMaps({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.widgetMaps}`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setMaps", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getPeriods({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.widgetPeriods}`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setPeriods", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getInfo({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.widgetInfo}`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setInfo", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getOneClickWidgets({ commit }, loader = true) {
      if (loader) {
        commit("loader/setScreenLoading", true, { root: true });
      }
      const url = `${apiEndpoints.company.widgetOneClick}`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setOneClickWidgets", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          if (loader) {
            commit("loader/setScreenLoading", false, { root: true });
          }
        });
    },
    setWidgetUsers({ commit, dispatch }, widgets) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.widgetUsers}`;
      return httpServiceAuth
        .post(url, { widgets: widgets })
        .then(() => {
          dispatch("getOneClickWidgets");
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setPersons({ commit, dispatch }, persons) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.widgetContacts}`;
      return httpServiceAuth
        .post(url, { persons: persons })
        .then(() => {
          dispatch("getOneClickWidgets");
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setPlanPositions({ commit, dispatch }, plans) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.widgetPlanPositions}`;
      return httpServiceAuth
        .post(url, { plan: plans })
        .then(() => {
          dispatch("getOneClickWidgets");
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setMapPositions({ commit, dispatch }, maps) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.widgetMapPositions}`;
      return httpServiceAuth
        .post(url, { maps: maps })
        .then(() => {
          dispatch("getOneClickWidgets");
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setInfoPositions({ commit, dispatch }, info) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.widgetInfoPositions}`;
      return httpServiceAuth
        .post(url, { info: info })
        .then(() => {
          dispatch("getOneClickWidgets");
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setOneClickPositions({ commit }, widget) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.widgetOneClickPositions}`;
      return httpServiceAuth
        .post(url, { widget: widget })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    editInfo({ commit, state, dispatch }, info) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.widgetInfo}/${info.id}`;
      return httpServiceAuth
        .put(url, info)
        .then(response => {
          dispatch("getOneClickWidgets");
          const newInfo = state.info.map(el =>
            el.id !== response.data.data.id ? el : response.data.data
          );
          commit("setInfo", newInfo);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    createInfo({ commit, state, dispatch }, info) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.widgetInfo}`;
      return httpServiceAuth
        .post(url, info)
        .then(response => {
          dispatch("getOneClickWidgets");
          const newInfo = [...state.info, response.data.data];
          commit("setInfo", newInfo);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    createPlan({ commit, state, dispatch }, plan) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.widgetPlans}`;
      return httpServiceAuth
        .post(url, plan)
        .then(response => {
          dispatch("getOneClickWidgets");
          const newPlans = [...state.plans, response.data.data];
          commit("setPlans", newPlans);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    createMap({ commit, state, dispatch }, map) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.widgetMaps}`;
      return httpServiceAuth
        .post(url, map)
        .then(response => {
          dispatch("getOneClickWidgets");
          const newMaps = [...state.maps, response.data.data];
          commit("setMaps", newMaps);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setFindPersonList({ commit }, payload) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.widgetFindPerson}`;
      return httpServiceAuth
        .post(url, payload)
        .then(response => {
          commit(
            "setFindPersonDeclarations",
            response.data.data.user.declarations
          );
          commit(
            "setFindPersonAssignments",
            response.data.data.user.resource_assignments
          );
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    deletePlan({ commit, state, dispatch }, id) {
      const url = `${apiEndpoints.company.widgetPlans}/${id}`;
      return httpServiceAuth
        .delete(url)
        .then(() => {
          dispatch("getOneClickWidgets");
          const newPlans = state.plans.filter(plan => plan.id !== id);
          commit("setPlans", newPlans);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    deleteMap({ commit, state, dispatch }, id) {
      const url = `${apiEndpoints.company.widgetMaps}/${id}`;
      return httpServiceAuth
        .delete(url)
        .then(() => {
          dispatch("getOneClickWidgets");
          const newMaps = state.maps.filter(plan => plan.id !== id);
          commit("setMaps", newMaps);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    deleteInfo({ commit, state, dispatch }, id) {
      const url = `${apiEndpoints.company.widgetInfo}/${id}`;
      return httpServiceAuth
        .delete(url)
        .then(() => {
          dispatch("getOneClickWidgets");
          const newInfo = state.info.filter(info => info.id !== id);
          const postArray = [];
          let ordering = 1;
          newInfo.forEach(element => {
            postArray.push({
              id: element.id,
              ordering: ordering
            });
            ordering++;
          });
          dispatch("setInfoPositions", postArray);
          commit("setInfo", newInfo);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    }
  }
};
