import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    filters: null,
    filterPresets: null,
    filtersChanged: false,
    showAddPresetButton: true,
    filterPage: null,
    filterSection: null,
    presetToDelete: null,
    filtersClosed: false
  },
  mutations: {
    setFilters(state, payload) {
      state.filters = payload;
    },
    setFilterPresets(state, payload) {
      state.filterPresets = payload;
    },
    setFiltersChanged(state, payload) {
      state.filtersChanged = payload;
    },
    setShowAddPresetButton(state, payload) {
      state.showAddPresetButton = payload;
    },
    setFilterPage(state, payload) {
      state.filterPage = payload;
    },
    setFilterSection(state, payload) {
      state.filterSection = payload;
    },
    setPresetToDelete(state, payload) {
      state.presetToDelete = payload;
    },
    setFiltersClosed(state, payload) {
      state.filtersClosed = payload;
    }
  },
  actions: {
    selectFilterPreset({ commit, state }, id = false) {
      if (id) {
        state.filterPresets.forEach(filPreset => {
          filPreset.selected = id === filPreset.id ? true : false;
        });
        commit("setFiltersChanged", true);
      }
      const selectedPreset = state.filterPresets.find(
        preset => preset.selected
      );
      let presetFilters = [];
      let numberRangeFilterValue = 0;
      if (selectedPreset) {
        presetFilters = JSON.parse(JSON.stringify(selectedPreset)).filters;
        const numberRangeFilter = presetFilters.find(
          el => el.type === "number-range"
        );
        if (numberRangeFilter) {
          numberRangeFilterValue = numberRangeFilter.value;
        }
      } else {
        return;
      }
      let allFilters = [];
      state.filters.forEach(filter => {
        if (filter.type === "number-range") {
          filter.value = numberRangeFilterValue;
        }
        filter.selected = false;
        const prFilter = presetFilters.find(
          prFilter => prFilter.slug + prFilter.id === filter.slug + filter.id
        );
        if (prFilter) {
          prFilter.selected = true;
          allFilters.push(prFilter);
        } else {
          allFilters.push(filter);
        }
      });
      commit("setFilters", allFilters);
    },
    selectFilter({ commit, state, dispatch }, { filter, selected }) {
      dispatch("unSelectFilterPresets");
      let updatedFilters = [];
      if (filter.type === "single") {
        if (!selected) {
          return;
        } else {
          updatedFilters = state.filters.map(x =>
            (filter.excludes &&
              filter.excludes.length &&
              filter.excludes.some(slug => slug === x.slug)) ||
            (x.selected && x.type === "single" && x.slug === filter.slug)
              ? x.value && x.value > 0
                ? { ...x, value: 0 }
                : { ...x, selected: false }
              : x.slug + x.id === filter.slug + filter.id
              ? { ...x, selected: true }
              : x
          );
        }
      } else if (filter.type === "multi") {
        updatedFilters = state.filters.map(x =>
          filter.excludes &&
          filter.excludes.length &&
          filter.excludes.some(slug => slug === x.slug)
            ? x.value && x.value > 0
              ? { ...x, value: 0 }
              : { ...x, selected: false }
            : x.slug + x.id === filter.slug + filter.id
            ? { ...x, selected: selected }
            : x
        );
      }
      commit("setFilters", updatedFilters);
      if (filter.includes && filter.includes.length) {
        let filters = JSON.parse(JSON.stringify(state.filters));
        filter.includes.forEach(slug => {
          if (
            !filters.filter(fil => fil.slug === slug && fil.selected).length
          ) {
            let firstOfSection = filters.find(fil => fil.slug === slug);
            if (firstOfSection) {
              firstOfSection.selected = true;
            }
          }
        });
        commit("setFilters", filters);
      }
      if (filter.slug === "dates") {
        let filters = JSON.parse(JSON.stringify(state.filters));
        const dateRangeHours = filter.hoursInterval;
        const hoursInterval = filters.find(
          el => el.selected && el.slug === "intervals"
        );
        if (hoursInterval && dateRangeHours < hoursInterval.hoursInterval) {
          hoursInterval.selected = false;
          let newHoursInterval = filters.find(
            fil =>
              fil.slug === "intervals" && fil.hoursInterval < dateRangeHours
          );
          if (newHoursInterval) {
            newHoursInterval.selected = true;
          }
          commit("setFilters", filters);
        }
      }
      commit("setFiltersChanged", true);
    },
    unSelectFilterPresets({ commit, state }) {
      let updatedFilterPresets = [...state.filterPresets];
      updatedFilterPresets.forEach(filPreset => {
        filPreset.selected = false;
      });
      commit("setFilterPresets", updatedFilterPresets);
    },
    resetFilters({ commit }) {
      commit("setFilters", null);
      commit("setFilterPresets", null);
      commit("setFiltersChanged", false);
    },
    deleteFilterPreset({ commit, state, dispatch }) {
      if (!state.presetToDelete) {
        return;
      }
      if (state.filterPage === "count") {
        commit("loader/setScreenLoading", true, { root: true });
        httpServiceAuth
          .delete(
            `${apiEndpoints.company.countPresets}/${state.presetToDelete}`
          )
          .catch(error => {
            if (error.response) {
              errorHandler(error.response);
            }
          })
          .finally(() => {
            commit("loader/setScreenLoading", false, { root: true });
            dispatch("count/getFilters", state.filterSection, {
              root: true
            });
          });
      } else if (state.filterPage === "occupancy") {
        commit("loader/setScreenLoading", true, { root: true });
        httpServiceAuth
          .delete(
            `${apiEndpoints.company.occupancyPresets}/${state.presetToDelete}`
          )
          .catch(error => {
            if (error.response) {
              errorHandler(error.response);
            }
          })
          .finally(() => {
            commit("loader/setScreenLoading", false, { root: true });
            dispatch("occupancy/getOccupancyFilters", state.filterSection, {
              root: true
            });
          });
      }
    }
  }
};
