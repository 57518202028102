import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    devices: null,
    deviceUsers: null,
    selectedDevice: null
  },
  mutations: {
    setDevices(state, payload) {
      state.devices = payload;
    },
    setUsers(state, payload) {
      state.deviceUsers = payload;
    },
    setSelectedDevice(state, payload) {
      state.selectedDevice = payload;
    }
  },
  actions: {
    getDevices({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(apiEndpoints.company.devices)
        .then(response => {
          commit("devices/setDevices", response.data.data, {
            root: true
          });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getUsers({ commit, rootState }) {
      if (rootState.devices.selectedDevice) {
        const url = `${apiEndpoints.company.deviceUsers}?model_id=${rootState.devices.selectedDevice.id}`;
        return httpServiceAuth
          .get(url)
          .then(response => {
            commit("devices/setUsers", response.data.data, {
              root: true
            });
          })
          .catch(error => {
            if (error.response) {
              errorHandler(error.response);
            }
          });
      }
    },
    selectDevice({ commit }, device) {
      commit("devices/setSelectedDevice", device, {
        root: true
      });
    },
    setDeviceUser({ commit, rootState }, userId) {
      let userIds = [];
      if (userId) userIds.push(userId);
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .post(apiEndpoints.company.deviceUsers, {
          user_ids: userIds,
          model_id: rootState.devices.selectedDevice.id,
          type: rootState.devices.selectedDevice.type
        })
        .then(() => {
          rootState.devices.selectedDevice = null;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    }
  }
};
