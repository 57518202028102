import httpServiceAuth, { getSubdomain } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { preferenceValueLabels } from "@/services/preference-options";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    preferenceCategories: null,
    preferences: null,
    preferenceUpdateData: null,
    landingPagePreferenceUrl: null,
    menuPreferences: null,
  },
  mutations: {
    setPreferenceCategories(state, payload) {
      state.preferenceCategories = payload;
    },
    setPreferences(state, payload) {
      state.preferences = payload;
    },
    setPreferenceUpdateData(state, payload) {
      state.preferenceUpdateData = payload;
    },
    setLandingPagePreferenceUrl(state, payload) {
      state.landingPagePreferenceUrl = payload;
    },
    setMenuPreferences(state, payload) {
      state.menuPreferences = payload;
    },
  },
  actions: {
    getPreferenceCategories({ commit }) {
      const url = `${apiEndpoints.company.preferenceCategories}/all`;
      return httpServiceAuth
        .get(url, {
          params: { filters: ["has_user_preferences"] },
        })
        .then((response) => {
          commit("setPreferenceCategories", response.data.data);
          return response.data.data;
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    setPreferenceCategories({ commit }, payload) {
      commit("setPreferenceCategories", payload);
    },
    getUserPreferences({ dispatch }, catId) {
      const url = `${apiEndpoints.company.userPreferences}/all`;
      return httpServiceAuth
        .get(url, { params: { preference_category_ids: [catId] } })
        .then((response) => {
          dispatch("setPreferences", response.data.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    setPreferenceUpdateData({ commit }, payload) {
      commit("setPreferenceUpdateData", payload);
    },
    setPreferences({ commit, state, dispatch }, payload) {
      payload.forEach((preference) => {
        if (preferenceValueLabels[preference.preference]) {
          if (preference.preference_values.length) {
            preference.preference_values.forEach((prefValue) => {
              prefValue.label =
                preferenceValueLabels[preference.preference][prefValue.value];
            });
          }
          if (preference.preference_user_values.length) {
            preference.preference_user_values.forEach((prefValue) => {
              if (preferenceValueLabels[preference.preference]) {
                prefValue.label =
                  preferenceValueLabels[preference.preference][prefValue.value];
              }
            });
          }
        }
        if (
          preference.preference === "DEFAULT_LOCATION" &&
          !preference.is_automatic &&
          preference.preference_user_values.length &&
          preference.preference_user_values[0].value
        ) {
          httpServiceAuth
            .get(
              `${apiEndpoints.company.levels}/${preference.preference_user_values[0].value}`
            )
            .then((response) => {
              preference.preference_user_values[0].label =
                response.data.data.path;
              commit("setPreferences", payload);
              dispatch("getTimePreferences", state.preferenceCategories);
            })
            .catch((error) => {
              if (error.response) {
                errorHandler(error.response);
              }
            });
        } else if (
          preference.preference === "DEFAULT_LOCATION" &&
          preference.is_automatic &&
          preference.preference_values.length &&
          preference.preference_values[0].value
        ) {
          httpServiceAuth
            .get(
              `${apiEndpoints.company.levels}/${preference.preference_values[0].value}`
            )
            .then((response) => {
              preference.preference_values[0].label = response.data.data.path;
              commit("setPreferences", payload);
              dispatch("getTimePreferences", state.preferenceCategories);
            })
            .catch((error) => {
              if (error.response) {
                errorHandler(error.response);
              }
            });
        } else {
          commit("setPreferences", payload);
          dispatch("getTimePreferences", state.preferenceCategories);
        }
      });
    },
    getLandingPreferences({ commit }, data) {
      // used for initial redirect
      const subdomain = getSubdomain();
      if (subdomain) {
        if (data.length) {
          const landingPage = data.filter(
            (item) => item.category === "LANDING_PAGE"
          );
          if (landingPage && landingPage.length) {
            let url = apiEndpoints.master.userPreferences;
            if (subdomain) url = `${apiEndpoints.company.userPreferences}/all`;
            httpServiceAuth
              .get(url, {
                params: { preference_category_ids: [landingPage[0].id] },
              })
              .then((response) => {
                if (data.length) {
                  let preference = null;
                  if (
                    response.data.data[0].preference_user_values &&
                    response.data.data[0].preference_user_values.length
                  ) {
                    preference =
                      response.data.data[0].preference_user_values[0].value;
                  } else if (
                    response.data.data[0].preference_values &&
                    response.data.data[0].preference_values.length
                  ) {
                    preference =
                      response.data.data[0].preference_values[0].value;
                  }
                  if (preference === "MAIN_MENU") {
                    commit("setLandingPagePreferenceUrl", "r_home");
                  } else if (preference === "AUTHORIZE") {
                    commit("setLandingPagePreferenceUrl", "r_authorize");
                  } else if (preference === "BOOKING") {
                    commit("setLandingPagePreferenceUrl", "r_booking");
                  } else if (preference === "CLEAN") {
                    commit("setLandingPagePreferenceUrl", "r_clean");
                  } else if (preference === "DISTANCE") {
                    commit("setLandingPagePreferenceUrl", "r_distance");
                  } else if (preference === "DOCUMENTS") {
                    commit("setLandingPagePreferenceUrl", "r_documents");
                  } else if (preference === "EVENTS") {
                    commit("setLandingPagePreferenceUrl", "r_events");
                  } else if (preference === "MESSAGES") {
                    commit("setLandingPagePreferenceUrl", "r_messages");
                  } else if (preference === "OCCUPANCY") {
                    commit("setLandingPagePreferenceUrl", "r_occupancy");
                  } else if (preference === "PLAN") {
                    commit("setLandingPagePreferenceUrl", "r_plan");
                  } else if (preference === "REAL_TIME") {
                    commit("setLandingPagePreferenceUrl", "r_realtime");
                  } else if (preference === "SERVICES") {
                    commit("setLandingPagePreferenceUrl", "r_services");
                  } else if (preference === "SURVEY") {
                    commit("setLandingPagePreferenceUrl", "r_survey");
                  } else if (preference === "ONE_CLICK") {
                    commit("setLandingPagePreferenceUrl", "r_one-click");
                  } else if (preference === "COCKPIT") {
                    commit("setLandingPagePreferenceUrl", "r_cockpit");
                  } else if (preference === "5D_MAPS") {
                    commit("setLandingPagePreferenceUrl", "r_5dmaps");
                  }
                }
              });
          }
        }
      }
    },
    getTimePreferences({ commit }, data) {
      // used for initial redirect
      if (data.length) {
        const dateTimePreference = data.filter(
          (item) => item.category === "DATE_AND_TIME"
        );
        if (dateTimePreference && dateTimePreference.length) {
          const url = `${apiEndpoints.company.userPreferences}/all`;
          return httpServiceAuth
            .get(url, {
              params: { preference_category_ids: [dateTimePreference[0].id] },
            })
            .then(async (response) => {
              await commit(
                "settings/setUserTimePreferences",
                response.data.data,
                {
                  root: true,
                }
              );
            })
            .catch((error) => {
              if (error.response) {
                errorHandler(error.response);
              }
            });
        }
      }
    },
    getMenuPreferences({ commit, state }) {
      const menuPreference = state.preferenceCategories.filter(
        (item) => item.category === "MENU"
      );
      if (menuPreference && menuPreference.length) {
        const url = `${apiEndpoints.company.userPreferences}/all`;
        return httpServiceAuth
          .get(url, {
            params: { preference_category_ids: [menuPreference[0].id] },
          })
          .then(async (response) => {
            await commit("setMenuPreferences", response.data.data);
          })
          .catch((error) => {
            if (error.response) {
              errorHandler(error.response);
            }
          });
      }
    },
  },
};
