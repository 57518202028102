import Plan from "@/components/plans/Plan";
import { APPLICATIONS } from "@/services/consts/application";
// import Space from "@/components/plans/Space";

const PLAN_MIDDLEWARES = {
  auth: null,
  permission: "PLAN",
  document: { applicationId: APPLICATIONS.APP_PLAN.id },
  menuPreferences: "PLAN"
};

export default [
  {
    path: "/plan/:plan_level_id?",
    name: "r_plan",
    meta: {
      middlewares: PLAN_MIDDLEWARES
    },
    component: Plan,
    children: [
      {
        path: "resources",
        name: "r_plan-resources",
        meta: {
          middlewares: PLAN_MIDDLEWARES
        },
        component: () => import("../../components/plans/PlanResources"),
        children: [
          {
            path: "search-filters",
            name: "r_plan-search-filters",
            meta: {
              middlewares: PLAN_MIDDLEWARES
            },
            props: {
              backLinkName: "r_plan-resources",
              searchFiltersStore: "searchFiltersPlanResources"
            },
            component: () =>
              import("../../components/search/SearchFiltersResources"),
            children: [
              {
                path: "help",
                name: "r_plan-resources-filters-help-online",
                meta: {
                  middlewares: PLAN_MIDDLEWARES
                },
                props: {
                  backLinkName: "r_plan-search-filters",
                  slug: "plan-resources-filters"
                },
                component: () =>
                  import("../../components/help-online/HelpOnline")
              }
            ]
          },
          {
            path: "slots",
            name: "r_plan-resources-slots",
            meta: {
              middlewares: PLAN_MIDDLEWARES
            },
            props: {
              backLinkName: "r_plan-resources",
              helpLinkName: "r_plan-resources-slots-help-online"
            },
            component: () => import("../../components/plans/PlanSlots"),
            children: [
              {
                path: "maps",
                name: "r_plan-maps-slots",
                meta: {
                  middlewares: PLAN_MIDDLEWARES
                },
                props: {
                  backLinkName: "r_plan-resources-slots",
                  helpLinkName: "r_plan-resources-map-help-online"
                },
                component: () => import("../../components/plans/Plan-5d-map"),
                children: [
                  {
                    path: "help",
                    name: "r_plan-resources-map-help-online",
                    meta: {
                      middlewares: PLAN_MIDDLEWARES
                    },
                    props: {
                      backLinkName: "r_plan-maps-slots",
                      slug: "plan-resources-5dmap"
                    },
                    component: () =>
                      import("../../components/help-online/HelpOnline")
                  }
                ]
              },
              {
                path: "help",
                name: "r_plan-resources-slots-help-online",
                meta: {
                  middlewares: PLAN_MIDDLEWARES
                },
                props: {
                  backLinkName: "r_plan-resources-slots",
                  slug: "plan-resources-calendar"
                },
                component: () =>
                  import("../../components/help-online/HelpOnline")
              }
            ]
          },
          {
            path: "maps",
            name: "r_plan-maps",
            meta: {
              middlewares: PLAN_MIDDLEWARES
            },
            props: {
              backLinkName: "r_plan-resources",
              helpLinkName: "r_plan-map-help-online"
            },
            component: () => import("../../components/plans/Plan-5d-map"),
            children: [
              {
                path: "help",
                name: "r_plan-map-help-online",
                meta: {
                  middlewares: PLAN_MIDDLEWARES
                },
                props: {
                  backLinkName: "r_plan-maps",
                  slug: "plan-resources-5dmap"
                },
                component: () =>
                  import("../../components/help-online/HelpOnline")
              }
            ]
          },
          {
            path: "resource/:resource_id",
            name: "r_resource-level",
            meta: {
              middlewares: PLAN_MIDDLEWARES
            },
            component: () => import("../../components/plans/PlanResourceDetail")
          },
          {
            path: "help",
            name: "r_plan-resources-help-online",
            meta: {
              middlewares: PLAN_MIDDLEWARES
            },
            props: {
              backLinkName: "r_plan-resources",
              slug: "plan-resources"
            },
            component: () => import("../../components/help-online/HelpOnline")
          }
        ]
      },
      {
        path: "slots",
        name: "r_plan-slots",
        meta: {
          middlewares: PLAN_MIDDLEWARES
        },
        props: {
          backLinkName: "r_plan",
          helpLinkName: "r_plan-slots-help-online"
        },
        component: () => import("../../components/plans/PlanSlots"),
        children: [
          {
            path: "maps",
            name: "r_plan-slots-maps",
            meta: {
              middlewares: PLAN_MIDDLEWARES
            },
            props: {
              backLinkName: "r_plan-slots",
              helpLinkName: "r_plan-slots-map-help-online"
            },
            component: () => import("../../components/plans/Plan-5d-map"),
            children: [
              {
                path: "help",
                name: "r_plan-slots-map-help-online",
                meta: {
                  middlewares: PLAN_MIDDLEWARES
                },
                props: {
                  backLinkName: "r_plan-slots-maps",
                  slug: "plan-resources-5dmap"
                },
                component: () =>
                  import("../../components/help-online/HelpOnline")
              }
            ]
          },
          {
            path: "help",
            name: "r_plan-slots-help-online",
            meta: {
              middlewares: PLAN_MIDDLEWARES
            },
            props: {
              backLinkName: "r_plan-slots",
              slug: "plan-resources-calendar"
            },
            component: () => import("../../components/help-online/HelpOnline")
          }
        ]
      },
      {
        path: "location/:level_id",
        name: "r_plan-level",
        meta: {
          middlewares: PLAN_MIDDLEWARES
        },
        component: () => import("../../components/plans/PlanLevelDetail")
      },
      {
        path: "help",
        name: "r_plan-help-online",
        meta: {
          middlewares: PLAN_MIDDLEWARES
        },
        props: {
          backLinkName: "r_plan",
          slug: "plan"
        },
        component: () => import("../../components/help-online/HelpOnline")
      }
    ]
  },
  {
    path: "/calendar",
    name: "r_calendar",
    meta: {
      middlewares: PLAN_MIDDLEWARES
    },
    component: () => import("../../components/plans/Calendar")
  }
];
