<template>
  <mobile-screen
    :header="true"
    :screen-class="
      $route.name !== 'r_plan-help-online'
        ? 'gray-bg show-footer-only-on-last-screen'
        : 'gray-bg'
    "
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_plan-help-online',
        }"
      >
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("plan", "plan", "location") }}
        </div>
        <template v-slot:right>
          <button @click="toggleSearch">
            <icon icon="#cx-hea1-search" />
          </button>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper level-search icon-app1" v-if="search">
        <div class="search-field">
          <form @submit.prevent="submitSearch" novalidate>
            <input
              type="text"
              ref="searchForm"
              v-model="query"
              :placeholder="displayLabelName('plan', 'plan', 'search')"
            />
            <div class="icon search">
              <icon icon="#cx-app1-search-16x16" />
            </div>
          </form>
        </div>
      </section>
    </template>
    <template v-if="hasLevels || hasFrequentLevels || hasFavoriteLevels">
      <frequent-levels-list
        v-if="!query && hasFrequentLevels"
        :select-level="selectLevel"
        :selected-level="selectedLevel"
        :frequent-levels="frequentLevels"
        :display-parent-names="displayParentNames"
        :set-favorite-level="setFavoriteLevel"
        :unset-favorite-level="unsetFavoriteLevel"
      />

      <all-location-and-favorites-list
        v-if="!query && hasLevels"
        :select-level="selectLevel"
        :selected-level="selectedLevel"
        :levels="levels"
        :display-parent-names="displayParentNames"
        :set-favorite-level="setFavoriteLevel"
        :unset-favorite-level="unsetFavoriteLevel"
        :getFavoriteLevels="getFavoriteLevels"
      />

      <search-levels-list
        v-if="query && hasLevels"
        :select-level="selectLevel"
        :selected-level="selectedLevel"
        :levels="levels"
        :display-parent-names="displayParentNames"
        :set-favorite-level="setFavoriteLevel"
        :unset-favorite-level="unsetFavoriteLevel"
      />
    </template>

    <ul
      class="clebex-item-section icon-app1"
      v-if="!hasLevels && !hasFrequentLevels && !search"
    >
      <li class="clebex-item-section-item full-right-underline has-lead-icon">
        <span class="clebex-item-content-wrapper">
          <span class="lead-icon">
            <span class="lead-icon-item">
              <icon icon="#cx-app1-favourite" />
            </span>
          </span>
          <span class="label">
            {{ displayLabelName("plan", "plan", "no-favorites-defined") }}
          </span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon icon="#cx-app1-information" />
            </span>
            <span class="follow-up-icon-item">
              <icon icon="#cx-app1-arrow-right-12x12" width="12" height="12" />
            </span>
          </span>
        </span>
      </li>
    </ul>
    <template v-slot:footer>
      <plans-footer-menu :showCameraButton="showCameraButton">
        <qr-code-scanner-button :showCameraButton="showCameraButton"
      /></plans-footer-menu>
    </template>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import PlansFooterMenu from "@/components/plans/PlansFooterMenu";
import { mapActions, mapGetters, mapState } from "vuex";
import { errorHandler } from "@/services/error-handler";
import PlanMixin from "@/services/mixins/plan/plan-mixin";
import FrequentLevelsList from "@/components/plans/FrequentLevelsList";
import AllLocationAndFavoritesList from "@/components/plans/AllLocationAndFavoritesList";
import SearchLevelsList from "@/components/plans/SearchLevelsList";
import QrCodeScannerButton from "@/components/global/QrCodeScannerButton";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "plan",
  data() {
    return {
      query: "",
      search: false,
      timer: null,
      helpSlug: "plan",
    };
  },
  computed: {
    ...mapState("level", ["levels", "frequentLevels", "favoriteLevels"]),
    ...mapGetters("level", ["parentLevels"]),
    ...mapState("companyPreferences", ["preferences"]),
    showCameraButton() {
      const showCameraPreference = this.preferences?.find(
        (p) => p.preference === "SHOW_CAMERA_BUTTON"
      )?.preference_values?.[0]?.value;
      return Boolean(Number(showCameraPreference));
    },
    hasLevels() {
      const { levels } = this;
      return !!(levels && levels.data && levels.data.length);
    },
    hasFavoriteLevels() {
      const { favoriteLevels } = this;
      return (
        favoriteLevels && favoriteLevels.data && favoriteLevels.data.length
      );
    },
    hasFrequentLevels() {
      return !!(
        this.frequentLevels &&
        this.frequentLevels.data &&
        this.frequentLevels.data.length
      );
    },
    queryParams() {
      return this.$route.query;
    },
  },
  watch: {
    query() {
      this.$store.commit("level/setFavoritesState", null, { root: true });
      this.$router.push({ name: "r_plan" });
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.submitSearch();
      }, 300);
    },
    queryParams() {
      const { levelId, resourceId } = this.queryParams;
      const { levels } = this;
      if (levelId) {
        if (levels && levels.data && levels.data.length) {
          const filtered = levels.data.filter(
            (item) => item.id === Number(levelId)
          );
          if (filtered && filtered.length) {
            this.selectPlanLevel({
              includes: ["default", "directly_declarable", "resources_count"],
              level_id: filtered[0].id,
            });
            this.selectPlanResource(null);
            if (resourceId) {
              this.$router.push({
                name: "r_plan-resources",
                query: { resourceId: resourceId },
              });
            } else {
              this.$router.push({
                name: "r_plan-resources",
              });
            }
          }
        }
      }
    },
    selectedLevel(val) {
      if (val && val.is_directly_declarable && val.resources_count === 1) {
        this.$router.push({
          name: "r_plan-slots",
        });
      } else if (val) {
        this.$router.push({
          name: "r_plan-resources",
          params: this.$route.params,
        });
      }
    },
    levels() {
      this.levels.data.forEach((level) => {
        if (level.is_default) {
          this.selectLevel(level);
        }
      });
    },
  },
  async created() {
    this.getCompanyPreferences(10);
    this.setPaginationData(null);
    this.getResourceFunctionTypes();
    this.getResourceModels();
    this.getLevelsData();
    this.getCompanyPreferences(10);
    this.setSearchQuery(null);
  },
  methods: {
    ...mapActions("companyPreferences", ["getCompanyPreferences"]),
    ...mapActions("global", ["setMenuStatus"]),
    ...mapActions("search", ["setSearchQuery"]),
    ...mapActions("resource", [
      "getResourceModels",
      "getResourceFunctionTypes",
    ]),
    ...mapActions("level", [
      "getLevels",
      "getLevel",
      "setSelectedLevel", // TODO vidjeti da li treba
      "getSublevels",
      "unFavoriteLevel",
      "favoriteLevel",
    ]),
    ...mapActions("pagination", ["setPaginationData"]),
    ...mapActions("companyPreferences", ["getCompanyPreferences"]),
    viewSublevels(id) {
      // load sublevels
      this.$router.push({
        name: "r_plan",
        params: {
          ...this.$route.params,
          plan_level_id: id,
        },
      });
    },
    getFrequentLevels() {
      this.getLevels({
        frequent: 3,
        includes: ["all_parents", "declarable", "default"],
        depth: "ALL",
      });
    },
    getAllLevels() {
      this.getLevels({
        // favorites: 1,
        sort: ["DEFAULT_LEVEL", "FAVORITES"],
        includes: ["all_parents", "declarable", "default"],
        depth: "ALL",
      });
    },
    getFavoriteLevels() {
      this.$store.commit("level/setFavoritesState", null, { root: true });
      this.query = "";
      this.$router.push({
        name: "r_plan-resources",
        params: this.$route.params,
      });
      setTimeout(() => {
        this.getLevels({
          favorites: 1,
          includes: ["all_parents", "declarable", "default"],
          depth: "ALL",
        });
      }, 0);
    },
    getSearchLevels() {
      const data = {
        depth: "ALL",
        includes: ["all_parents", "declarable", "default"],
      };
      if (this.query) {
        data.query = this.query;
      }
      this.getLevels(data);
    },
    getLevelsData() {
      if (this.query) {
        this.getSearchLevels();
      }
      this.getFrequentLevels();
      this.getAllLevels();
    },
    setFavoriteLevel(id) {
      this.favoriteLevel(id)
        .then(() => {
          this.getLevelsData();
        })
        .catch((error) => {
          errorHandler(error.response);
        });
    },
    unsetFavoriteLevel(id) {
      this.unFavoriteLevel(id)
        .then(() => {
          this.getLevelsData();
        })
        .catch((error) => {
          errorHandler(error.response);
        });
    },
    selectLevel(level) {
      if (!level.is_declarable) {
        return;
      }
      this.$store.commit("plan/setPlanSelectedDate", null, { root: true });
      this.selectPlanLevel({
        includes: ["default", "directly_declarable", "resources_count"],
        level_id: level.id,
      });
      this.selectPlanResource(null);
    },
    submitSearch() {
      this.getSearchLevels();
    },
    toggleSearch() {
      this.query === "";
      this.search = !this.search;
    },
  },
  beforeUnmount() {
    // reset data
    // resource models
    this.$store.commit("resource/setResourceCapacityModels", null, {
      root: true,
    });
    // levels + favorites + frequent
    this.$store.commit("level/setLevelsState", null, { root: true });
    this.$store.commit("level/setFavoritesState", null, { root: true });
    this.$store.commit("level/setFrequentLevelsState", null, { root: true });

    // reset level
    this.selectPlanLevel(null);
  },
  mixins: [PlanMixin, helpOnlineMixin],
  components: {
    QrCodeScannerButton,
    MobileScreen,
    TopHeaderMenuWrapper,
    PlansFooterMenu,
    FrequentLevelsList,
    AllLocationAndFavoritesList,
    SearchLevelsList,
  },
};
</script>
