<template>
  <section class="top-header-menu" :class="{ [menuClass]: menuClass }">
    <div class="left-action">
      <slot name="left"></slot>
      <section v-if="!noHelpOnline">
        <router-link
          v-if="helpOnline && helpOnlineRoute && helpOnlineRoute.name"
          :to="helpOnlineRoute"
        >
          <icon icon="#cx-hea1-help" />
        </router-link>
        <button v-else class="disabled-button">
          <icon icon="#cx-hea1-help" />
        </button>
      </section>
    </div>
    <div class="center-info">
      <slot></slot>
    </div>
    <div class="right-action">
      <slot name="right"></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: "TopHeaderMenuWrapper",
  props: {
    menuClass: {
      required: false,
      type: String
    },
    helpOnline: {
      required: false,
      type: Boolean,
      default: false
    },
    helpOnlineRoute: {
      required: false,
      type: Object
    },
    noHelpOnline: {
      required: false,
      type: Boolean
    }
  }
};
</script>
