export default {
  path: "/company-preferences",
  name: "r_company-preferences",
  meta: {
    middlewares: {
      auth: null,
      permission: "COMPANY_PREFERENCES"
    }
  },
  component: () => import("../../components/company-preferences/Preferences"),
  children: [
    {
      path: ":categoryId",
      name: "r_company-preference-category",
      meta: {
        middlewares: {
          auth: null,
          permission: "COMPANY_PREFERENCES"
        }
      },
      component: () =>
        import("../../components/company-preferences/PreferenceCategory"),
      children: [
        {
          path: ":preferenceId",
          name: "r_set-company-preference",
          meta: {
            middlewares: {
              auth: null,
              permission: "COMPANY_PREFERENCES"
            }
          },
          component: () =>
            import("../../components/company-preferences/SetPreference"),
          children: [
            {
              path: "help",
              name: "r_company-preferences-set-preference-help-online",
              meta: {
                middlewares: {
                  auth: null
                }
              },
              props: {
                backLinkName: "r_set-company-preference"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "help",
          name: "r_company-preferences-category-help-online",
          meta: {
            middlewares: {
              auth: null,
              permission: "COMPANY_PREFERENCES"
            }
          },
          props: {
            backLinkName: "r_company-preference-category"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "help",
      name: "r_company-preferences-help-online",
      meta: {
        middlewares: {
          auth: null,
          permission: "COMPANY_PREFERENCES"
        }
      },
      props: {
        backLinkName: "r_company-preferences",
        slug: "preferences"
      },
      component: () => import("../../components/help-online/HelpOnline")
    }
  ]
};
