export default {
  path: "/service-types",
  name: "r_service-types",
  meta: {
    middlewares: {
      auth: null,
      permission: "SERVICE_TYPES"
    }
  },
  component: () => import("../../components/service-types/ServiceTypes"),
  children: [
    {
      path: "add",
      name: "r_add-service-type",
      meta: {
        middlewares: {
          auth: null,
          permission: ["MANAGE_SERVICE_TYPES", "create"]
        }
      },
      props: { backLinkName: "r_service-types" },
      component: () => import("../../components/service-types/AddServiceType"),
      children: [
        {
          path: "name-translations",
          name: "r_add-service-type-translate-name",
          meta: {
            middlewares: {
              auth: null,
              permission: ["MANAGE_SERVICE_TYPES", "create"]
            }
          },
          props: {
            backLinkName: "r_add-service-type",
            componentTitle: "add-service-type"
          },
          component: () =>
            import("../../components/service-types/TranslateServiceType"),
          children: [
            {
              path: "help",
              name: "service-types-translation-add-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: ["MANAGE_SERVICE_TYPES", "create"]
                }
              },
              props: {
                backLinkName: "r_add-service-type-translate-name",
                slug: "service-types-translation"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "help",
          name: "r_service-types-add-help-online",
          meta: {
            middlewares: {
              auth: null,
              permission: "SERVICE_TYPES"
            }
          },
          props: {
            backLinkName: "r_add-service-type",
            slug: "service-types-add"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: ":service_type_id",
      name: "r_edit-service-type",
      meta: {
        middlewares: {
          auth: null,
          permission: ["MANAGE_SERVICE_TYPES", "read"]
        }
      },
      props: { backLinkName: "r_service-types" },
      component: () => import("../../components/service-types/EditServiceType"),
      children: [
        {
          path: "name-translations",
          name: "r_edit-service-type-translate-name",
          meta: {
            middlewares: {
              auth: null,
              permission: ["MANAGE_SERVICE_TYPES", "read"]
            }
          },
          props: {
            backLinkName: "r_edit-service-type",
            componentTitle: "edit-service-type"
          },
          component: () =>
            import("../../components/service-types/TranslateServiceType"),
          children: [
            {
              path: "help",
              name: "r_service-types-translation-edit-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: ["MANAGE_SERVICE_TYPES", "read"]
                }
              },
              props: {
                backLinkName: "r_edit-service-type-translate-name",
                slug: "service-types-translation"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "help",
          name: "r_service-types-edit-help-online",
          meta: {
            middlewares: {
              auth: null,
              permission: ["MANAGE_SERVICE_TYPES", "read"]
            }
          },
          props: {
            backLinkName: "r_edit-service-type",
            slug: "service-types-edit"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "help",
      name: "r_service-types-help-online",
      meta: {
        middlewares: {
          auth: null,
          permission: "SERVICE_TYPES"
        }
      },
      props: {
        backLinkName: "r_service-types",
        slug: "service-types"
      },
      component: () => import("../../components/help-online/HelpOnline")
    }
  ]
};
