import { mapState } from "vuex";
import { getPermission } from "@/services/helpers";
import { getSubdomain } from "@/services/http-service";

export default {
  computed: {
    ...mapState("auth", ["generalPermissions", "fetchingGeneralPermissions"])
  },
  methods: {
    can(permissionName, permission) {
      if (Array.isArray(permissionName)) {
        permissionName = permissionName[0];
      }
      // if (process.env.NODE_ENV === "development") return true;
      if (!getSubdomain()) return true;
      const permissionObject = getPermission(
        permissionName,
        this.generalPermissions && this.generalPermissions.data
      );
      if (!permissionObject) {
        return false;
      }
      if (permissionObject.type !== "CRUD") {
        return permission ? false : permissionObject.is_active;
      }
      if (permission) {
        return permissionObject[permission]
          ? permissionObject[permission]
          : false;
      }
      return permissionObject.is_active;
    },
    canItem(item, permissionName) {
      // if (process.env.NODE_ENV === "development") return true;
      if (!getSubdomain()) return true;
      if (!item || !permissionName) return false;
      const { meta } = item;
      return meta && meta.permission && meta.permission[permissionName];
    },
    isMobileDevice() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (
        /windows phone/i.test(userAgent) ||
        /android/i.test(userAgent) ||
        (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
      ) {
        return true;
      }
      return false;
      // return (
      //   navigator.userAgent.toLowerCase().indexOf("android") > -1 ||
      //   navigator.userAgent.toLowerCase().indexOf("iphone") > -1
      // );
    },
    isWindowsDevice() {
      let platform = null;
      if (navigator.userAgentData && navigator.userAgentData.platform) {
        platform = navigator.userAgentData.platform;
      } else {
        platform = navigator.platform;
      }
      const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];

      if (windowsPlatforms.indexOf(platform) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    extractHeaderFileName(contentDispositionHeader) {
      let fileName = "";
      const matches = contentDispositionHeader.match(
        /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/gi
      );
      if (matches && matches.length) {
        fileName = matches
          .shift()
          .replace(/['"]/g, "")
          .replace("filename=", "");
      }
      return fileName;
    }
  }
};
