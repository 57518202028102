import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  namespaced: true,
  state: {
    spaceConfirmModalStatus: false,
    spaceWarningModalStatus: false,
    slots: null,
    selectedDate: null,
    occupancy: null,
    selectedSlot: null,
    selectedLevel: null,
    selectedResource: null,
    selectedFrequentResourceType: null,
    selectedResourceType: null,
    screenNum: 1,
    anyResourceTypes: null,
    resourcesLimit: null,
    planSelectedDate: null, // used for 5d maps
    mapData: null,
    cost: null
  },
  mutations: {
    updateConfirmModalStatus(state, payload) {
      state.spaceConfirmModalStatus = payload;
    },
    updateWarningModalStatus(state, payload) {
      state.spaceWarningModalStatus = payload;
    },
    setSlotsData(state, payload) {
      state.slots = payload;
    },
    setSelectedDate(state, payload) {
      state.selectedDate = payload;
    },
    setOccupancyData(state, payload) {
      state.occupancy = payload;
    },
    setSelectedSlot(state, payload) {
      state.selectedSlot = payload;
    },
    selectPlanLevel(state, payload) {
      state.selectedLevel = payload;
    },
    selectPlanResource(state, payload) {
      state.selectedResource = payload;
    },
    selectPlanFrequentResourceType(state, payload) {
      state.selectedFrequentResourceType = payload;
    },
    selectPlanResourceType(state, payload) {
      state.selectedResourceType = payload;
    },
    setScreenNum(state, payload) {
      state.screenNum = payload;
    },
    setAnyResourceTypes(state, payload) {
      state.anyResourceTypes = payload;
    },
    setResourcesLimit(state, payload) {
      state.resourcesLimit = payload;
    },
    setPlanSelectedDate(state, payload) {
      state.planSelectedDate = payload;
    },
    setMapData(state, payload) {
      state.mapData = payload;
    },
    setCost(state, payload) {
      state.cost = payload;
    }
  },
  actions: {
    updateConfirmModalStatus({ commit }, payload) {
      commit("updateConfirmModalStatus", payload);
    },
    updateWarningModalStatus({ commit }, payload) {
      commit("updateWarningModalStatus", payload);
    },
    getSlots({ commit }, { payload, noLoaders }) {
      if (!noLoaders) {
        commit("loader/setScreenLoading", true, { root: true });
      }
      commit("setSelectedSlot", null);
      const { type, types, id, date, timezone } = payload;
      const params = {
        types,
        date
      };

      if (timezone) {
        params.timezone = timezone;
      }
      return httpServiceAuth
        .get(`${apiEndpoints.company.slots}/${type}${id ? "/" + id : ""}`, {
          params
        })
        .then(response => {
          commit("setSlotsData", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          if (!noLoaders) {
            commit("loader/setScreenLoading", false, { root: true });
          }
        });
    },
    setSelectedDate({ commit }, payload) {
      commit("setSelectedDate", payload);
    },
    getOccupancy({ commit }, { payload, noLoaders }) {
      if (!noLoaders) {
        commit("loader/setScreenLoading", true, { root: true });
      }
      const { type, date, id } = payload;
      return httpServiceAuth
        .get(`${apiEndpoints.company.occupancy}/${type}/${id}?date=${date}`)
        .then(response => {
          commit("setOccupancyData", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          if (!noLoaders) {
            commit("loader/setScreenLoading", false, { root: true });
          }
        });
    },
    setSelectedSlot({ commit }, payload) {
      commit("setSelectedSlot", payload);
    },
    selectPlanLevel({ commit }, options) {
      if (!options) {
        commit("selectPlanLevel", null);
        return;
      }
      if (!options.noLoaders) {
        commit("loader/setScreenLoading", true, { root: true });
      }
      const params = {
        includes: options.includes
      };
      return httpServiceAuth
        .get(`${apiEndpoints.company.levels}/${options.level_id}`, { params })
        .then(response => {
          commit("selectPlanLevel", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          if (!options.noLoaders) {
            commit("loader/setScreenLoading", false, { root: true });
          }
        });
    },
    selectPlanResource({ commit }, payload) {
      commit("selectPlanResource", payload);
    },
    selectPlanFrequentResourceType({ commit }, payload) {
      commit("selectPlanFrequentResourceType", payload);
    },
    selectPlanResourceType({ commit }, payload) {
      commit("selectPlanResourceType", payload);
    },
    setScreenNum({ commit }, payload) {
      commit("setScreenNum", payload);
    },
    getResourcesLimit({ commit }, resource_id) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.planResourceLimit}/${resource_id}`)
        .then(response => {
          commit("setResourcesLimit", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    planScan({ commit }, { typeId, elementId }) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(
          `${apiEndpoints.company.planScan}?type_id=${typeId}&element_id=${elementId}`
        )
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    planDeclarationCost({ commit }, values) {
      commit("setCost", null);
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .post(apiEndpoints.company.declarationCost, values)
        .then(response => {
          commit("setCost", response.data);
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    }
  }
};
