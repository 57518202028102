export default {
  path: "/translations-sync",
  name: "r_translations-sync",
  meta: {
    middlewares: {
      auth: null
    }
  },
  component: () => import("../../components/translations-sync/TranslationsSync")
};
