<template>
  <div class="svg-icon">
    <component :is="currentComponent"></component>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  name: "svg-icon",
  computed: {
    currentComponent() {
      return defineAsyncComponent(
        () => import(`@/assets/svg-icons/svg-icon-${this.icon}.svg`)
      );
    },
  },
  props: {
    icon: {
      required: true,
      type: String,
    },
  },
};
</script>
