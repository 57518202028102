export default {
  path: "/logs",
  name: "r_logs",
  meta: {
    middlewares: {
      auth: null,
      permission: "LOG-FILES"
    }
  },
  component: () => import("../../components/logs/Logs")
};
