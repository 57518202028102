import httpServiceAuth, { getSubdomain } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    countries: null,
    selectedCountry: null
  },
  mutations: {
    setCountryData(state, payload) {
      state.countries = payload;
    },
    setSelectedCountry(state, payload) {
      state.selectedCountry = payload;
    }
  },
  actions: {
    getCountries({ commit }) {
      let url = `${apiEndpoints.master.countries}/all`;
      if (getSubdomain()) {
        url = `${apiEndpoints.company.countries}/all`;
      }
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setCountryData", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    setCountryData({ commit }, payload) {
      commit("setCountryData", payload);
    },
    setSelectedCountry({ commit }, payload) {
      commit("setSelectedCountry", payload);
    },
    deleteCountry({ dispatch }, id) {
      return httpServiceAuth
        .delete(`${apiEndpoints.master.countries}/${id}`)
        .then(() => {
          dispatch("getCountries");
        });
    }
  }
};
