import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    searchQuery: "",
    filters: null,
    presets: null,
    activePresets: null,
    inactivePresets: null,
    presetSection: null
  },
  mutations: {
    setSearchQuery(state, payload) {
      state.searchQuery = payload;
    },
    setFilters(state, payload) {
      state.filters = payload;
    },
    setPresets(state, payload) {
      state.presets = payload;
    },
    setActivePresets(state, payload) {
      state.activePresets = payload;
    },
    setInactivePresets(state, payload) {
      state.inactivePresets = payload;
    },
    setPresetSection(state, payload) {
      state.presetSection = payload;
    }
  },
  actions: {
    setSearchQuery({ commit }, query) {
      commit("setSearchQuery", query);
    },
    getFilters({ commit, dispatch }, { section, presetId }) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.searchFilters}/${section}`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          if (presetId) {
            commit("setFilters", response.data);
            if (!response.data.filters.find(el => el.endpoint)) {
              dispatch("setSelectedFilters", presetId);
            }
          } else {
            if (response.data.filters.length) {
              response.data.filters.forEach(element => {
                if (
                  Array.isArray(element.default) &&
                  element.default.length &&
                  element.values.length
                ) {
                  element.default.forEach(el => {
                    element.values.find(e => e.id === el.id).selected = true;
                  });
                } else if (element.default !== null) {
                  const defaultValue = element.values.find(
                    e => e.id === element.default
                  );
                  if (defaultValue) {
                    element.values.find(
                      e => e.id === element.default
                    ).selected = true;
                  }
                }
              });
            }
            commit("setFilters", response.data);
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getPresets({ commit }, section) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.searchPresets}/${section}`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          response.data.data.sort((a, b) => a.order - b.order);
          commit("setPresets", response.data.data);
          commit(
            "setActivePresets",
            response.data.data.filter(el => el.is_active)
          );
          commit(
            "setInactivePresets",
            response.data.data.filter(el => !el.is_active)
          );
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    savePreset({ state, dispatch }, name) {
      const url = `${apiEndpoints.company.searchPresets}`;
      let postObject = {
        name: name,
        section: state.presetSection,
        is_active: true,
        preset: {}
      };
      state.filters.filters.forEach(el => {
        if (!el.multi) {
          postObject.preset[el.slug] = ["number", "switch"].includes(el.type)
            ? el.default
            : el.values.find(e => e.selected).id;
        } else {
          postObject.preset[el.slug] = el.values
            .filter(e => e.selected)
            .map(e => e.id);
        }
      });
      return httpServiceAuth
        .post(url, postObject)
        .then(() => {
          dispatch("getPresets", state.presetSection);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    updatePreset({ state, dispatch }, preset) {
      const url = `${apiEndpoints.company.searchPresets}/${preset.id}`;
      let postObject = {
        name: preset.name,
        section: state.presetSection,
        is_active: preset.is_active,
        preset: {}
      };
      state.filters.filters.forEach(el => {
        if (!el.multi) {
          postObject.preset[el.slug] = ["number", "switch"].includes(el.type)
            ? el.default
            : el.values.find(e => e.selected).id;
        } else {
          postObject.preset[el.slug] = el.values
            .filter(e => e.selected)
            .map(e => e.id);
        }
      });
      return httpServiceAuth
        .put(url, postObject)
        .then(() => {
          dispatch("getPresets", state.presetSection);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    selectFilter({ state }, { section, value, numberIncrease }) {
      let updatedFilters = { ...state.filters };
      updatedFilters.filters.forEach(sec => {
        if (sec.type === "number") {
          if (sec.slug === section.slug) {
            sec.default = numberIncrease
              ? sec.default + 1
              : sec.default > 0
              ? sec.default - 1
              : 0;
          }
          if (section.excludes.some(slug => slug === sec.slug)) {
            sec.default = 0;
          }
        } else if (sec.type === "switch") {
          if (sec.slug === section.slug) {
            sec.default = value;
          }
          if (section.excludes.some(slug => slug === sec.slug)) {
            sec.default = false;
          }
        } else if (sec.type === "select") {
          sec.values.forEach(val => {
            // For clicked filter
            if (sec.slug === section.slug && val.id === value.id) {
              if (!section.multi && value.selected) {
                return;
              } else {
                val.selected = !value.selected;
              }
            }
            // For other filters
            else {
              if (
                (sec.slug === section.slug && !section.multi) ||
                section.excludes.some(slug => slug === sec.slug)
              ) {
                val.selected = false;
              }
            }
          });
        }
      });
    },
    clearAllFilters({ state, commit }) {
      if (
        state.filters &&
        state.filters.filters &&
        state.filters.filters.length
      ) {
        state.filters.filters.forEach(element => {
          if (element.type === "select") {
            element.values.forEach(el => {
              el.selected = false;
            });
            if (element.default && Array.isArray(element.default)) {
              element.default.forEach(el => {
                element.values.find(e => e.id === el.id).selected = true;
              });
            }
          }
          if (element.type === "number") {
            element.default = 0;
          }
        });
      }
      commit("setFilters", state.filters);
    },
    setSelectedFilters({ state, commit, dispatch }, presetId) {
      dispatch("clearAllFilters");
      const selectedPreset = state.presets.find(el => el.id == presetId).preset;
      if (
        selectedPreset &&
        state.filters &&
        state.filters.filters &&
        state.filters.filters.length
      ) {
        state.filters.filters.forEach(element => {
          if (element.type === "select" && element.values.length) {
            element.values.forEach(el => {
              el.selected = false;
            });
            Object.entries(selectedPreset).forEach(entry => {
              const [key, value] = entry;
              if (key === element.slug) {
                if (!element.multi) {
                  element.values.find(e => e.id === value).selected = true;
                } else {
                  value.forEach(el => {
                    element.values.find(e => e.id === el).selected = true;
                  });
                }
              }
            });
          }
          if (["number", "switch"].includes(element.type)) {
            Object.entries(selectedPreset).forEach(entry => {
              const [key, value] = entry;
              if (key === element.slug) {
                element.default = value;
              }
            });
          }
        });
        commit("setFilters", state.filters);
      }
    },
    activatePreset({ state, dispatch, commit }, id) {
      let selectedPreset = state.presets.find(el => el.id === id);
      selectedPreset.is_active = selectedPreset.is_active ? 0 : 1;
      commit(
        "setActivePresets",
        state.presets.filter(el => el.is_active)
      );
      commit(
        "setInactivePresets",
        state.presets.filter(el => !el.is_active)
      );
      commit("setPresets", state.presets);
      const url = `${apiEndpoints.company.searchPresets}/activate/${id}`;
      return httpServiceAuth
        .get(url)
        .then(() => {
          dispatch("getPresets", state.presetSection);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    orderPresets({ state, dispatch }) {
      let postArray = [];
      let ordering = 1;
      const fullArray = state.activePresets.concat(state.inactivePresets);
      fullArray.forEach(element => {
        postArray.push({
          id: element.id,
          ordering: ordering
        });
        ordering++;
      });
      const url = `${apiEndpoints.company.searchPresets}/order`;
      return httpServiceAuth
        .post(url, { order: postArray })
        .then(() => {
          dispatch("getPresets", state.presetSection);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    deletePreset({ state, dispatch }, id) {
      const url = `${apiEndpoints.company.searchPresets}/${id}`;
      return httpServiceAuth
        .delete(url)
        .then(() => {
          dispatch("getPresets", state.presetSection);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    }
  }
};
