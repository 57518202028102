import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { preferenceValueLabels } from "@/services/preference-options";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    preferenceCategories: null,
    preferences: null,
    preferenceUpdateData: null
  },
  mutations: {
    setPreferenceCategories(state, payload) {
      state.preferenceCategories = payload;
    },
    setPreferences(state, payload) {
      state.preferences = payload;
    },
    setPreferenceUpdateData(state, payload) {
      state.preferenceUpdateData = payload;
    }
  },
  actions: {
    getPreferenceCategories({ commit }) {
      const url = `${apiEndpoints.company.preferenceCategories}/all`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setPreferenceCategories", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    getCompanyPreferences({ dispatch }, catId) {
      const url = `${apiEndpoints.company.companyPreferences}/all`;
      return httpServiceAuth
        .get(url, { params: { preference_category_ids: [catId] } })
        .then(response => {
          dispatch("setPreferences", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    setPreferenceUpdateData({ commit }, payload) {
      commit("setPreferenceUpdateData", payload);
    },
    setPreferences({ commit }, payload) {
      payload.forEach(preference => {
        if (preferenceValueLabels[preference.preference]) {
          if (preference.preference_values.length) {
            preference.preference_values.forEach(prefValue => {
              prefValue.label =
                preferenceValueLabels[preference.preference][prefValue.value];
            });
          }
        }
        if (
          preference.preference === "DEFAULT_LOCATION" &&
          preference.preference_values.length &&
          preference.preference_values[0].value
        ) {
          httpServiceAuth
            .get(
              `${apiEndpoints.company.levels}/${preference.preference_values[0].value}`
            )
            .then(response => {
              preference.preference_values[0].label = response.data.data.name;
              commit("setPreferences", payload);
            })
            .catch(error => {
              if (error.response) {
                errorHandler(error.response);
              }
            });
        } else {
          commit("setPreferences", payload);
        }
      });
    }
  }
};
