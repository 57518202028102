import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    helpOnline: {}
  },
  mutations: {
    setHelpOnline(state, payload) {
      state.helpOnline = payload;
    }
  },
  actions: {
    getHelpOnline({ commit, state }, slug) {
      const url = `${apiEndpoints.company.helpOnline}${
        slug ? "?slug=" + slug : ""
      }`;
      return httpServiceAuth
        .get(url)
        .then(({ data }) => {
          state.helpOnline[slug] = data.data;
          commit("setHelpOnline", state.helpOnline);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    }
  }
};
