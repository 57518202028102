import dayjs from "dayjs";
import { getLang } from "@/services/http-service";

export function formatDate(date, format, localised) {
  if (typeof date === "object") {
    const { day, month, year } = date;
    if (day && month && year) {
      return dayjs(`${year || dayjs().year()}-${month}-${day}`).format(format);
    } else {
      return "";
    }
  } else if (typeof date === "string") {
    const locale = getLang();
    if (localised) {
      return dayjs(date, ["D-MMMM-YYYY", "YYYY-MM-DD"], locale).format(format);
    }
    return dayjs(date).format(format);
  }
  return "Wrong date format";
}

export function convertDateToUTC(date, format) {
  const offset = dayjs().utcOffset(); // get time offset (minutes)
  // set offset for date
  const dateWithOffset = dayjs(date, format)
    .utcOffset(offset)
    .toISOString();
  // convert to UTC and format
  return dayjs(dateWithOffset)
    .utc()
    .format("DD-MM-YYYY HH:mm");
}

export function hasTouch() {
  return window.matchMedia("(hover: none) and (pointer: coarse)").matches;
}

export function capitalizeString(term) {
  return term.charAt(0).toUpperCase() + term.slice(1);
}

export function getPermission(permissionName, permissions) {
  if (!permissionName || !permissions) return false;
  return permissions.filter(item => item.permission === permissionName)[0];
}

export function getScreenId(el) {
  const closesEl = el.closest("[data-id]");
  if (closesEl) {
    return closesEl.dataset.id;
  } else {
    // if no element is provided show error message on last screen
    const modalEl = document.querySelectorAll(".screen");
    return modalEl[modalEl.length - 1].dataset.id;
  }
}

export function getOffsetFromDateString(date) {
  if (date.indexOf("+") !== -1) {
    return `+${date.split("+")[1]}`;
  } else {
    const splitArr = date.split("-");
    return `-${splitArr[splitArr.length - 1]}`;
  }
}

export function getTimezoneCodeFromOffset({ offset, timezones }) {
  return timezones
    .filter(item => item.offset === offset)
    .map(item => item.code)[0];
}

/**
 * Returns the date in its own timezone time
 * Example: if it's 12:35, regardless of the timezone return 12:35
 * Important for booking. Booking is done in local time ALWAYS.
 * @param date -> date as a string with timezone offset
 * @param format -> date format to return
 * @param timezones -> object collection of timezones
 * @returns {string}
 */
export function displayDateWithTimezone({
  date,
  format,
  timezones,
  noTimezone = false
}) {
  if (noTimezone) {
    return dayjs(date).format(format);
  }
  if (timezones && timezones.data) {
    const localZone = dayjs.tz.guess();
    const offset = getOffsetFromDateString(date);
    const offsetInt = Number(offset.replace(":", "").replace(/0/g, ""));
    const zone = getTimezoneCodeFromOffset({
      offset,
      timezones: timezones.data
    });
    if (localZone === zone) {
      return dayjs(date).format(format);
    } else {
      if (offsetInt < 0) {
        return dayjs(date)
          .utc()
          .subtract(offsetInt.toString().replace("-", ""), "hour")
          .format(format);
      } else if (offsetInt > 0) {
        return dayjs(date)
          .utc()
          .add(offsetInt.toString().replace("+", ""), "hour")
          .format(format);
      } else {
        return dayjs(date)
          .utc()
          .format(format);
      }
    }
  }
}

export function getDateWithTimezone({ date, timezones, timezone }) {
  if (timezones && timezones.data) {
    const offset = timezones.data
      .filter(item => item.code === timezone)
      .map(item => item.offset)[0];

    const offsetInt = Number(offset.replace(":", "").replace(/0/g, ""));

    if (offsetInt < 0) {
      return dayjs(date)
        .utc()
        .subtract(offsetInt.toString().replace("-", ""), "hour");
    } else if (offsetInt > 0) {
      return dayjs(date)
        .utc()
        .add(offsetInt.toString().replace("+", ""), "hour");
    } else {
      return dayjs(date).utc();
    }
  }
}

//preferences names converted to camelCase
export function toCamelCase(term) {
  var camelWord = "";
  var splitTerm = term.toLowerCase().split("_");
  for (var i = 0; i < splitTerm.length; i++) {
    if (i == 0) {
      camelWord = camelWord + splitTerm[i];
    } else {
      camelWord = camelWord + capitalizeString(splitTerm[i]);
    }
  }
  return camelWord;
}

// Converting RGB to HEX values
export function rgbToHex(rgbValue) {
  const rgbRegExp = new RegExp(
    /^(\d{1,3}),\s?(\d{1,3}),\s?(\d{1,3}),?\s?(\d{1,3})?$/,
    "i"
  );
  if (!rgbRegExp.test(rgbValue)) {
    return "";
  }
  rgbValue = rgbValue.split(",");
  let red = rgbValue[0].trim();
  let green = rgbValue[1].trim();
  let blue = rgbValue[2].trim();

  return "#" + ((red << 16) | (green << 8) | blue).toString(16).toUpperCase();
}

// Converting HEX to RGB values
export function hexToRGB(hexValue) {
  const hexRegExp = new RegExp(/^#?([a-f0-9]{6}|[a-f0-9]{3})$/, "i");
  if (!hexRegExp.test(hexValue)) {
    return "";
  }
  hexValue = hexValue.replace("#", "");
  const arrBuffer = new ArrayBuffer(4);
  const dataView = new DataView(arrBuffer);
  dataView.setUint32(0, parseInt(hexValue, 16), false);
  const arrByte = new Uint8Array(arrBuffer);

  return arrByte[1] + ", " + arrByte[2] + ", " + arrByte[3] + ", 100";
}
