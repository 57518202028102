export default {
  state: {
    sections: ["sso", "visitor", "containers", "assigned_resources"],
    selectedSection: "sso",
    sso: 0,
    visitor: 0,
    selectedUserContainersIds: [],
    selectedAssignedResourcesIds: [],
    searchActiveNum: 0 // track api calls
  },
  mutations: {
    setSelectedSection(state, payload) {
      state.selectedSection = payload;
    },
    setSelectedUserContainersIds(state, payload) {
      const arr = [...state.selectedUserContainersIds];
      const index = arr.findIndex(item => item === payload);
      if (index === -1) {
        arr.push(payload);
      } else {
        arr.splice(index, 1);
      }
      state.selectedUserContainersIds = arr;
    },
    setSelectedAssignedResourcesIds(state, payload) {
      const arr = [...state.selectedAssignedResourcesIds];
      const index = arr.findIndex(item => item === payload);
      if (index === -1) {
        arr.push(payload);
      } else {
        arr.splice(index, 1);
      }
      state.selectedAssignedResourcesIds = arr;
    },
    setSso(state) {
      state.sso = !state.sso;
    },
    setVisitor(state) {
      state.visitor = !state.visitor;
    },
    activateSearch(state, payload) {
      if (payload) {
        state.searchActiveNum === 1
          ? (state.searchActiveNum = 2)
          : (state.searchActiveNum = 1);
      } else {
        state.searchActiveNum = 0;
      }
    },
    clearAllFilters(state) {
      state.sso = 0;
      state.visitor = 0;
      state.selectedUserContainersIds = [];
      state.selectedAssignedResourcesIds = [];
      state.searchActivated = 0;
    }
  },
  actions: {
    setSelectedSection({ commit }, payload) {
      commit("setSelectedSection", payload);
    },
    setSelectedUserContainersIds({ commit }, payload) {
      commit("setSelectedUserContainersIds", payload);
    },
    setSelectedAssignedResourcesIds({ commit }, payload) {
      commit("setSelectedAssignedResourcesIds", payload);
    },
    setSso({ commit }, payload) {
      commit("setSso", payload);
    },
    setVisitor({ commit }, payload) {
      commit("setVisitor", payload);
    },
    activateSearch({ commit }, payload) {
      commit("activateSearch", payload);
    }
  }
};
