import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
// import store from "@/store";

export default {
  state: {
    levels: null,
    level: null,
    selectedLevel: null,
    favoriteLevels: null,
    frequentLevels: null,
    maps: null,
    selectedMap: null,
    unsetMapValue: false,
    levelResources: null,
    frequentLevelResources: null,
    levelTranslations: [],
    workingHoursFrom: null,
    workingHoursTo: null,
    qrCode: null
  },
  getters: {
    parentLevels(state) {
      if (state.level && state.level.data) {
        if (state.level.data.all_parents) {
          const stateLevelDataCopy = { ...state.level.data };
          delete stateLevelDataCopy.all_parents;
          delete stateLevelDataCopy.all_children;
          const stateLevelParentsCopy = [
            stateLevelDataCopy,
            ...state.level.data.all_parents
          ];
          return stateLevelParentsCopy.reverse();
        }
        return [state.level.data];
      }
      return null;
    }
  },
  mutations: {
    setLevelsState(state, payload) {
      state.levels = payload;
    },
    setSelectedLevel(state, payload) {
      state.selectedLevel = payload;
    },
    setLevelData(state, payload) {
      state.level = payload;
    },
    setFavoritesState(state, payload) {
      state.favoriteLevels = payload;
    },
    setMapsData(state, payload) {
      state.maps = payload;
    },
    selectMap(state, payload) {
      state.selectedMap = payload;
    },
    setLevelResources(state, payload) {
      state.levelResources = payload;
    },
    setFrequentLevelsState(state, payload) {
      state.frequentLevels = payload;
    },
    setFrequentLevelResources(state, payload) {
      state.frequentLevelResources = payload;
    },
    setLevelTranslations(state, payload) {
      state.levelTranslations = payload;
    },
    unsetMap(state, payload) {
      state.unsetMapValue = payload;
    },
    setWorkingHoursFrom(state, payload) {
      state.workingHoursFrom = payload;
    },
    setWorkingHoursTo(state, payload) {
      state.workingHoursTo = payload;
    },
    setQrCode(state, payload) {
      state.qrCode = payload;
    }
  },
  actions: {
    getLevels({ commit }, newparams) {
      const params = {
        depth: "TOP", // default - will be replaced with new params if depth is defined
        ...newparams
      };

      // let whereToSave = "level/setLevelsState";
      // if (params.favorites) {
      //   whereToSave = "level/setFavoritesState";
      // } else if (params.frequent) {
      //   whereToSave = "level/setFrequentLevelsState";
      // }

      // store.dispatch("pagination/paginate", {
      //   whatToCall: "level/getLevels",
      //   whereToSave: whereToSave,
      //   url: apiEndpoints.company.levels,
      //   data: state.levels,
      //   params: params
      // });

      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.levels}/all`, { params })
        .then(response => {
          if (params.favorites) {
            commit("setFavoritesState", response.data);
          } else if (params.frequent) {
            commit("setFrequentLevelsState", response.data);
          } else {
            commit("setLevelsState", response.data);
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setFavoritesState({ commit }, payload) {
      commit("setFavoritesState", payload);
    },
    setFrequentLevelsState({ commit }, payload) {
      commit("setFrequentLevelsState", payload);
    },
    setSelsetLevelsStateectedLevel({ commit }, payload) {
      commit("setLevelsState", payload);
    },
    getSublevels({ commit }, levelId) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.levels}/${levelId}/sublevels`)
        .then(response => {
          commit("setLevelsState", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getLevel({ commit }, levelId) {
      commit("loader/setScreenLoading", true, { root: true });
      commit("setLevelTranslations", []);
      return httpServiceAuth
        .get(`${apiEndpoints.company.levels}/${levelId}`, {
          params: {
            includes: ["all_parents"]
          }
        })
        .then(response => {
          commit("setLevelData", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedLevel({ commit }, payload) {
      commit("setSelectedLevel", payload);
    },
    deleteLevel({ dispatch, commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .delete(`${apiEndpoints.company.levels}/${id}`)
        .then(() => {
          dispatch("getLevels");
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    deleteLevels({ dispatch, commit }, payload) {
      commit("loader/setScreenLoading", true, { root: true });
      const { level_id, sublevels } = payload;
      return httpServiceAuth
        .delete(`${apiEndpoints.company.levels}/${level_id}/sublevels`, {
          data: {
            level_ids: sublevels
          }
        })
        .then(() => {
          dispatch("getLevels");
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    favoriteLevel({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .post(`${apiEndpoints.company.levels}/${id}/favor`)
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    unFavoriteLevel({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .post(`${apiEndpoints.company.levels}/${id}/disfavor`)
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    resetLevelsState({ commit }) {
      commit("setLevelsState", null);
      commit("setSelectedLevel", null);
      commit("setLevelData", null);
      commit("setFavoritesState", null);
    },
    getMaps({ commit }, params) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.maps}/all`, { params })
        .then(response => {
          commit("setMapsData", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    selectMap({ commit }, payload) {
      commit("selectMap", payload);
    },
    displayMapName({ state }, id) {
      if (state.maps && state.maps.data && state.maps.data.length) {
        const filtered = state.maps.data.filter(item => item.id === id);
        if (filtered && filtered.length) {
          return filtered[0].name;
        }
        return "";
      }
      return "";
    },
    getLevelResources({ rootState, commit }, data) {
      commit("loader/setScreenLoading", true, { root: true });
      const { levelId, params } = data;

      if (rootState.search.searchQuery && !params.query) {
        params.query = rootState.search.searchQuery;
      }

      if (rootState.searchFiltersPlanResources.selectedTypeIds.length) {
        const hasResourceTypesIdx =
          params.filters &&
          params.filters.findIndex(item => item.type === "resource_type");
        if (hasResourceTypesIdx !== -1) {
          params.filters[hasResourceTypesIdx].value = [
            ...rootState.searchFiltersPlanResources.selectedTypeIds,
            ...params.filters[hasResourceTypesIdx].value
          ];
        } else {
          if (params.filters) {
            params.filters.push({
              type: "resource_type",
              value: [...rootState.searchFiltersPlanResources.selectedTypeIds]
            });
          } else {
            params.filters = [
              {
                type: "resource_type",
                value: [...rootState.searchFiltersPlanResources.selectedTypeIds]
              }
            ];
          }
        }
      }

      if (rootState.searchFiltersPlanResources.selectedModelIds.length) {
        const hasResourceModelIdx =
          params.filters &&
          params.filters.findIndex(item => item.type === "resource_model");
        if (hasResourceModelIdx !== -1) {
          params.filters[hasResourceModelIdx].value = [
            ...rootState.searchFiltersPlanResources.selectedModelIds,
            ...params.filters[hasResourceModelIdx].value
          ];
        } else {
          if (params.filters) {
            params.filters.push({
              type: "resource_model",
              value: [...rootState.searchFiltersPlanResources.selectedModelIds]
            });
          } else {
            params.filters = [
              {
                type: "resource_model",
                value: [
                  ...rootState.searchFiltersPlanResources.selectedModelIds
                ]
              }
            ];
          }
        }
      }

      if (rootState.searchFiltersPlanResources.selectedAttributesIds.length) {
        const hasResourceAttributeIdx =
          params.filters &&
          params.filters.findIndex(item => item.type === "resource_attribute");
        if (hasResourceAttributeIdx !== -1) {
          params.filters[hasResourceAttributeIdx].value = [
            ...rootState.searchFiltersPlanResources.selectedAttributesIds,
            ...params.filters[hasResourceAttributeIdx].value
          ];
        } else {
          if (params.filters) {
            params.filters.push({
              type: "resource_attribute",
              value: [
                ...rootState.searchFiltersPlanResources.selectedAttributesIds
              ]
            });
          } else {
            params.filters = [
              {
                type: "resource_attribute",
                value: [
                  ...rootState.searchFiltersPlanResources.selectedAttributesIds
                ]
              }
            ];
          }
        }
      }

      if (rootState.searchFiltersPlanResources.selectedCapacity) {
        const hasResourceCapacity =
          params.filters &&
          params.filters.findIndex(item => item.type === "capacity");
        if (hasResourceCapacity === -1) {
          if (params.filters) {
            params.filters.push({
              type: "capacity",
              value: rootState.searchFiltersPlanResources.selectedCapacity
            });
          } else {
            params.filters = [
              {
                type: "capacity",
                value: rootState.searchFiltersPlanResources.selectedCapacity
              }
            ];
          }
        }
      }

      return httpServiceAuth
        .get(`${apiEndpoints.company.levels}/${levelId}/resources/all`, {
          params
        })
        .then(response => {
          if (params && params.frequent) {
            commit("setFrequentLevelResources", response.data);
          } else {
            commit("setLevelResources", response.data);
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    unsetMap({ commit }, payload) {
      commit("unsetMap", payload);
    }
  }
};
