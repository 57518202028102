import store from "@/store";
import { getSubdomain } from "@/services/http-service";

function handleRoutePermissions(meta, permissions) {
  if (!meta) return false;

  /** meta can be a string or array:
   - In case of string it's a permission name or multiple permissions since it may be  comma separated string.
   - If meta is array we expect only two elemets for now:
      [0] String - permission name(or names)
      [1] String - crud
  */
  let permission = meta;
  let crud = null;
  if (Array.isArray(meta)) {
    permission = meta[0];
    crud = meta[1];
  }
  // default permission

  // list of permissions
  const data = permissions && permissions.data;
  let isAccessAllowed = false;
  let isCrudAllowed = false;
  if (data) {
    const permissionArray = permission.replace(/\s+/g, "").split(",");
    permissionArray.forEach(permission => {
      const currentPermission = data.filter(
        item => item.permission === permission
      );
      if (currentPermission && currentPermission.length) {
        if (currentPermission[0].type === "CRUD" && crud) {
          isCrudAllowed = !isCrudAllowed
            ? currentPermission[0][crud]
            : isCrudAllowed;
        } else {
          isAccessAllowed = !isAccessAllowed
            ? currentPermission[0].is_active
            : isAccessAllowed;
        }
      }
    });
  }
  return isAccessAllowed || isCrudAllowed;
}

export default async function permission({ data }) {
  if (!getSubdomain()) {
    return;
  }
  // permissions object exists
  // check my permission for this route
  if (!handleRoutePermissions(data, store.state.auth.generalPermissions)) {
    // don't have permission for this route
    return {
      name: "r_error"
    };
  }
  return;
}
