import store from "@/store";
import { isLoggedIn } from "@/services/http-service";
// Main interceptor is first one executed.
// It shoud return null otherwise all other middlewares will be skipped
// If you have any complex logic plase create new midddleware
export default async function main({ to }) {
  // close menu if opened between routes
  if (store.state.global.mainMenuStatus) {
    store.commit("global/setMenuStatus", false, { root: true });
  }

  // main menu change logic
  if (to.matched[0].name === "r_count") {
    store.commit("navigation/setNavigationInUse", "countNavigation", {
      root: true
    });
  } else if (to.matched[0].name === "r_occupancy") {
    store.commit("navigation/setNavigationInUse", "occupancyNavigation", {
      root: true
    });
    if (!store.state.occupancy.settings) {
      store.dispatch("occupancy/getSettings");
    }
  } else if (store.state.navigation.navigationInUse !== "navigation") {
    store.commit("navigation/setNavigationInUse", "navigation", {
      root: true
    });
  }

  // Redirect logged in user to home page
  if (
    isLoggedIn() &&
    (to.name === "r_tmp-code" || to.name === "r_login") &&
    !store.state.auth.isOutlookPage
  ) {
    return { name: "r_home" };
  }
}
