import { qrCodeTypes } from "@/services/constants";

export default {
  state: {
    sections: ["Levels", "Type"],
    selectedSection: "Type",
    selectedTypeId: null,
    selectedLevelId: null,
    searchActiveNum: 0,
    typesOptions: qrCodeTypes
  },
  mutations: {
    setSelectedSection(state, payload) {
      state.selectedSection = payload;
    },
    setSelectedTypeId(state, payload) {
      state.selectedTypeId = payload;
    },
    setSelectedLevelId(state, payload) {
      state.selectedLevelId = payload;
    },
    activateSearch(state, payload) {
      if (payload) {
        state.searchActiveNum === 1
          ? (state.searchActiveNum = 2)
          : (state.searchActiveNum = 1);
      } else {
        state.searchActiveNum = 0;
      }
    },
    clearAllFilters(state) {
      state.selectedTypeId = null;
      state.selectedLevelId = null;
    }
  },
  actions: {
    setSelectedSection({ commit }, payload) {
      commit("setSelectedSection", payload);
    },
    setSelectedTypeId({ commit }, payload) {
      commit("setSelectedTypeId", payload);
    },
    setSelectedLevelId({ commit }, payload) {
      commit("setSelectedLevelId", payload);
    },
    activateSearch({ commit }, payload) {
      commit("activateSearch", payload);
    },
    clearAllFilters({ commit }, payload) {
      commit("clearAllFilters", payload);
    }
  }
};
