import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    availabilities: null,
    unavailabilities: null,
    availability: null,
    unavailability: null
  },
  mutations: {
    setAvailabilitiesData(state, payload) {
      state.availabilities = payload;
    },
    setUnavailabilitiesData(state, payload) {
      state.unavailabilities = payload;
    },
    setAvailabilityData(state, payload) {
      state.availability = payload;
    },
    setUnavailabilityData(state, payload) {
      state.unavailability = payload;
    }
  },
  actions: {
    getAvailabilities({ commit }, resourceId) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(
          `${apiEndpoints.company.resources}/${resourceId}/resourceavailabilities/all`
        )
        .then(response => {
          commit("setAvailabilitiesData", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getUnavailabilities({ commit }, resourceId) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(
          `${apiEndpoints.company.resources}/${resourceId}/resourceunavailabilities/all`
        )
        .then(response => {
          commit("setUnavailabilitiesData", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getAvailability({ commit }, { resource_id, availability_id }) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(
          `${apiEndpoints.company.resources}/${resource_id}/resourceavailabilities/${availability_id}`
        )
        .then(response => {
          commit("setAvailabilityData", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getUnavailability({ commit }, { resource_id, unavailability_id }) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(
          `${apiEndpoints.company.resources}/${resource_id}/resourceunavailabilities/${unavailability_id}`
        )
        .then(response => {
          commit("setUnavailabilityData", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    }
  }
};
