export const APPLICATIONS = {
  APP_CLEBEX_HUB: {
    id: 4,
    name: "Clebex Hub"
  },
  APP_MAPS: {
    id: 5,
    name: "Clebex 5D Maps"
  },
  APP_OCCUPANCY: {
    id: 6,
    name: "Clebex Occupancy"
  },
  APP_REAL_TIME: {
    id: 7,
    name: "Clebex Real-Time"
  },
  APP_PLAN: {
    id: 8,
    name: "CLebex Plan"
  },
  APP_COUNT: {
    id: 10,
    name: "Clebex Count"
  },
  APP_VISITOR: {
    id: 11,
    name: "Clebex Visitor"
  },
  APP_ONE_CLICK: {
    id: 12,
    name: "Clebex One-click"
  },
  APP_MY_VISITS: {
    id: 13,
    name: "Clebex My visits"
  },
  APP_BOOKINGS: {
    id: 14,
    name: "Clebex Bookings"
  }
};
