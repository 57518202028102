export default {
  path: "/preferences",
  name: "r_preferences",
  meta: {
    middlewares: {
      auth: null
    }
  },
  component: () => import("../../components/preferences/Preferences"),
  children: [
    {
      path: ":categoryId",
      name: "r_preference-category",
      meta: {
        middlewares: {
          auth: null
        }
      },
      component: () =>
        import("../../components/preferences/PreferenceCategory"),
      children: [
        {
          path: ":preferenceId",
          name: "r_set-preference",
          meta: {
            middlewares: {
              auth: null
            }
          },
          component: () => import("../../components/preferences/SetPreference"),
          children: [
            {
              path: "help",
              name: "r_preferences-set-preference-help-online",
              meta: {
                middlewares: {
                  auth: null
                }
              },
              props: {
                backLinkName: "r_set-preference"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "help",
          name: "r_preferences-category-help-online",
          meta: {
            middlewares: {
              auth: null
            }
          },
          props: {
            backLinkName: "r_preference-category"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "help",
      name: "r_preferences-help-online",
      meta: {
        middlewares: {
          auth: null
        }
      },
      props: {
        backLinkName: "r_preferences",
        slug: "preferences"
      },
      component: () => import("../../components/help-online/HelpOnline")
    }
  ]
};
