// TODO vidjeti koja se permisija mapira ovde da li LEVELS ili MANAGE_LEVELS
export default {
  path: "/levels/:level_id?",
  name: "r_levels",
  meta: {
    middlewares: {
      auth: null,
      permission: "LEVELS"
    }
  },
  component: () => import("../../components/levels/Levels"),
  children: [
    {
      path: "add-level",
      name: "r_add-level",
      meta: {
        middlewares: {
          auth: null,
          permission: ["LEVELS, MANAGE_LEVELS", "read"]
        }
      },
      component: () => import("../../components/levels/AddLevel"),
      children: [
        {
          path: "select-timezone",
          name: "r_add-level-select-timezone",
          meta: {
            middlewares: {
              auth: null,
              permission: ["LEVELS, MANAGE_LEVELS", "read"]
            }
          },
          props: {
            backLinkName: "r_add-level",
            helpOnlineLinkName: "r_levels-add-timezone-help-online"
          },
          component: () => import("../../components/global/TimezoneSelection"),
          children: [
            {
              path: "help",
              name: "r_levels-add-timezone-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "LEVELS"
                }
              },
              props: {
                backLinkName: "r_add-level-select-timezone",
                slug: "levels-time-zone"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "select-country",
          name: "r_add-level-select-country",
          meta: {
            middlewares: {
              auth: null,
              permission: ["LEVELS, MANAGE_LEVELS", "read"]
            }
          },
          props: {
            backLinkName: "r_add-level",
            helpOnlineLinkName: "r_levels-add-countries-help-online"
          },
          component: () => import("../../components/global/CountriesSelection"),
          children: [
            {
              path: "help",
              name: "r_levels-add-countries-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "LEVELS"
                }
              },
              props: {
                backLinkName: "r_add-level-select-country",
                slug: "levels-countries"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "select-map",
          name: "r_add-level-select-map",
          meta: {
            middlewares: {
              auth: null,
              permission: ["LEVELS, MANAGE_LEVELS", "read"]
            }
          },
          props: {
            backLinkName: "r_add-level",
            helpOnlineLinkName: "r_levels-add-maps-help-online"
          },
          component: () => import("../../components/global/MapsSelection"),
          children: [
            {
              path: "help",
              name: "r_levels-add-maps-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "LEVELS"
                }
              },
              props: {
                backLinkName: "r_add-level-select-map",
                slug: "levels-5d-maps"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "name-translations",
          name: "r_add-level-translate-name",
          meta: {
            middlewares: {
              auth: null,
              permission: ["LEVELS, MANAGE_LEVELS", "read"]
            }
          },
          props: {
            backLinkName: "r_add-level",
            componentTitle: "add-level",
            helpLinkName: "r_levels-add-translation-help-online"
          },
          component: () => import("../../components/levels/TranslateLevel"),
          children: [
            {
              path: "help",
              name: "r_levels-add-translation-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "LEVELS"
                }
              },
              props: {
                backLinkName: "r_add-level-translate-name",
                slug: "levels-translation"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "select-working-hours",
          name: "r_add-level-select-working-hours",
          meta: {
            middlewares: {
              auth: null,
              permission: "LEVELS"
            }
          },
          props: {
            backLinkName: "r_add-level",
            helpLinkName: "r_levels-add-hours-help-online"
          },
          component: () => import("../../components/levels/SelectWorkingHours"),
          children: [
            {
              path: "help",
              name: "r_levels-add-hours-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "LEVELS"
                }
              },
              props: {
                backLinkName: "r_add-level-select-working-hours",
                slug: "levels-hours"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "help",
          name: "r_levels-add-help-online",
          meta: {
            middlewares: {
              auth: null,
              permission: "LEVELS"
            }
          },
          props: {
            backLinkName: "r_add-level",
            slug: "levels-add"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "edit-level",
      name: "r_edit-level",
      meta: {
        middlewares: {
          auth: null,
          permission: "LEVELS"
        }
      },
      component: () => import("../../components/levels/EditLevel"),
      children: [
        {
          path: "select-timezone",
          name: "r_edit-level-select-timezone",
          meta: {
            middlewares: {
              auth: null,
              permission: "LEVELS"
            }
          },
          props: {
            backLinkName: "r_edit-level",
            helpOnlineLinkName: "r_levels-edit-timezone-help-online"
          },
          component: () => import("../../components/global/TimezoneSelection"),
          children: [
            {
              path: "help",
              name: "r_levels-edit-timezone-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "LEVELS"
                }
              },
              props: {
                backLinkName: "r_edit-level-select-timezone",
                slug: "levels-time-zone"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "select-country",
          name: "r_edit-level-select-country",
          meta: {
            middlewares: {
              auth: null,
              permission: "LEVELS"
            }
          },
          props: {
            backLinkName: "r_edit-level",
            helpOnlineLinkName: "r_levels-edit-countries-help-online"
          },
          component: () => import("../../components/global/CountriesSelection"),
          children: [
            {
              path: "help",
              name: "r_levels-edit-countries-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "LEVELS"
                }
              },
              props: {
                backLinkName: "r_edit-level-select-country",
                slug: "levels-countries"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "select-map",
          name: "r_edit-level-select-map",
          meta: {
            middlewares: {
              auth: null,
              permission: "LEVELS"
            }
          },
          props: {
            backLinkName: "r_edit-level",
            helpOnlineLinkName: "r_levels-edit-maps-help-online"
          },
          component: () => import("../../components/global/MapsSelection"),
          children: [
            {
              path: "help",
              name: "r_levels-edit-maps-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "LEVELS"
                }
              },
              props: {
                backLinkName: "r_edit-level-select-map",
                slug: "levels-5d-maps"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "name-translations",
          name: "r_edit-level-translate-name",
          meta: {
            middlewares: {
              auth: null,
              permission: "LEVELS"
            }
          },
          props: {
            backLinkName: "r_edit-level",
            componentTitle: "edit-level",
            helpLinkName: "r_levels-edit-translation-help-online"
          },
          component: () => import("../../components/levels/TranslateLevel"),
          children: [
            {
              path: "help",
              name: "r_levels-edit-translation-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "LEVELS"
                }
              },
              props: {
                backLinkName: "r_edit-level-translate-name",
                slug: "levels-translation"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "qr-code",
          name: "r_edit-level-qr-code",
          meta: {
            middlewares: {
              auth: null,
              permission: "LEVELS"
            }
          },
          props: {
            backLinkName: "r_edit-level"
          },
          component: () => import("../../components/levels/EditLevelQrCode"),
          children: [
            {
              path: "help",
              name: "r_levels-edit-qr-code-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "LEVELS"
                }
              },
              props: {
                backLinkName: "r_edit-level-qr-code",
                slug: "levels-qr-code"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "select-working-hours",
          name: "r_edit-level-select-working-hours",
          meta: {
            middlewares: {
              auth: null,
              permission: "LEVELS"
            }
          },
          props: {
            backLinkName: "r_edit-level",
            helpLinkName: "r_levels-edit-hours-help-online"
          },
          component: () => import("../../components/levels/SelectWorkingHours"),
          children: [
            {
              path: "help",
              name: "r_levels-edit-hours-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "LEVELS"
                }
              },
              props: {
                backLinkName: "r_edit-level-select-working-hours",
                slug: "levels-hours"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "help",
          name: "r_levels-edit-help-online",
          meta: {
            middlewares: {
              auth: null,
              permission: "LEVELS"
            }
          },
          props: {
            backLinkName: "r_edit-level",
            slug: "levels-edit"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "help",
      name: "r_levels-help-online",
      meta: {
        middlewares: {
          auth: null,
          permission: "LEVELS"
        }
      },
      props: {
        backLinkName: "r_levels",
        slug: "levels"
      },
      component: () => import("../../components/help-online/HelpOnline")
    }
  ]
};
