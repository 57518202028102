import { getLang } from "@/services/http-service";
var relativeTime = require("dayjs/plugin/relativeTime");
var duration = require("dayjs/plugin/duration");
var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
var isBetween = require("dayjs/plugin/isBetween");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
var customParseFormat = require("dayjs/plugin/customParseFormat");
import { Settings } from "luxon";

import dayjs from "dayjs";

const locale = getLang();
Settings.locale = locale; // default luxon locale
const requireModule = require.context(`dayjs/locale/`, false, /\.js$/);

dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

// TODO add logic for custom localizations here and logic for handling
// TODO as well as multiple locales sharing the same translations
let module = null;
requireModule.keys().forEach(fileName => {
  if (fileName === `./${locale}.js`) {
    module = requireModule(fileName).default;
    dayjs.locale(locale);
  }
});
export default module;
