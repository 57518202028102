import dayjs from "dayjs";

const today = dayjs(); // decembar
// const today = dayjs().add(1, "month"); // decembar for testing

export default {
  namespaced: true,
  state: {
    expanded: false,
    selectedDate: today,
    today: today,
    month: today.month(), // mjesec u formatu broja
    year: today.year(),
    numberOfDaysInAMonth: today.daysInMonth(), // broj
    numOfDaysInAWeek: 7
  },
  getters: {
    parsedDateMonthYear(state) {
      return state.today.format("MMMM YYYY");
    },
    /**
     * Returns number of day in the week
     * 0: sunday
     * 1: monday
     * 2: tuesday
     * 3: wednesday
     * 4: thursday
     * 5: friday
     * 6: saturday
     * @returns {number}
     */
    dayOfTheWeek(state) {
      return state.today.day();
    },
    /**
     * Returns number of day in a month 1 - 31
     * @returns {number}
     */
    dayOfTheMonth(state) {
      return state.today.date();
    },
    /**
     * Divides a current month into 7 and flooring it
     * to show how many full 7 days cycles are in the month.
     * NOTE: This is not a true week of the year, just num of
     * days in a month divided by 7.
     * @returns {number}
     */
    numberOfFullWeeks(state) {
      return Math.floor(state.numberOfDaysInAMonth / 7);
    },
    /**
     * Returns a number which shows in which 7 day cycle (week)
     * is current day.
     * NOTE: This is not a true week of the year, just num of
     * days in a month divided by 7.
     * @returns {number}
     */
    currentDayIsInWeek(state, getters) {
      return Math.ceil(getters.dayOfTheMonth / 7);
    },
    /**
     * Returns a position of the day in the true week.
     * So wee could calculate how to draw calendar and where
     * to put days that come before and after.
     * @returns {number}
     */
    positionInWeek(state, getters) {
      return getters.dayOfTheMonth % 7;
    },
    /**
     * Function that creates day labels S, M, T etc.
     * Not every combination of shown days will have
     * full week days in single week view.
     * @returns {[]}
     */
    labelsOfDaysSingleWeek(state, getters) {
      /**
       * Uzeti u obzir kraj mjeseca za daysAfter i daysBefore
       * ako poslednja sedmica u mjesecu ima npr 2 dana onda ofsetovati pocetak za 5
       */
      let days = [];
      let position = getters.positionInWeek;

      let dayOfTheMonth = getters.dayOfTheMonth;
      if (dayOfTheMonth + 7 > state.numberOfDaysInAMonth) {
        position += dayOfTheMonth + 7 - state.numberOfDaysInAMonth;
      }

      const firstIndex = position - 1;
      days[firstIndex] = state.today.format("dd");
      const daysAfter = 7 - position;
      const daysBefore = 7 - daysAfter - 1; // 7 days - remaining days - current day
      if (daysBefore) {
        for (let i = 1; i <= daysBefore; i++) {
          days[firstIndex - i] = state.today.subtract(i, "day").format("dd");
        }
      }

      if (daysAfter) {
        for (let i = 1; i <= daysAfter; i++) {
          days[firstIndex + i] = state.today.add(i, "day").format("dd");
        }
      }

      return days;
    },
    /**
     * Returns array of days to be shown in a single week view.
     * @returns {[]}
     */
    numbersOfDaysToDisplaySingleWeek(state, getters) {
      let days = [];
      let weekNumber = getters.currentDayIsInWeek;

      // Single week start is always at the beginning
      let numbersStart = weekNumber * 7 - 6;
      let numbersEnd = weekNumber * 7;

      if (numbersEnd > state.numberOfDaysInAMonth) {
        numbersEnd = state.numberOfDaysInAMonth;
      }

      if (numbersEnd - numbersStart < 6) {
        numbersStart -= 6 - (numbersEnd - numbersStart);
      }

      // const endDay = state.today.date(numbersEnd).day();

      for (let i = numbersStart; i <= numbersEnd; i++) {
        let d = new Date(`${state.year}, ${state.month + 1}, ${i}`);
        days.push({
          dayNum: i,
          dateObj: getters.dayOfTheMonth === i ? state.today : dayjs(d),
          dateString: `${state.year}, ${state.month + 1}, ${i}`
        });
      }

      return days;
    },
    labelsOfDaysWholeMonth(state) {
      return [
        state.today.day(0).format("dd"),
        state.today.day(1).format("dd"),
        state.today.day(2).format("dd"),
        state.today.day(3).format("dd"),
        state.today.day(4).format("dd"),
        state.today.day(5).format("dd"),
        state.today.day(6).format("dd"),
        state.today.day(7).format("dd")
      ];
    },
    numbersOfDaysToDisplayWholeMonth(state, getters) {
      let days = [];
      let weekDayOfTheFirstDayInTheMonth = today.date(1).day();
      // dodati i uslov ako je januar da kupi od prethodne godine :(
      // i ako je decembar da kupi od sljedece godine
      // Add numbers from last month in front of state.month's days if not sunday
      if (weekDayOfTheFirstDayInTheMonth !== 0) {
        let previousMonth;
        let previousMonthNumberOfDays;
        let year = state.year;

        // if january take last month of previous year
        if (state.month === 0) {
          previousMonth = 11;
          previousMonthNumberOfDays = 31; // december has 31 days no need to calculate
          year = today.year(state.year - 1);
        } else {
          previousMonth = today.month(state.month - 1);
          previousMonthNumberOfDays = previousMonth.daysInMonth();
        }

        const dayNum =
          previousMonthNumberOfDays - weekDayOfTheFirstDayInTheMonth + 1;

        for (let i = dayNum; i <= previousMonthNumberOfDays; i++) {
          let d = new Date(`${year}, ${previousMonth.month() + 1}, ${i}`);
          days.push({
            dayNum: i,
            dateObj: dayjs(d),
            dateString: `${year}, ${previousMonth.month() + 1}, ${i}`,
            outOfRange: true
          });
        }
      }

      // Add days of current month to array
      for (let i = 1; i <= state.numberOfDaysInAMonth; i++) {
        let d = new Date(`${state.year}, ${state.month + 1}, ${i}`);
        days.push({
          dayNum: i,
          dateObj: getters.dayOfTheMonth === i ? state.today : dayjs(d),
          dateString: `${state.year}, ${state.month + 1}, ${i}`
        });
      }

      // if last day of the month is not saturday add days from next month
      if (state.today.date(state.numberOfDaysInAMonth).day() !== 6) {
        // return week day of the last day of the month
        // so we could properly add missing days of the next month
        let weekDayOfTheLastDayInTheMonth = today
          .date(state.numberOfDaysInAMonth)
          .day();

        if (weekDayOfTheLastDayInTheMonth !== 6) {
          // if not saturday add numbers
          let year = state.year;
          let nextMonth;

          if (state.month === 11) {
            // if december take next year's january
            year = today.year(state.year + 1).year(); // next year
            nextMonth = 0; // january
          } else {
            nextMonth = today.month(state.month + 1).month();
          }

          for (let i = 1; i < 7 - weekDayOfTheLastDayInTheMonth; i++) {
            let d = new Date(`${year}, ${nextMonth + 1}, ${i}`);
            days.push({
              dayNum: i,
              dateObj: dayjs(d),
              dateString: `${year}, ${nextMonth + 1}, ${i}`,
              outOfRange: true
            });
          }
        }
      }

      return days;
    }
  },
  mutations: {
    setSelectedDate(state, payload) {
      state.selectedDate = payload;
    },
    setExpanded(state, payload) {
      state.expanded = payload;
    }
  },
  actions: {
    selectDate({ commit }, payload) {
      commit("setSelectedDate", payload);
    },
    setExpanded({ commit }, payload) {
      commit("setExpanded", payload);
    }
  }
};
