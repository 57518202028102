export default {
  path: "/permissions-resource",
  name: "r_permissions-resource",
  meta: {
    middlewares: {
      auth: null,
      permission: false
    }
  },
  props: {
    containerType: "RESOURCE",
    backLinkName: "r_permissions-resource"
  },
  component: () => import("../../components/permissions/Permissions"),
  children: [
    {
      path: "edit-permission",
      name: "r_edit-resource-permission",
      meta: {
        middlewares: {
          auth: null,
          permission: false
        }
      },
      props: {
        backLinkName: "r_permissions-resource",
        containerType: "RESOURCE"
      },
      component: () => import("../../components/permissions/EditPermission")
    }
  ]
};
