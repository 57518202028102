import store from "@/store";
import { getSubdomain } from "@/services/http-service";

export default async function menuPreferences({ to, from, data }) {
  if (
    !getSubdomain() ||
    (to.matched[0] === from.matched[0] &&
      from.meta.middlewares &&
      from.meta.middlewares.menuPreferences)
  ) {
    return;
  }
  await getInitialData().then(async () => {
    await setShowMenu(data);
  });
  return;
}

async function getInitialData() {
  if (!store.state.preferences.menuPreferences) {
    await store.dispatch("preferences/getMenuPreferences", false, {
      root: true,
    });
  }
  return;
}

async function setShowMenu(data) {
  let menuValue = store.state.preferences.menuPreferences.find(
    (el) => el.preference === data
  ).is_automatic
    ? false
    : true;

  await store.dispatch("global/setShowMenu", menuValue, {
    root: true,
  });
}
