export default {
  path: "/containers-mega",
  name: "r_containers-mega",
  meta: {
    middlewares: {
      auth: null,
      permission: "MEGA_CONTAINERS"
    }
  },
  component: () => import("../../components/containers/MegaContainers"),
  children: [
    {
      path: "add-mega-container",
      name: "r_add-mega-container",
      meta: {
        middlewares: {
          auth: null,
          permission: "MEGA_CONTAINERS"
        }
      },
      props: { backLinkName: "r_containers-mega" },
      component: () => import("../../components/containers/AddMegaContainer")
    },
    {
      path: "edit-mega-container/:mega_container_id",
      name: "r_edit-mega-container",
      meta: {
        middlewares: {
          auth: null,
          permission: "MEGA_CONTAINERS"
        }
      },
      props: { backLinkName: "r_containers-mega" },
      component: () => import("../../components/containers/EditMegaContainer"),
      children: [
        {
          path: "select-container-type",
          name: "r_edit-mega-container-select-container-type",
          meta: {
            middlewares: {
              auth: null,
              permission: "MEGA_CONTAINERS"
            }
          },
          props: { backLinkName: "r_edit-mega-container" },
          component: () =>
            import("../../components/containers/SelectContainerAccessType"),
          children: [
            {
              path: "select-container/:access_type_id",
              name: "r_edit-mega-container-select-container",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "MEGA_CONTAINERS"
                }
              },
              props: {
                backLinkName: "r_edit-mega-container-select-container-type"
              },
              component: () =>
                import(
                  "../../components/containers/MegaContainerSelectContainer"
                )
            }
          ]
        }
      ]
    }
  ]
};
