import { apiEndpoints } from "@/services/constants";
import httpServiceAuth from "@/services/http-service";
import { DateTime } from "luxon";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    grid: null,
    date: DateTime.now().toFormat("yyyy-MM-dd"),
    slotDuration: null,
    loader: false,
    start: null,
    end: null,
    resource: null,
    selectedSlots: null,
  },
  mutations: {
    setGrid(state, payload) {
      state.grid = payload;
    },
    setDate(state, payload) {
      state.date = payload;
    },
    setSlotDuration(state, payload) {
      state.slotDuration = payload;
    },
    setLoader(state, payload) {
      state.loader = payload;
    },
    setStart(state, payload) {
      state.start = payload;
    },
    setEnd(state, payload) {
      state.setEnd = payload;
    },
    setResource(state, payload) {
      state.resource = payload;
    },
    setSelectedSlots(state, payload) {
      state.selectedSlots = payload;
    },
  },
  actions: {
    setGrid({ commit }, payload) {
      commit("setGrid", payload);
    },
    setSelectedSlots({ commit }, payload) {
      commit("setSelectedSlots", payload);
    },
    getGrid({ commit }, { presetId, date, duration, loader }) {
      let url = `${apiEndpoints.company.bookingGrid}/${presetId}?date=${date}`;
      if (duration != null) {
        url += `&duration=${duration}`;
      }
      if (loader == true) {
        commit("loader/setScreenLoading", true, { root: true });
      }
      return httpServiceAuth
        .get(url)
        .then((response) => {
          commit("setGrid", response.data);
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    resize(
      { commit },
      {
        id: id,
        resource_id: resource_id,
        slots: slots,
        slot_duration: slot_duration,
      }
    ) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.bookingResize}`;
      return httpServiceAuth
        .put(url, {
          id: id,
          resource_id: resource_id,
          slots: slots,
          is_grid: true,
          slot_duration: slot_duration,
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setDate({ commit }, payload) {
      commit("setDate", payload);
    },
    setSlotDuration({ commit }, payload) {
      commit("setSlotDuration", payload);
    },
    setLoader({ commit }, payload) {
      commit("setLoader", payload);
    },
    setStart({ commit }, payload) {
      commit("setStart", payload);
    },
    setEnd({ commit }, payload) {
      commit("setEnd", payload);
    },
    setResource({ commit }, payload) {
      commit("setResource", payload);
    },
    setDeclaration({ commit }, postObject) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.bookings}/store`;
      return httpServiceAuth
        .post(url, postObject)
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
  },
};
