export default {
  path: "/cx-countries",
  name: "r_countries",
  meta: {
    middlewares: {
      auth: null
    }
  },
  component: () => import("../../components/countries/Countries"),
  children: [
    {
      path: "add-country",
      name: "r_add-country",
      meta: {
        middlewares: {
          auth: null
        }
      },
      component: () => import("../../components/countries/AddCountry")
    },
    {
      path: "edit-country",
      name: "r_edit-country",
      meta: {
        middlewares: {
          auth: null
        }
      },
      component: () => import("../../components/countries/EditCountry")
    }
  ]
};
