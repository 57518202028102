export default {
  path: "/roles-reception-desk",
  name: "r_roles-reception-desk",
  meta: {
    middlewares: {
      auth: null,
      permission: "RECEPTION_DESK_ROLES"
    }
  },
  props: {
    containerType: "RECEPTION_DESK",
    backLinkName: "r_roles-reception-desk"
  },
  component: () => import("../../components/roles/Roles"),
  children: [
    {
      path: "add-role",
      name: "r_add-reception-desk-role",
      meta: {
        middlewares: {
          auth: null,
          permission: "RECEPTION_DESK_ROLES"
        }
      },
      props: {
        backLinkName: "r_roles-reception-desk",
        containerType: "RECEPTION_DESK"
      },
      component: () => import("../../components/roles/AddRole")
    },
    {
      path: "edit-role",
      name: "r_edit-reception-desk-role",
      meta: {
        middlewares: {
          auth: null,
          permission: "RECEPTION_DESK_ROLES"
        }
      },
      props: {
        backLinkName: "r_roles-reception-desk",
        containerType: "RECEPTION_DESK"
      },
      component: () => import("../../components/roles/EditRole"),
      children: [
        {
          path: "add-permissions-to-roles",
          name: "r_edit-reception-desk-role-add-permissions-to-role",
          meta: {
            middlewares: {
              auth: null,
              permission: "RECEPTION_DESK_ROLES"
            }
          },
          props: {
            backLinkName: "r_edit-reception-desk-role",
            containerType: "RECEPTION_DESK"
          },
          component: () => import("../../components/roles/RolesAddPermissions")
        }
      ]
    }
  ]
};
