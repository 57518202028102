export default {
  path: "/resource-types",
  name: "r_resource-types",
  meta: {
    middlewares: {
      auth: null,
      permission: "RESOURCE_TYPES"
    }
  },
  component: () => import("../../components/resource-types/ResourceTypes"),
  children: [
    {
      path: "add",
      name: "r_add-resource-type",
      meta: {
        middlewares: {
          auth: null,
          permission: "RESOURCE_TYPES"
        }
      },
      props: { backLinkName: "r_resource-types" },
      component: () =>
        import("../../components/resource-types/AddResourceType"),
      children: [
        {
          path: "name-translations",
          name: "r_add-resource-type-translate-name",
          meta: {
            middlewares: {
              auth: null,
              permission: ["RESOURCE_TYPES", "create"]
            }
          },
          props: {
            backLinkName: "r_add-resource-type",
            componentTitle: "add-resource-type"
          },
          component: () =>
            import("../../components/resource-types/TranslateResourceType"),
          children: [
            {
              path: "help",
              name: "r_resource-types-translation-add-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCE_TYPES"
                }
              },
              props: {
                backLinkName: "r_add-resource-type-translate-name",
                slug: "resource-types-translation"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "help",
          name: "r_resource-types-add-help-online",
          meta: {
            middlewares: {
              auth: null,
              permission: "RESOURCE_TYPES"
            }
          },
          props: {
            backLinkName: "r_add-resource-type",
            slug: "resource-types-add"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: ":resource_type_id",
      name: "r_edit-resource-type",
      meta: {
        middlewares: {
          auth: null,
          permission: "RESOURCE_TYPES"
        }
      },
      props: { backLinkName: "r_resource-types" },
      component: () =>
        import("../../components/resource-types/EditResourceType"),
      children: [
        {
          path: "name-translations",
          name: "r_edit-resource-type-translate-name",
          meta: {
            middlewares: {
              auth: null,
              permission: "RESOURCE_TYPES"
            }
          },
          props: {
            backLinkName: "r_edit-resource-type",
            componentTitle: "edit-resource-type"
          },
          component: () =>
            import("../../components/resource-types/TranslateResourceType"),
          children: [
            {
              path: "help",
              name: "r_resource-types-translation-edit-help-online",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCE_TYPES"
                }
              },
              props: {
                backLinkName: "r_edit-resource-type-translate-name",
                slug: "resource-types-translation"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "help",
          name: "r_resource-types-edit-help-online",
          meta: {
            middlewares: {
              auth: null,
              permission: "RESOURCE_TYPES"
            }
          },
          props: {
            backLinkName: "r_edit-resource-type",
            slug: "resource-types-edit"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "help",
      name: "r_resource-types-help-online",
      meta: {
        middlewares: {
          auth: null,
          permission: "RESOURCE_TYPES"
        }
      },
      props: {
        backLinkName: "r_resource-types",
        slug: "resource-types"
      },
      component: () => import("../../components/help-online/HelpOnline")
    }
  ]
};
