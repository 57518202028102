import { apiEndpoints } from "@/services/constants";
import httpServiceAuth from "@/services/http-service";
import { errorHandler } from "@/services/error-handler";
import router from "@/router";

export default {
  state: {
    declaration: null,
    declarationApproveStatuses: null
  },
  mutations: {
    setDeclarationData(state, payload) {
      state.declaration = payload;
    },
    setDeclarationApproveStatuses(state, payload) {
      state.declarationApproveStatuses = payload;
    }
  },
  actions: {
    async getDeclaration({ commit }, data) {
      const { id, params, hideLoader } = data;
      if (!hideLoader) commit("loader/setScreenLoading", true, { root: true });
      let url = `${apiEndpoints.company.declarations}/${id}`;
      return httpServiceAuth
        .get(url, { params })
        .then(response => {
          commit("setDeclarationData", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getDeclarationApproveStatuses({ commit }) {
      let url = `${apiEndpoints.company.declarationApproveStatuses}`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setDeclarationApproveStatuses", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    declarationStatusAssign(
      { commit },
      { declarationId, declarationApproveStatusId }
    ) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(
          `${apiEndpoints.company.declarationApproveStatuses}/assign/${declarationId}/${declarationApproveStatusId}`
        )
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    declarationCheckIn({ commit }, { id, refresh }) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .post(`${apiEndpoints.company.declarationActions}${id}/check-in`)
        .then(() => {
          if (refresh) {
            window.location.reload();
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    declarationCheckOut({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .post(`${apiEndpoints.company.declarationActions}${id}/check-out`)
        .then(() => {
          router.push({ name: "r_plan" });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    declarationExtendTime({ commit }, { id, type }) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .post(
          `${apiEndpoints.company.declarationActions}${id}/extend?extending_type=${type}`
        )
        .then(() => {
          window.location.reload();
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    }
  }
};
