import { APPLICATIONS } from "@/services/consts/application";

const OCCUPANCY_MIDDLEWARES = {
  auth: null,
  permission: "OCCUPANCY",
  document: { applicationId: APPLICATIONS.APP_OCCUPANCY.id },
  menuPreferences: "OCCUPANCY"
};

export default {
  path: "/occupancy",
  name: "r_occupancy",
  meta: {
    middlewares: OCCUPANCY_MIDDLEWARES
  },
  component: () => import("../../components/global/HiddenScreen"),
  children: [
    {
      path: "dashboard",
      name: "r_occupancy-dashboard",
      meta: {
        middlewares: OCCUPANCY_MIDDLEWARES
      },
      component: () => import("../../components/occupancy/Dashboard"),
      children: [
        {
          path: "search-filters",
          name: "r_occupancy-dashboard-search-filters",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-dashboard",
            savePresetLinkName: "r_occupancy-dashboard-search-filters-preset"
          },
          component: () => import("../../components/search/SearchFilters"),
          children: [
            {
              path: "save-preset",
              name: "r_occupancy-dashboard-search-filters-preset",
              meta: {
                middlewares: OCCUPANCY_MIDDLEWARES
              },
              props: {
                backLinkName: "r_occupancy-dashboard-search-filters"
              },
              component: () =>
                import("../../components/search/SearchFiltersPresets")
            }
          ]
        },
        {
          path: "export",
          name: "r_occupancy-export-dashboard",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-dashboard",
            helpLinkName: "r_occupancy-help-online-export-dashboard"
          },
          component: () => import("../../components/occupancy/Export"),
          children: [
            {
              path: "help",
              name: "r_occupancy-help-online-export-dashboard",
              meta: {
                middlewares: OCCUPANCY_MIDDLEWARES
              },
              props: {
                backLinkName: "r_occupancy-export-dashboard",
                slug: "occupancy-data-export"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "map",
          name: "r_occupancy-map-dashboard",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-dashboard"
          },
          component: () => import("../../components/occupancy/Map")
        },
        {
          path: "help",
          name: "r_occupancy-help-online-dashboard",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-dashboard",
            slug: "occupancy-dashboard"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "min-average-max",
      name: "r_occupancy-min-average-max",
      meta: {
        middlewares: OCCUPANCY_MIDDLEWARES
      },
      component: () =>
        import("../../components/occupancy/reports/MinAverageMax"),
      children: [
        {
          path: "search-filters",
          name: "r_occupancy-min-average-max-search-filters",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-min-average-max",
            savePresetLinkName:
              "r_occupancy-min-average-max-search-filters-preset"
          },
          component: () => import("../../components/search/SearchFilters"),
          children: [
            {
              path: "save-preset",
              name: "r_occupancy-min-average-max-search-filters-preset",
              meta: {
                middlewares: OCCUPANCY_MIDDLEWARES
              },
              props: {
                backLinkName: "r_occupancy-min-average-max-search-filters"
              },
              component: () =>
                import("../../components/search/SearchFiltersPresets")
            }
          ]
        },
        {
          path: "export",
          name: "r_occupancy-export-min-average-max",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-min-average-max",
            helpLinkName: "r_occupancy-help-online-export-min-average-max"
          },
          component: () => import("../../components/occupancy/Export"),
          children: [
            {
              path: "help",
              name: "r_occupancy-help-online-export-min-average-max",
              meta: {
                middlewares: OCCUPANCY_MIDDLEWARES
              },
              props: {
                backLinkName: "r_occupancy-export-min-average-max",
                slug: "occupancy-data-export"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "map",
          name: "r_occupancy-map-min-average-max",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-min-average-max"
          },
          component: () => import("../../components/occupancy/Map")
        },
        {
          path: "help",
          name: "r_occupancy-help-online-min-average-max",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-min-average-max",
            slug: "occupancy-min-average-max"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "historical-occupancy",
      name: "r_occupancy-historical-occupancy",
      meta: {
        middlewares: OCCUPANCY_MIDDLEWARES
      },
      component: () =>
        import("../../components/occupancy/reports/HistoricalOccupancy"),
      children: [
        {
          path: "search-filters",
          name: "r_occupancy-historical-occupancy-search-filters",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-historical-occupancy",
            savePresetLinkName:
              "r_occupancy-historical-occupancy-search-filters-preset"
          },
          component: () => import("../../components/search/SearchFilters"),
          children: [
            {
              path: "save-preset",
              name: "r_occupancy-historical-occupancy-search-filters-preset",
              meta: {
                middlewares: OCCUPANCY_MIDDLEWARES
              },
              props: {
                backLinkName: "r_occupancy-historical-occupancy-search-filters"
              },
              component: () =>
                import("../../components/search/SearchFiltersPresets")
            }
          ]
        },
        {
          path: "export",
          name: "r_occupancy-export-historical-occupancy",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-historical-occupancy",
            helpLinkName: "r_occupancy-help-online-export-historical-occupancy"
          },
          component: () => import("../../components/occupancy/Export"),
          children: [
            {
              path: "help",
              name: "r_occupancy-help-online-export-historical-occupancy",
              meta: {
                middlewares: OCCUPANCY_MIDDLEWARES
              },
              props: {
                backLinkName: "r_occupancy-export-historical-occupancy",
                slug: "occupancy-data-export"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "map",
          name: "r_occupancy-map-historical-occupancy",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-historical-occupancy"
          },
          component: () => import("../../components/occupancy/Map")
        },
        {
          path: "help",
          name: "r_occupancy-help-online-historical-occupancy",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-historical-occupancy",
            slug: "occupancy-historical"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "historical-real-use",
      name: "r_occupancy-historical-real-use",
      meta: {
        middlewares: OCCUPANCY_MIDDLEWARES
      },
      component: () =>
        import("../../components/occupancy/reports/HistoricalRealUse"),
      children: [
        {
          path: "search-filters",
          name: "r_occupancy-historical-real-use-search-filters",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-historical-real-use",
            savePresetLinkName:
              "r_occupancy-historical-real-use-search-filters-preset"
          },
          component: () => import("../../components/search/SearchFilters"),
          children: [
            {
              path: "save-preset",
              name: "r_occupancy-historical-real-use-search-filters-preset",
              meta: {
                middlewares: OCCUPANCY_MIDDLEWARES
              },
              props: {
                backLinkName: "r_occupancy-historical-real-use-search-filters"
              },
              component: () =>
                import("../../components/search/SearchFiltersPresets")
            }
          ]
        },
        {
          path: "export",
          name: "r_occupancy-export-historical-real-use",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-historical-real-use",
            helpLinkName: "r_occupancy-help-online-export-historical-real-use"
          },
          component: () => import("../../components/occupancy/Export"),
          children: [
            {
              path: "help",
              name: "r_occupancy-help-online-export-historical-real-use",
              meta: {
                middlewares: OCCUPANCY_MIDDLEWARES
              },
              props: {
                backLinkName: "r_occupancy-export-historical-real-use",
                slug: "occupancy-data-export"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "map",
          name: "r_occupancy-map-historical-real-use",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-historical-real-use"
          },
          component: () => import("../../components/occupancy/Map")
        },
        {
          path: "help",
          name: "r_occupancy-help-online-historical-real-use",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-historical-real-use",
            slug: "occupancy-real-use"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "prediction-daily",
      name: "r_occupancy-prediction-daily",
      meta: {
        middlewares: OCCUPANCY_MIDDLEWARES
      },
      component: () =>
        import("../../components/occupancy/reports/PredictionDaily"),
      children: [
        {
          path: "search-filters",
          name: "r_occupancy-prediction-daily-search-filters",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-prediction-daily",
            savePresetLinkName:
              "r_occupancy-prediction-daily-search-filters-preset"
          },
          component: () => import("../../components/search/SearchFilters"),
          children: [
            {
              path: "save-preset",
              name: "r_occupancy-prediction-daily-search-filters-preset",
              meta: {
                middlewares: OCCUPANCY_MIDDLEWARES
              },
              props: {
                backLinkName: "r_occupancy-prediction-daily-search-filters"
              },
              component: () =>
                import("../../components/search/SearchFiltersPresets")
            }
          ]
        },
        {
          path: "export",
          name: "r_occupancy-export-prediction-daily",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-prediction-daily",
            helpLinkName: "r_occupancy-help-online-export-prediction-daily"
          },
          component: () => import("../../components/occupancy/Export"),
          children: [
            {
              path: "help",
              name: "r_occupancy-help-online-export-prediction-daily",
              meta: {
                middlewares: OCCUPANCY_MIDDLEWARES
              },
              props: {
                backLinkName: "r_occupancy-export-prediction-daily",
                slug: "occupancy-data-export"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "map",
          name: "r_occupancy-map-prediction-daily",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-prediction-daily"
          },
          component: () => import("../../components/occupancy/Map")
        },
        {
          path: "help",
          name: "r_occupancy-help-online-prediction-daily",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-prediction-daily",
            slug: "occupancy-prediction-daily"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "prediction-weekday",
      name: "r_occupancy-prediction-weekday",
      meta: {
        middlewares: OCCUPANCY_MIDDLEWARES
      },
      component: () =>
        import("../../components/occupancy/reports/PredictionWeekday"),
      children: [
        {
          path: "search-filters",
          name: "r_occupancy-prediction-weekday-search-filters",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-prediction-weekday",
            savePresetLinkName:
              "r_occupancy-prediction-weekday-search-filters-preset"
          },
          component: () => import("../../components/search/SearchFilters"),
          children: [
            {
              path: "save-preset",
              name: "r_occupancy-prediction-weekday-search-filters-preset",
              meta: {
                middlewares: OCCUPANCY_MIDDLEWARES
              },
              props: {
                backLinkName: "r_occupancy-prediction-weekday-search-filters"
              },
              component: () =>
                import("../../components/search/SearchFiltersPresets")
            }
          ]
        },
        {
          path: "export",
          name: "r_occupancy-export-prediction-weekday",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-prediction-weekday",
            helpLinkName: "r_occupancy-help-online-export-prediction-weekday"
          },
          component: () => import("../../components/occupancy/Export"),
          children: [
            {
              path: "help",
              name: "r_occupancy-help-online-export-prediction-weekday",
              meta: {
                middlewares: OCCUPANCY_MIDDLEWARES
              },
              props: {
                backLinkName: "r_occupancy-export-prediction-weekday",
                slug: "occupancy-data-export"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "map",
          name: "r_occupancy-map-prediction-weekday",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-prediction-weekday"
          },
          component: () => import("../../components/occupancy/Map")
        },
        {
          path: "help",
          name: "r_occupancy-help-online-prediction-weekday",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-prediction-weekday",
            slug: "occupancy-prediction-weekly"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "settings",
      name: "r_occupancy-settings",
      meta: {
        middlewares: {
          auth: null,
          permission: "OCCUPANCY",
          document: { applicationId: APPLICATIONS.APP_OCCUPANCY.id }
        }
      },
      component: () => import("../../components/occupancy/Settings"),
      children: [
        {
          path: "help",
          name: "r_occupancy-help-online-settings",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-settings",
            slug: "occupancy-settings"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "technical-list",
      name: "r_occupancy-technical-list",
      meta: {
        middlewares: OCCUPANCY_MIDDLEWARES
      },
      component: () =>
        import("../../components/occupancy/reports/TechnicalList"),
      children: [
        {
          path: "search-filters",
          name: "r_occupancy-technical-list-search-filters",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-technical-list",
            savePresetLinkName:
              "r_occupancy-technical-list-search-filters-preset"
          },
          component: () => import("../../components/search/SearchFilters"),
          children: [
            {
              path: "save-preset",
              name: "r_occupancy-technical-list-search-filters-preset",
              meta: {
                middlewares: OCCUPANCY_MIDDLEWARES
              },
              props: {
                backLinkName: "r_occupancy-technical-list-search-filters"
              },
              component: () =>
                import("../../components/search/SearchFiltersPresets")
            }
          ]
        },
        {
          path: "export",
          name: "r_occupancy-export-technical-list",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-technical-list",
            helpLinkName: "r_occupancy-help-online-export-technical-list"
          },
          component: () => import("../../components/occupancy/Export"),
          children: [
            {
              path: "help",
              name: "r_occupancy-help-online-export-technical-list",
              meta: {
                middlewares: OCCUPANCY_MIDDLEWARES
              },
              props: {
                backLinkName: "r_occupancy-export-technical-list",
                slug: "occupancy-data-export"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "map",
          name: "r_occupancy-map-technical-list",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-technical-list"
          },
          component: () => import("../../components/occupancy/Map")
        },
        {
          path: "help",
          name: "r_occupancy-help-online-technical-list",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-technical-list",
            slug: "occupancy-technical-list"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "finder",
      name: "r_occupancy-finder",
      meta: {
        middlewares: OCCUPANCY_MIDDLEWARES
      },
      component: () => import("../../components/occupancy/Finder"),
      children: [
        {
          path: "map",
          name: "r_occupancy-finder-map",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-finder"
          },
          component: () => import("../../components/occupancy/Map"),
          children: [
            {
              path: "filters",
              name: "r_occupancy-finder-map-filters",
              meta: {
                middlewares: OCCUPANCY_MIDDLEWARES
              },
              props: {
                backLinkName: "r_occupancy-finder-map"
              },
              component: () =>
                import("../../components/occupancy/FinderMapFilters")
            }
          ]
        },
        {
          path: "help",
          name: "r_occupancy-help-online-finder",
          meta: {
            middlewares: OCCUPANCY_MIDDLEWARES
          },
          props: {
            backLinkName: "r_occupancy-finder",
            slug: "occupancy-finder"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    }
  ]
};
