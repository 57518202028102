import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    deviceTemplates: null,
    selectedDeviceTemplate: null
  },
  mutations: {
    setDeviceTemplates(state, payload) {
      state.deviceTemplates = payload;
    },
    setSelectedDeviceTemplate(state, payload) {
      state.selectedDeviceTemplate = payload;
    }
  },
  actions: {
    getDeviceTemplates({ commit }, deviceType) {
      let deviceTypeUrl = "";
      if (deviceType) {
        deviceTypeUrl = "?device_type=" + deviceType;
      }
      let url = `${apiEndpoints.company.deviceTemplates}${deviceTypeUrl}`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setDeviceTemplates", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    setDeviceTemplates({ commit }, payload) {
      commit("setDeviceTemplates", payload);
    },
    setSelectedDeviceTemplate({ commit }, payload) {
      commit("setSelectedDeviceTemplate", payload);
    }
  }
};
