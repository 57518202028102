export default {
  state: {
    cameraActive: false,
    cameraScreenId: null,
    recordingStarted: false,
    photoImageUrl: null
  },
  getters: {
    photoImageUrl(state) {
      return state.photoImageUrl;
    }
  },
  mutations: {
    setCameraActive(state, payload) {
      state.cameraActive = payload;
    },
    setCameraRecordingStatus(state, payload) {
      state.recordingStarted = payload;
    },
    setCameraScreenId(state, payload) {
      state.cameraScreenId = payload;
    },
    setPhotoImageUrl(state, payload) {
      state.photoImageUrl = payload;
    }
  },
  actions: {
    setCameraActive({ commit }, payload) {
      commit("setCameraActive", payload);
    },
    setCameraRecordingStatus({ commit }, payload) {
      commit("setCameraRecordingStatus", payload);
    },
    setCameraScreenId({ commit }, payload) {
      commit("setCameraScreenId", payload);
    },
    setPhotoImageUrl({ commit }, payload) {
      commit("setPhotoImageUrl", payload);
    }
  }
};
