import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  namespaced: true,
  state: {
    receptionDesks: null,
    receptionDeskInfo: null,
    visitors: null,
    visitor: null,
    qrScannerActive: false,
    qrScannerScreenId: null,
    recordingStarted: false,
    scannedQRCode: null,
    selectedContainers: null,
    allowedContainers: null,
    showStatusDialog: false,
    scanResource: false,
    modalQueue: [],
    searchQuery: null,
    refreshVisitors: false,
  },
  mutations: {
    setRefreshVisitors(state, payload) {
      state.refreshVisitors = payload;
    },
    /* QR Code scanner */
    setQrScreenActive(state, payload) {
      state.qrScannerActive = payload;
    },
    setQrRecordingStatus(state, payload) {
      state.recordingScStarted = payload;
    },
    setQrScannerScreenId(state, payload) {
      state.qrScannerScreenId = payload;
    },
    setScannedQRCode(state, payload) {
      state.scannedQRCode = payload;
    },
    setReceptionDesks(state, payload) {
      state.receptionDesks = payload;
    },
    setReceptionDeskVisitors(state, payload) {
      if (!payload || !payload.key) {
        state.visitors = null;
        return;
      }
      if (!state.visitors) {
        state.visitors = { [payload.key]: payload.data };
      } else {
        state.visitors[payload.key] = payload.data;
      }
    },
    updateReceptionDeskVisitors(state, payload) {
      state.visitors = payload;
    },
    setReceptionDeskInfo(state, payload) {
      state.receptionDeskInfo = payload;
    },
    setVisitor(state, payload) {
      state.visitor = payload;
    },
    setSelectedContainer(state, payload) {
      if (state.selectedContainers && state.selectedContainers.length) {
        const index = state.selectedContainers.findIndex(
          (item) => item.id === payload.id
        );
        if (index !== -1) {
          // Reception desk default container if selected for each user by default and cannot be unselected
          if (
            state.selectedContainers.length === 1 &&
            state.receptionDeskInfo &&
            state.receptionDeskInfo.user_container_id
          ) {
            return;
          }
          state.selectedContainers.splice(index, 1);
        } else {
          state.selectedContainers.push(payload);
        }
      } else {
        state.selectedContainers = [payload];
      }
    },
    setSelectedContainers(state, payload) {
      state.selectedContainers = payload;
    },
    setAllowedContainers(state, payload) {
      state.allowedContainers = payload;
    },
    setShowStatusDialog(state, payload) {
      state.showStatusDialog = payload;
    },
    setScanResource(state, payload) {
      state.scanResource = payload;
    },
    pushToModalQueue(state, payload) {
      if (state.modalQueue && state.modalQueue.length) {
        const index = state.modalQueue.findIndex(
          (item) => item.visit.id === payload.visit.id
        );
        // remove if already in queue
        if (index !== -1) {
          state.modalQueue.splice(index, 1);
        } else {
          state.modalQueue.push(payload);
        }
      } else {
        state.modalQueue.push(payload);
      }
    },
    setModalQueue(state, payload) {
      state.modalQueue = payload;
    },
    setSearchQuery(state, payload) {
      state.searchQuery = payload;
    },
  },
  actions: {
    setQrScreenActive({ commit }, payload) {
      commit("setQrScreenActive", payload);
    },
    setQrRecordingStatus({ commit }, payload) {
      commit("setQrRecordingStatus", payload);
    },
    setQrScannerScreenId({ commit }, payload) {
      commit("setQrScannerScreenId", payload);
    },
    setScannedQRCode({ commit }, payload) {
      commit("setScannedQRCode", payload);
    },
    getReceptionDesks({ commit }) {
      const url = `${apiEndpoints.company.receptionDesks}`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(url)
        .then((response) => {
          commit("setReceptionDesks", response.data.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getReceptionDeskInfo({ commit }, deskId) {
      const url = `${apiEndpoints.company.receptionDesks}/${deskId}`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(url)
        .then((response) => {
          commit("setReceptionDeskInfo", response.data.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getReceptionDeskVisitors(
      { commit },
      { deskId, params, noLoaders = false }
    ) {
      const url = `${apiEndpoints.company.receptionDesks}/${deskId}/visitors`;
      if (!noLoaders) {
        commit("loader/setScreenLoading", true, { root: true });
      }
      return httpServiceAuth
        .post(url, params)
        .then((response) => {
          commit("setReceptionDeskVisitors", {
            data: response.data,
            key: params.group,
          });
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getReceptionDeskMyVisitors(
      { commit },
      { deskId, params, noLoaders = false }
    ) {
      const url = `${apiEndpoints.company.receptionDesks}/${deskId}/myvisitors`;
      if (!noLoaders) {
        commit("loader/setScreenLoading", true, { root: true });
      }
      return httpServiceAuth
        .post(url, params)
        .then((response) => {
          commit("setReceptionDeskVisitors", {
            data: response.data,
            key: params.group,
          });
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    clearReceptionDeskVisitors({ commit }) {
      commit("setReceptionDeskVisitors", null);
    },
    getVisitor({ commit }, { visitorId, receptionDeskId }) {
      const url = `${apiEndpoints.company.visitors}/${visitorId}/${receptionDeskId}`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(url)
        .then((response) => {
          const visitor = {
            ...response.data.data,
            disability: Boolean(response.data.data.disability),
          };
          commit("setVisitor", visitor);
          return visitor;
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    putVisitor({ commit }, { visitorId, params }) {
      const url = `${apiEndpoints.company.visitors}/${visitorId}`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .put(url, params)
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    putVisitorVisit({ commit }, { visitId, params }) {
      const url = `${apiEndpoints.company.visitors}/${visitId}`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .put(url, params)
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    postVisitor({ commit }, params) {
      const url = `${apiEndpoints.company.visitors}`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .post(url, params)
        .then((response) => {
          return response.data.data;
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    deleteVisitor({ commit }, id) {
      const url = `${apiEndpoints.company.visitors}/${id}`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .delete(url)
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    readQRCode(code) {
      return httpServiceAuth
        .get(`${apiEndpoints.company.qrCode}/read/${code}`)
        .then((response) => {
          if (response && response.data.data) {
            return response.data.data;
          }
        });
    },
    printBadge({ commit }, params) {
      const url = `${apiEndpoints.company.visitorBadge}/printbadge`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .post(url, params)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedContainer({ commit }, payload) {
      commit("setSelectedContainer", payload);
    },
    setSelectedContainers({ commit }, payload) {
      commit("setSelectedContainers", payload);
    },
    getAllowedContainers({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.allowedContainers}/${id}`)
        .then((response) => {
          commit("setAllowedContainers", response.data.data);
          return response.data.data;
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    checkIfExist({ commit, state }) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(
          `${apiEndpoints.company.visitors}/by-email/${state.receptionDeskInfo.id}`,
          {
            params: {
              email: state.visitor.email,
            },
          }
        )
        .then((response) => {
          if (!response.data.data) {
            // user not found, user should fill the form manually
            return;
          }
          const newVisitorData = { ...state.visitor, ...response.data.data };
          commit("visitorManagement/setVisitor", newVisitorData, {
            root: true,
          });
          return response.data.data;
        })
        .catch(() => {
          // api fail, user should fill the form manually
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setShowStatusDialog({ commit }, data) {
      commit("setShowStatusDialog", data);
    },
    setScanResource({ commit }, data) {
      commit("setScanResource", data);
    },
    pushToModalQueue({ commit }, payload) {
      Object.keys(payload).forEach((index) => {
        commit("pushToModalQueue", payload[index]);
      });
    },
    setModalQueue({ commit }, payload = []) {
      commit("setModalQueue", payload);
    },
    setSearchQuery({ commit }, payload) {
      commit("setSearchQuery", payload);
    },
    setRefreshVisitors({ commit }, payload) {
      commit("setRefreshVisitors", payload);
    },
    changeVisitStatus({ commit }, { visitId, params }) {
      const url = `${apiEndpoints.company.visits}/${visitId}/change-status`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .post(url, params)
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
  },
};
