import { mapActions, mapState } from "vuex";

export default {
  // data() {
  //   // return {
  //   //   selectedLevel: null,
  //   //   selectedResource: null,
  //   //   selectedFrequentResourceType: null,
  //   //   screenNum: 1
  //   // };
  // },
  computed: {
    ...mapState("plan", [
      "selectedLevel",
      "selectedResource",
      "selectedFrequentResourceType",
      "screenNum",
      "anyResourceTypes"
    ])
  },
  methods: {
    ...mapActions("plan", [
      "selectPlanLevel",
      "selectPlanResource",
      "selectPlanFrequentResourceType",
      "setScreenNum"
    ]),
    displayParentNames(parents) {
      const filteredParents = parents.filter(item => item.name);
      return filteredParents.map(parent => parent.name).join(", ");
    }
  }
};
