const COCKPIT_MIDDLEWARES = {
  auth: null,
  permission: "COCKPIT",
  menuPreferences: "COCKPIT"
};

export default {
  path: "/cockpit",
  name: "r_cockpit",
  meta: {
    middlewares: COCKPIT_MIDDLEWARES
  },
  component: () => import("../../components/cockpit/Cockpit"),
  children: [
    {
      path: "settings",
      name: "r_cockpit-settings",
      meta: {
        middlewares: COCKPIT_MIDDLEWARES
      },
      component: () => import("../../components/cockpit/settings/Settings"),
      children: [
        {
          path: "visitor-management",
          name: "r_cockpit-settings-visitor-management",
          meta: {
            middlewares: COCKPIT_MIDDLEWARES
          },
          component: () =>
            import("../../components/cockpit/settings/VisitorManagement")
        },
        {
          path: "bookings",
          name: "r_cockpit-settings-bookings",
          meta: {
            middlewares: COCKPIT_MIDDLEWARES
          },
          component: () => import("../../components/cockpit/settings/Bookings")
        }
      ]
    }
  ]
};
