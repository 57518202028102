export default {
  path: "/cx-data-regions",
  name: "r_data-regions",
  meta: {
    middlewares: {
      auth: null
    }
  },
  component: () => import("../../components/data-regions/DataRegions"),
  children: [
    {
      path: "add-data-region",
      name: "r_add-data-region",
      meta: {
        middlewares: {
          auth: null
        }
      },
      component: () => import("../../components/data-regions/AddDataRegion")
    },
    {
      path: "edit-data-region",
      name: "r_edit-data-region",
      meta: {
        middlewares: {
          auth: null
        }
      },
      component: () => import("../../components/data-regions/EditDataRegion")
    }
  ]
};
