import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import store from "@/store";

export default {
  state: {
    maps: null,
    map: null,
    active: false,
    token: null
  },
  mutations: {
    setMaps(state, payload) {
      state.maps = payload;
    },
    setMap(state, payload) {
      state.map = payload;
    },
    setActive(state, payload) {
      state.active = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    }
  },
  actions: {
    getMaps({ state }) {
      store.dispatch("pagination/paginate", {
        whatToCall: "realtime/getMaps",
        whereToSave: "realtime/setMaps",
        url: apiEndpoints.company.maps,
        data: state.maps,
        params: {}
      });
    },
    setMaps({ commit }, payload) {
      commit("setMaps", payload);
    },
    getMap({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.maps}/${id}`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setActive", response.data.data.realtime === 0 ? false : true);
          commit("setMap", response.data.data);
          // if (response.data.data.realtime === 1) {
          //   dispatch("getToken");
          // } else {
          //   commit("setToken", null);
          // }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    updateMap({ commit, state, dispatch }, id) {
      const url = `${apiEndpoints.company.maps}/${id}`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .put(
          url,
          { realtime: state.active ? 0 : 1 },
          { params: { set_realtime: 1 } }
        )
        .then(response => {
          commit("setMap", response.data.data);
          commit("setActive", response.data.data.realtime === 0 ? false : true);
          if (response.data.data.realtime === 1) {
            dispatch("getToken");
          } else {
            commit("setToken", null);
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
          dispatch("getMaps");
        });
    },
    getToken({ commit }) {
      const url = `${apiEndpoints.company.temptoken}?realtime=true`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .post(url)
        .then(response => {
          commit("setToken", response.data.access_token);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    }
  }
};
