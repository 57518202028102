import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    levelResources: null,
    selectedLevel: null,
    cleanQrCode: null,
    resourceCleaned: false
  },
  mutations: {
    setLevelResources(state, payload) {
      state.levelResources = payload;
    },
    setSelectedLevel(state, payload) {
      state.selectedLevel = payload;
    },
    setCleanQrCode(state, payload) {
      state.cleanQrCode = payload;
    },
    setResourceCleaned(state, payload) {
      state.resourceCleaned = payload;
    }
  },
  actions: {
    getLevelResources({ commit }, levelId) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.cleanResources}`, {
          params: { level_id: levelId }
        })
        .then(response => {
          commit("setLevelResources", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedLevel({ commit, dispatch }, level) {
      commit("setSelectedLevel", level);
      dispatch("getLevelResources", level.id);
    }
  }
};
