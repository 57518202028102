import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import store from "@/store";

export default {
  state: {
    templates: null,
    selectedTemplate: null,
    qrCodes: null,
    selectedQrCode: null,

    /** Plan module */
    qrActive: false,
    qrScreenId: null,
    recordingStarted: false
  },
  mutations: {
    /** Plan module */
    setQrActive(state, payload) {
      state.qrActive = payload;
    },
    setQrRecordingStatus(state, payload) {
      state.recordingStarted = payload;
    },
    setQrScreenId(state, payload) {
      state.qrScreenId = payload;
    },

    /** Qr Code */
    setTemplates(state, payload) {
      state.templates = payload;
    },
    setSelectedTemplate(state, payload) {
      state.selectedTemplate = payload;
    },

    /** QrCode template */
    setQrCodes(state, payload) {
      state.qrCodes = payload;
    },
    setSelectedQrCode(state, payload) {
      state.selectedQrCode = payload;
    }
  },
  actions: {
    setQrCodes({ commit }, payload) {
      commit("setQrActive", payload);
    },
    /** Plan module */
    setQrActive({ commit }, payload) {
      commit("setQrActive", payload);
    },
    setQrRecordingStatus({ commit }, payload) {
      commit("setQrRecordingStatus", payload);
    },
    setQrScreenId({ commit }, payload) {
      commit("setQrScreenId", payload);
    },

    /**
     * QrCode Template
     */
    getTemplates({ state }) {
      store.dispatch("pagination/paginate", {
        whatToCall: "qrCode/getTemplates",
        whereToSave: "qrCode/setTemplates",
        url: apiEndpoints.company.qrCodeTemplates,
        data: state.templates,
        params: {}
      });
    },
    setTemplates({ commit }, payload) {
      commit("setTemplates", payload);
    },
    findTemplate({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.qrCodeTemplates}/${id}`)
        .then(response => {
          const template = response.data.data;
          if (template.is_default) {
            template.is_default = template.is_default == 0 ? false : true;
          }
          commit("setSelectedTemplate", template);
          return template;
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    deleteTemplate({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .delete(`${apiEndpoints.company.qrCodeTemplates}/${id}`)
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    createTemplate({ commit }, values) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.qrCodeTemplates}`;
      return httpServiceAuth
        .post(url, values)
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    updateTemplate({ commit }, { data, id }) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.qrCodeTemplates}/${id}`;

      return httpServiceAuth
        .put(url, data)
        .then(response => {
          const template = response.data.data;
          template.is_default = template.is_default == 0 ? false : true;
          return template;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setAsDefaultTemplate({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.qrCodeTemplates}/${id}/default`;
      return httpServiceAuth
        .put(url, { is_default: 1 })
        .then(response => {
          const template = response.data.data;
          template.is_default = template.is_default == 0 ? false : true;
          return template;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedTemplate({ commit }, payload) {
      commit("setSelectedTemplate", payload);
    },

    /**
     * QrCode actions
     */
    getQrCodes({ rootState, state }) {
      const params = {};
      if (rootState.qrCodeFilters.selectedTypeId !== null) {
        params["type"] = rootState.qrCodeFilters.selectedTypeId;
      }

      if (rootState.qrCodeFilters.selectedLevelId !== null) {
        params["level"] = rootState.qrCodeFilters.selectedLevelId;
      }

      const queryParams = new URLSearchParams(params).toString();
      const url = `${apiEndpoints.company.qrCodes}${
        queryParams ? "?" + queryParams : ""
      }`;

      store.dispatch("pagination/paginate", {
        whatToCall: "qrCode/getQrCodes",
        whereToSave: "qrCode/setQrCodes",
        url: url,
        data: state.qrCodes,
        params: {}
      });
      // return httpServiceAuth
      //   .get(url)
      //   .then(response => {
      //     commit("setQrCodes", response.data.data);
      //     return response.data.data;
      //   })
      //   .catch(error => {
      //     if (error.response) {
      //       errorHandler(error.response);
      //     }
      //   })
      //   .finally(() => {
      //     commit("loader/setScreenLoading", false, { root: true });
      //   });
    },
    setSelectedQrCode({ commit }, payload) {
      commit("setSelectedQrCode", payload);
    },
    deleteQrCode({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .delete(`${apiEndpoints.company.qrCodes}/${id}`)
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    findQrCode({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.qrCodes}/${id}`)
        .then(response => {
          const qrCode = response.data.data;
          commit("setSelectedQrCode", qrCode);
          return qrCode;
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    updateQrCode({ commit }, { data, id }) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.qrCodes}/${id}`;

      return httpServiceAuth
        .put(url, data)
        .then(response => {
          return response.data.data;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    readQrCode({ commit }, code) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.qrCode}/read/${code}`)
        .then(response => {
          return response.data.data;
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    exportQrCodes({ commit }, data) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.qrCodeExports}`;
      return httpServiceAuth
        .post(url, data, {
          responseType: "blob",
          exposedHeaders: ["Content-Disposition"]
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    }
  }
};
