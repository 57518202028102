import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    companies: null,
    selectedCompany: null,
    selectedCompanyType: null,
    companyTypes: null
  },
  mutations: {
    setCompaniesData(state, payload) {
      state.companies = payload;
    },
    setSelectedCompany(state, payload) {
      state.selectedCompany = payload;
    },
    setCompanyTypes(state, payload) {
      state.companyTypes = payload;
    },
    setCompanyType(state, payload) {
      state.selectedCompanyType = payload;
    }
  },
  actions: {
    getCompanies({ commit }) {
      return httpServiceAuth
        .get(`${apiEndpoints.master.companies}/all`)
        .then(response => {
          commit("setCompaniesData", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    getCompanyTypes({ commit }) {
      return httpServiceAuth
        .get(`${apiEndpoints.master.companyTypes}/all`)
        .then(response => {
          commit("setCompanyTypes", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    setSelectedCompany({ commit }, payload) {
      commit("setSelectedCompany", payload);
    },
    deleteCompany({ dispatch }, id) {
      return httpServiceAuth
        .delete(`${apiEndpoints.master.companies}/${id}`)
        .then(() => {
          dispatch("getCompanies");
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    setCompanyType({ commit }, payload) {
      commit("setCompanyType", payload);
    }
  }
};
