const L_VISITS_TODAY = "today";
const L_VISITS_PAST = "past";
const L_VISITS_FUTURE = "future";

const visitGroups = {
  [L_VISITS_TODAY]: {
    name: L_VISITS_TODAY,
    label: "today-visits",
    icon: ""
  },
  [L_VISITS_PAST]: {
    name: L_VISITS_PAST,
    label: "past-visits",
    icon: ""
  },
  [L_VISITS_FUTURE]: {
    name: L_VISITS_FUTURE,
    label: "future-visits",
    icon: ""
  }
};

export default visitGroups;

export { L_VISITS_TODAY, L_VISITS_PAST, L_VISITS_FUTURE };
