export default {
  path: "/pass/:pass_id?",
  name: "r_pass",
  meta: {
    middlewares: {
      auth: null,
      menuPreferences: "PLAN"
    }
  },
  component: () => import("../../components/pass/Pass"),
  children: [
    {
      path: "help",
      name: "r_pass-help-online",
      meta: {
        middlewares: {
          auth: null,
          menuPreferences: "PLAN"
        }
      },
      props: {
        backLinkName: "r_pass",
        slug: "plan-resources-space-pass"
      },
      component: () => import("../../components/help-online/HelpOnline")
    }
  ]
};
