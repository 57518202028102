import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  namespaced: true,
  state: {
    permissions: null,
    selectedPermission: null,
    permissionTypes: null,
    permissionCategories: null
  },
  getters: {},
  mutations: {
    setPermissions(state, payload) {
      state.permissions = payload;
    },
    setSelectedPermission(state, payload) {
      state.selectedPermission = payload;
    },
    setPermissionTypes(state, payload) {
      state.permissionTypes = payload;
    },
    setPermissionCategories(state, payload) {
      state.permissionCategories = payload;
    }
  },
  actions: {
    getPermissions({ commit }, params) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.permissions}/all`, { params })
        .then(response => {
          commit("setPermissions", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedPermission({ commit }, payload) {
      commit("setSelectedPermission", payload);
    },
    getPermissionTypes({ commit }, params) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.permissiontypes}/all`, { params })
        .then(response => {
          commit("setPermissionTypes", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getPermissionCategories({ commit }, params) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.permissioncategories}/all`, { params })
        .then(response => {
          commit("setPermissionCategories", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    }
  }
};
