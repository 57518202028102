import { APPLICATIONS } from "@/services/consts/application";

const ONE_CLICK_MIDDLEWARES = {
  auth: null,
  permission: "ONE-CLICK",
  document: { applicationId: APPLICATIONS.APP_ONE_CLICK.id },
  menuPreferences: "ONE_CLICK"
};

export default {
  path: "/one-click",
  name: "r_one-click",
  meta: {
    middlewares: ONE_CLICK_MIDDLEWARES
  },
  component: () => import("../../components/one-click/OneClick"),
  children: [
    {
      path: "info",
      name: "r_one-click-open-info",
      meta: {
        middlewares: ONE_CLICK_MIDDLEWARES
      },
      component: () => import("../../components/one-click/OneClickOpenInfo")
    },
    {
      path: "settings",
      name: "r_one-click-settings",
      meta: {
        middlewares: ONE_CLICK_MIDDLEWARES
      },
      component: () => import("../../components/one-click/OneClickSettings"),
      children: [
        {
          path: "contacts",
          name: "r_one-click-contacts",
          meta: {
            middlewares: ONE_CLICK_MIDDLEWARES
          },
          component: () =>
            import("../../components/one-click/OneClickContacts"),
          children: [
            {
              path: "help",
              name: "r_one-click-contacts-help-online",
              meta: {
                middlewares: ONE_CLICK_MIDDLEWARES
              },
              props: {
                backLinkName: "r_one-click-contacts",
                slug: "one-click-settings-find"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "plan",
          name: "r_one-click-plan",
          meta: {
            middlewares: ONE_CLICK_MIDDLEWARES
          },
          component: () => import("../../components/one-click/OneClickPlan"),
          children: [
            {
              path: "add",
              name: "r_one-click-plan-add",
              meta: {
                middlewares: ONE_CLICK_MIDDLEWARES
              },
              component: () =>
                import("../../components/one-click/OneClickPlanAdd"),
              children: [
                {
                  path: ":levelId",
                  name: "r_one-click-plan-add-resources",
                  meta: {
                    middlewares: ONE_CLICK_MIDDLEWARES
                  },
                  component: () =>
                    import(
                      "../../components/one-click/OneClickPlanAddResources"
                    ),
                  children: [
                    {
                      path: "set-plan",
                      name: "r_one-click-plan-add-resources-mode",
                      meta: {
                        middlewares: ONE_CLICK_MIDDLEWARES
                      },
                      component: () =>
                        import(
                          "../../components/one-click/OneClickPlanAddResourcesMode"
                        ),
                      children: [
                        {
                          path: "help",
                          name: "r_one-click-plan-add-period-help-online",
                          meta: {
                            middlewares: ONE_CLICK_MIDDLEWARES
                          },
                          props: {
                            backLinkName: "r_one-click-plan-add-resources-mode",
                            slug: "one-click-settings-plan-add-period"
                          },
                          component: () =>
                            import("../../components/help-online/HelpOnline")
                        }
                      ]
                    },
                    {
                      path: "help",
                      name: "r_one-click-plan-add-resource-help-online",
                      meta: {
                        middlewares: ONE_CLICK_MIDDLEWARES
                      },
                      props: {
                        backLinkName: "r_one-click-plan-add-resources",
                        slug: "one-click-settings-plan-add-resource"
                      },
                      component: () =>
                        import("../../components/help-online/HelpOnline")
                    }
                  ]
                },
                {
                  path: "help",
                  name: "r_one-click-plan-add-help-online",
                  meta: {
                    middlewares: ONE_CLICK_MIDDLEWARES
                  },
                  props: {
                    backLinkName: "r_one-click-plan-add",
                    slug: "one-click-settings-plan-add"
                  },
                  component: () =>
                    import("../../components/help-online/HelpOnline")
                }
              ]
            },
            {
              path: "help",
              name: "r_one-click-plan-help-online",
              meta: {
                middlewares: ONE_CLICK_MIDDLEWARES
              },
              props: {
                backLinkName: "r_one-click-plan",
                slug: "one-click-settings-plan"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "info",
          name: "r_one-click-info",
          meta: {
            middlewares: ONE_CLICK_MIDDLEWARES
          },
          component: () => import("../../components/one-click/OneClickInfo"),
          children: [
            {
              path: "add",
              name: "r_one-click-info-add",
              meta: {
                middlewares: ONE_CLICK_MIDDLEWARES
              },
              component: () =>
                import("../../components/one-click/OneClickInfoAdd")
            },
            {
              path: ":infoId",
              name: "r_one-click-info-edit",
              meta: {
                middlewares: ONE_CLICK_MIDDLEWARES
              },
              component: () =>
                import("../../components/one-click/OneClickInfoEdit")
            }
          ]
        },
        {
          path: "map",
          name: "r_one-click-map",
          meta: {
            middlewares: ONE_CLICK_MIDDLEWARES
          },
          component: () => import("../../components/one-click/OneClickMap"),
          children: [
            {
              path: "add",
              name: "r_one-click-map-add",
              meta: {
                middlewares: ONE_CLICK_MIDDLEWARES
              },
              component: () =>
                import("../../components/one-click/OneClickMapAdd"),
              children: [
                {
                  path: ":levelId",
                  name: "r_one-click-map-add-types",
                  meta: {
                    middlewares: ONE_CLICK_MIDDLEWARES
                  },
                  component: () =>
                    import("../../components/one-click/OneClickMapAddTypes"),
                  children: [
                    {
                      path: "set-map",
                      name: "r_one-click-map-add-types-mode",
                      meta: {
                        middlewares: ONE_CLICK_MIDDLEWARES
                      },
                      component: () =>
                        import(
                          "../../components/one-click/OneClickMapAddTypesMode"
                        ),
                      children: [
                        {
                          path: "help",
                          name: "r_one-click-map-add-period-help-online",
                          meta: {
                            middlewares: ONE_CLICK_MIDDLEWARES
                          },
                          props: {
                            backLinkName: "r_one-click-map-add-types-mode",
                            slug: "one-click-settings-map-add-period"
                          },
                          component: () =>
                            import("../../components/help-online/HelpOnline")
                        }
                      ]
                    },
                    {
                      path: "help",
                      name: "r_one-click-map-add-type-help-online",
                      meta: {
                        middlewares: ONE_CLICK_MIDDLEWARES
                      },
                      props: {
                        backLinkName: "r_one-click-map-add-types",
                        slug: "one-click-settings-map-add-type"
                      },
                      component: () =>
                        import("../../components/help-online/HelpOnline")
                    }
                  ]
                },
                {
                  path: "help",
                  name: "r_one-click-map-add-help-online",
                  meta: {
                    middlewares: ONE_CLICK_MIDDLEWARES
                  },
                  props: {
                    backLinkName: "r_one-click-map-add",
                    slug: "one-click-settings-map-add"
                  },
                  component: () =>
                    import("../../components/help-online/HelpOnline")
                }
              ]
            },
            {
              path: "help",
              name: "r_one-click-map-help-online",
              meta: {
                middlewares: ONE_CLICK_MIDDLEWARES
              },
              props: {
                backLinkName: "r_one-click-map",
                slug: "one-click-settings-map"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "help",
          name: "r_one-click-settings-help-online",
          meta: {
            middlewares: ONE_CLICK_MIDDLEWARES
          },
          props: {
            backLinkName: "r_one-click-settings",
            slug: "one-click-settings"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: ":urlLinkName",
      name: "r_one-click-url",
      meta: {
        middlewares: ONE_CLICK_MIDDLEWARES
      },
      component: () => import("../../components/one-click/OneClickUrl")
    },
    {
      path: "slots",
      name: "r_one-click-slots",
      meta: {
        middlewares: { auth: null, permission: "PLAN" }
      },
      props: {
        backLinkName: "r_one-click"
      },
      component: () => import("../../components/plans/PlanSlots"),
      children: [
        {
          path: "maps",
          name: "r_one-click-slots-maps",
          meta: {
            middlewares: { auth: null, permission: "PLAN" }
          },
          props: {
            backLinkName: "r_one-click-slots"
          },
          component: () => import("../../components/plans/Plan-5d-map")
        }
      ]
    },
    {
      path: "person-map",
      name: "r_one-click-person-map",
      meta: {
        middlewares: ONE_CLICK_MIDDLEWARES
      },
      component: () =>
        import("../../components/one-click/OneClickFindPersonMap")
    },
    {
      path: "find-person",
      name: "r_one-click-find-person",
      meta: {
        middlewares: ONE_CLICK_MIDDLEWARES
      },
      component: () => import("../../components/one-click/OneClickFindPerson"),
      children: [
        {
          path: "map",
          name: "r_one-click-find-person-map",
          meta: {
            middlewares: ONE_CLICK_MIDDLEWARES
          },
          component: () =>
            import("../../components/one-click/OneClickFindPersonMap")
        }
      ]
    },
    {
      path: "map",
      name: "r_one-click-map-page",
      meta: {
        middlewares: ONE_CLICK_MIDDLEWARES
      },
      component: () => import("../../components/one-click/OneClickMapPage"),
      children: [
        {
          path: "calendar",
          name: "r_one-click-calendar",
          meta: {
            middlewares: ONE_CLICK_MIDDLEWARES
          },
          props: {
            backLinkName: "r_one-click-map-page"
          },
          component: () => import("../../components/plans/PlanSlots")
        }
      ]
    },
    {
      path: "help",
      name: "r_one-click-help-online",
      meta: {
        middlewares: ONE_CLICK_MIDDLEWARES
      },
      props: {
        backLinkName: "r_one-click",
        slug: "one-click"
      },
      component: () => import("../../components/help-online/HelpOnline")
    }
  ]
};
