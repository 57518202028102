export default [
  {
    path: "/manage-users",
    name: "r_manage-users",
    meta: {
      middlewares: {
        auth: null
      }
    },
    component: () => import("../../components/users/UsersList"),
    children: [
      {
        path: "add-user",
        name: "r_add-user",
        meta: {
          middlewares: {
            auth: null,
            permission: ["MANAGE_USERS", "create"]
          }
        },
        component: () => import("../../components/users/AddUser"),
        children: [
          {
            path: "language",
            name: "r_add-user-language",
            meta: {
              middlewares: {
                auth: null,
                permission: ["MANAGE_USERS", "create"]
              }
            },
            props: { backLinkName: "r_add-user" },
            component: () => import("../../components/global/LanguageSelection")
          },
          {
            path: "timezone",
            name: "r_add-user-timezone",
            meta: {
              middlewares: {
                auth: null,
                permission: ["MANAGE_USERS", "create"]
              }
            },
            props: { backLinkName: "r_add-user" },
            component: () => import("../../components/global/TimezoneSelection")
          },
          {
            path: "country",
            name: "r_add-user-country",
            meta: {
              middlewares: {
                auth: null,
                permission: ["MANAGE_USERS", "create"]
              }
            },
            props: { backLinkName: "r_add-user" },
            component: () =>
              import("../../components/global/CountriesSelection")
          },
          {
            path: "add-assignable-containers-to-user",
            name: "r_add-user-add-containers-to-user",
            meta: {
              middlewares: {
                auth: null,
                permission: ["MANAGE_USERS", "create"]
              }
            },
            props: { backLinkName: "r_add-user", actionType: "add" },
            component: () =>
              import("../../components/users/AddAssignableContainersToUser")
          },
          {
            path: "change-password",
            name: "r_change-password",
            meta: {
              middlewares: {
                auth: null
              }
            },
            props: { backLinkName: "r_add-user" },
            component: () =>
              import("../../components/change-password/ChangePassword")
          }
        ]
      },
      {
        path: "edit-user/:user_id",
        name: "r_edit-user",
        meta: {
          middlewares: {
            auth: null
          }
        },
        component: () => import("../../components/users/EditUser"),
        children: [
          {
            path: "language",
            name: "r_edit-user-language",
            meta: {
              middlewares: {
                auth: null
              }
            },
            props: { backLinkName: "r_edit-user" },
            component: () => import("../../components/global/LanguageSelection")
          },
          {
            path: "timezone",
            name: "r_edit-user-timezone",
            props: { backLinkName: "r_edit-user" },
            meta: {
              middlewares: {
                auth: null
              }
            },
            component: () => import("../../components/global/TimezoneSelection")
          },
          {
            path: "country",
            name: "r_edit-user-country",
            meta: {
              middlewares: {
                auth: null
              }
            },
            props: { backLinkName: "r_edit-user" },
            component: () =>
              import("../../components/global/CountriesSelection")
          },
          {
            path: "add-assignable-containers-to-user",
            name: "r_edit-user-add-containers-to-user",
            meta: {
              middlewares: {
                auth: null
              }
            },
            props: { backLinkName: "r_edit-user", actionType: "edit" },
            component: () =>
              import("../../components/users/AddAssignableContainersToUser")
          },
          {
            path: "add-assignable-resources-to-user",
            name: "r_edit-user-add-resources-to-user",
            meta: {
              middlewares: {
                auth: null
              }
            },
            props: { backLinkName: "r_edit-user", actionType: "edit" },
            component: () =>
              import("../../components/users/AddAssignableResourcesToUser"),
            children: [
              {
                path: ":resourceId/:assignedResourceId",
                name: "r_edit-assigned-resource",
                meta: {
                  middlewares: {
                    auth: null
                  }
                },
                props: {
                  backLinkName: "r_edit-user-add-resources-to-user",
                  actionType: "edit"
                },
                component: () =>
                  import(
                    "../../components/users/AddAssignableResourcesToUserResourceMode"
                  )
              },
              {
                path: "levels",
                name: "r_edit-user-add-resources-to-user-levels",
                meta: {
                  middlewares: {
                    auth: null
                  }
                },
                props: {
                  backLinkName: "r_edit-user-add-resources-to-user",
                  actionType: "add"
                },
                component: () =>
                  import(
                    "../../components/users/AddAssignableResourcesToUserLevels"
                  ),
                children: [
                  {
                    path: ":levelId",
                    name: "r_edit-user-add-resources-to-user-resources",
                    meta: {
                      middlewares: {
                        auth: null
                      }
                    },
                    props: {
                      backLinkName: "r_edit-user-add-resources-to-user-levels",
                      actionType: "add"
                    },
                    component: () =>
                      import(
                        "../../components/users/AddAssignableResourcesToUserResources"
                      ),
                    children: [
                      {
                        path: ":resourceId",
                        name: "r_edit-user-add-resources-to-user-resource-mode",
                        meta: {
                          middlewares: {
                            auth: null
                          }
                        },
                        props: {
                          backLinkName:
                            "r_edit-user-add-resources-to-user-resources",
                          actionType: "add"
                        },
                        component: () =>
                          import(
                            "../../components/users/AddAssignableResourcesToUserResourceMode"
                          )
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: "search-filters",
        name: "r_users-search-filters",
        meta: {
          middlewares: {
            auth: null,
            permission: ["MANAGE_USERS"]
          }
        },
        props: {
          backLinkName: "r_manage-users",
          searchFiltersStore: "searchFiltersUsers"
        },
        component: () => import("../../components/search/SearchFiltersUsers")
      }
    ]
  }
];
