import httpServiceAuth, {
  saveAccessToken,
  clearAccessToken,
  getSubdomain,
  setLang,
} from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import router from "@/router";
import store from "@/store";

// TODO set general permissions in local storage and use them there
// TODO create directive for permissions so you can hide/show ui items with it

export default {
  state: {
    loggedIn: null,
    passwordResetEmail: "",
    successMessage: "",
    errorMessage: "",
    generalPermissions: null,
    fetchingGeneralPermissions: null,
    codeSignIn: false,
    isOutlookPage: false,
    device: null,
    startupPage: {
      MAIN_MENU: "r_home",
      AUTHORIZE: "r_authorize",
      BOOKING: "r_booking",
      CLEAN: "r_clean",
      DISTANCE: "r_distance",
      DOCUMENTS: "r_documents",
      EVENTS: "r_events",
      MESSAGES: "r_messages",
      OCCUPANCY: "r_occupancy",
      PLAN: "r_plan",
      REAL_TIME: "r_realtime",
      SERVICES: "r_services",
      SURVEY: "r_survey",
      ONE_CLICK: "r_one-click",
      COCKPIT: "r_cockpit",
      "5D_MAPS": "r_5dmaps",
    },
  },
  mutations: {
    setDevice(state, payload) {
      state.device = payload;
    },
    setLoggedInStatus(state, payload) {
      state.loggedIn = payload;
    },
    setPasswordResetEmail(state, payload) {
      state.passwordResetEmail = payload;
    },
    setSuccessMessage(state, payload) {
      state.successMessage = payload;
    },
    setErrorMessage(state, payload) {
      if (payload) {
        const { data } = payload;
        if (data) {
          const { message, errors } = data;
          if (errors) {
            const keys = Object.keys(errors);
            if (keys.length) {
              state.errorMessage = errors[keys[0]][0];
            } else {
              state.errorMessage = message;
            }
          } else {
            state.errorMessage = message;
          }
        } else {
          state.errorMessage = payload;
        }
      } else {
        state.errorMessage = "";
      }
    },
    setGeneralPermissions(state, payload) {
      state.generalPermissions = payload;
    },
    setFetchingGeneralPermissions(state, payload) {
      state.fetchingGeneralPermissions = payload;
    },
    setCodeSignIn(state, payload) {
      state.codeSignIn = payload;
    },
    setIsOutlookPage(state, payload) {
      state.isOutlookPage = payload;
    },
  },
  actions: {
    login({ commit, state }, payload) {
      // make sure to clear access token if one has remained after
      // session expiration
      clearAccessToken();
      const { company, master } = apiEndpoints;
      const url = getSubdomain() ? company.login : master.login;
      let data = {};

      const { token, addIn } = payload;

      if (token) {
        data.token = token;
      } else {
        data = payload;
      }

      return httpServiceAuth
        .post(url, data, {
          params: { includes: ["all"] },
        })
        .then((response) => {
          if (url == company.login) {
            if (response.data.data && response.data.data.device) {
              window.location.href = response.data.data.device;
            } else {
              // Set startup page - START
              if (response.data.data.startup_page) {
                commit(
                  "preferences/setLandingPagePreferenceUrl",
                  state.startupPage[response.data.data.startup_page],
                  {
                    root: true,
                  }
                );
              }
              // Set startup page - END
              // Set general permissions - START
              commit(
                "setGeneralPermissions",
                response.data.data.general_permissions
              );
              // Set general permissions - END
              // Set labels - START

              store.dispatch(
                "translation/setTranslations",
                response.data.data.labels,
                {
                  root: true,
                }
              );
              // Set labels - END
              // Set languages - START
              setLang(response.data.data.user.language);
              store.dispatch(
                "language/setLanguageData",
                response.data.data.languages,
                {
                  root: true,
                }
              );
              // Set languages - END
              // Set countries - START
              store.dispatch(
                "country/setCountryData",
                response.data.data.countries,
                {
                  root: true,
                }
              );
              // Set countries - END
              // Set timezones - START
              store.dispatch(
                "timezone/setTimezoneData",
                response.data.data.timezones,
                {
                  root: true,
                }
              );
              // Set timezones - END
              // Set currencies - START
              store.dispatch(
                "currency/setCurrencyData",
                response.data.data.currencies,
                {
                  root: true,
                }
              );
              // Set currencies - END
              // Set preference categories - START
              store.dispatch(
                "preferences/setPreferenceCategories",
                response.data.data.preferencecategories,
                {
                  root: true,
                }
              );
              // Set preference categories - END
              // Set user time preference - START
              store.dispatch(
                "settings/setUserTimePreferences",
                response.data.data.usertimepreference,
                {
                  root: true,
                }
              );
              // Set user time preference - END
              // Set device templates - START
              store.dispatch(
                "deviceTemplates/setDeviceTemplates",
                response.data.data.devicetemplates,
                {
                  root: true,
                }
              );
              // Set device templates - END
            }
          } else if (url == master.login) {
            if (response.data.data) {
              // Set languages - START
              store.dispatch(
                "language/setLanguageData",
                response.data.data.languages,
                {
                  root: true,
                }
              );
              // Set languages - END
              // Set countries - START
              store.dispatch(
                "country/setCountryData",
                response.data.data.countries,
                {
                  root: true,
                }
              );
              // Set countries - END
              // Set timezones - START
              store.dispatch(
                "timezone/setTimezoneData",
                response.data.data.timezones,
                {
                  root: true,
                }
              );
              // Set timezones - END
              // Set currencies - START
              store.dispatch(
                "currency/setCurrencyData",
                response.data.data.currencies,
                {
                  root: true,
                }
              );
              // Set currencies - END
            }
          }

          const { access_token, subdomain } = response.data;

          if (subdomain) {
            let hostname = window.location.hostname;
            let urlWithSubdomain;

            if (hostname.indexOf("www") !== -1) {
              urlWithSubdomain = `www.${subdomain}.${window.location.hostname.replace(
                "www.",
                ""
              )}`;
            } else {
              urlWithSubdomain = `${subdomain}.${window.location.hostname}`;
            }

            window.location.href = `${window.location.protocol}//${urlWithSubdomain}?token=${access_token}`;
            history.pushState(
              {},
              "",
              `${window.location.protocol}//${urlWithSubdomain}?token=${access_token}`
            );
            return;
          }

          if (access_token) {
            saveAccessToken(access_token);
            commit("setLoggedInStatus", true);
            const messageObj = { message: access_token };
            const stringifiedMessageObj = JSON.stringify(messageObj);
            console.log("pre-postmessage call on webkit");
            if (window.webkit && Window.webkit.messageHandlers) {
              console.log("postmessage call on webkit");
              window.webkit.messageHandlers.cordova_iab.postMessage(
                stringifiedMessageObj
              );
            }

            if (state.isOutlookPage) {
              router.push({
                name: "r_outlook",
              });
            } else if (addIn) {
              window.location.href = `${process.env.VUE_APP_OUTLOOK_URL}/dialog.html?close=true`;
            } else {
              router.push({
                name: "r_home",
              });
            }
          }
        });
    },
    logout() {
      const subdomain = getSubdomain();
      const { company, master } = apiEndpoints;
      const url = subdomain ? company.logout : master.logout;
      return httpServiceAuth.get(url).finally(() => {
        clearAccessToken();
        router.go({ name: "r_login" });
      });
    },
    ssoLogin({ commit }, payload) {
      // make sure to clear access token if one has remained after
      // session expiration
      clearAccessToken();
      commit("setLoggedInStatus", false);
      const subdomain = getSubdomain();
      const { company, master } = apiEndpoints;
      const url = subdomain ? company.ssoLogin : master.ssoLogin;
      return httpServiceAuth
        .post(url, {
          SAMLResponse: payload,
        })
        .then((response) => {
          const { access_token, subdomain } = response.data;
          if (subdomain) {
            let hostname = window.location.hostname;
            let urlWithSubdomain;

            if (hostname.indexOf("www") !== -1) {
              urlWithSubdomain = `www.${subdomain}.${window.location.hostname.replace(
                "www.",
                ""
              )}`;
            } else {
              urlWithSubdomain = `${subdomain}.${window.location.hostname}`;
            }
            window.location.href = `${window.location.protocol}//${urlWithSubdomain}?token=${access_token}`;
            return;
          }
          if (access_token) {
            saveAccessToken(access_token);
            commit("setLoggedInStatus", true);

            const messageObj = { message: access_token };
            const stringifiedMessageObj = JSON.stringify(messageObj);
            console.log("pre-postmessage call on webkit");
            if (window.webkit && Window.webkit.messageHandlers) {
              console.log("postmessage call on webkit");
              window.webkit.messageHandlers.cordova_iab.postMessage(
                stringifiedMessageObj
              );
            }
          }
        });
    },
    passwordForgot({ commit }, email) {
      commit("loader/setScreenLoading", true, { root: true });
      let type = "master";
      if (getSubdomain()) {
        type = "company";
      }

      httpServiceAuth
        .post(apiEndpoints[type].passwordForgot, {
          email,
        })
        .then((response) => {
          const { message } = response.data;
          if (message) commit("setSuccessMessage", message);
        })
        .catch((error) => {
          commit("setErrorMessage", error.response);
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getPasswordResetEmail({ commit }, requestToken) {
      commit("loader/setScreenLoading", true, { root: true });
      let type = "master";
      if (getSubdomain()) {
        type = "company";
      }
      return httpServiceAuth
        .get(`${apiEndpoints[type].passwordReset}/${requestToken}`)
        .then((response) => {
          const { email } = response.data;
          if (email) commit("setPasswordResetEmail", email);
        })
        .catch((error) => {
          commit("setErrorMessage", error.response);
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    passwordReset({ commit }, payload) {
      commit("loader/setScreenLoading", true, { root: true });
      let type = "master";
      if (getSubdomain()) {
        type = "company";
      }
      httpServiceAuth
        .post(apiEndpoints[type].passwordReset, {
          ...payload,
        })
        .then((response) => {
          const { message } = response.data;
          if (message) commit("setSuccessMessage", message);
        })
        .catch((error) => {
          commit("setErrorMessage", error.response);
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    sendTempCode({ commit }, uuid) {
      return httpServiceAuth
        .get(`${apiEndpoints.company.tempCode}/${uuid}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          commit("setErrorMessage", error.response);
        });
    },

    loginWithCode({ commit }, payload) {
      // make sure to clear access token if one has remained after
      // session expiration
      clearAccessToken();
      const type = getSubdomain() ? "company" : "master";

      return httpServiceAuth
        .post(
          apiEndpoints[type].tempCode +
            (payload.locale ? "?lang=" + payload.locale : ""),
          payload,
          {
            params: { includes: ["startup_page", "user", "company"] },
          }
        )
        .then((response) => {
          commit("preferences/setLandingPagePreferenceUrl", "r_profile", {
            root: true,
          });
          const { access_token, subdomain } = response.data;

          if (subdomain) {
            let hostname = window.location.hostname;
            let urlWithSubdomain;

            if (hostname.indexOf("www") !== -1) {
              urlWithSubdomain = `www.${subdomain}.${window.location.hostname.replace(
                "www.",
                ""
              )}`;
            } else {
              urlWithSubdomain = `${subdomain}.${window.location.hostname}`;
            }

            window.location.href = `${window.location.protocol}//${urlWithSubdomain}?token=${access_token}`;
            return;
          }

          if (access_token) {
            saveAccessToken(access_token);
            commit("setLoggedInStatus", true);

            const messageObj = { message: access_token };
            const stringifiedMessageObj = JSON.stringify(messageObj);
            console.log("pre-postmessage call on webkit");
            if (window.webkit && Window.webkit.messageHandlers) {
              console.log("postmessage call on webkit");
              window.webkit.messageHandlers.cordova_iab.postMessage(
                stringifiedMessageObj
              );
            }
            router.push({
              name: "r_home",
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            commit("setErrorMessage", error.response);
          }
        });
    },
  },
};
