import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import httpServiceAuth from "@/services/http-service";

export default {
  state: {
    passes: null,
    pass: null,
    selectedPass: {
      id: 0
    },
    backLinkName: null
  },
  mutations: {
    setPassesData(state, payload) {
      state.passes = payload;
    },
    setPassData(state, payload) {
      state.pass = payload;
    },
    setSelectedPass(state, payload) {
      state.selectedPass = payload;
    },
    setBackLinkName(state, payload) {
      state.backLinkName = payload;
    }
  },
  actions: {
    getPasses({ commit }, { params, noLoaders }) {
      if (!noLoaders) {
        commit("loader/setScreenLoading", true, { root: true });
      }
      return httpServiceAuth
        .get(`${apiEndpoints.company.spacePasses}/all`, { params })
        .then(response => {
          commit("setPassesData", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getPass({ commit }, { id, params }) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.spacePasses}/${id}`, { params })
        .then(response => {
          commit("setPassData", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    deletePass({ commit, dispatch }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .delete(`${apiEndpoints.company.spacePasses}/${id}`)
        .then(() => {
          dispatch("getPasses", {
            params: { includes: ["declaration_status", "full_path"] },
            noLoaders: false
          });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    }
  }
};
