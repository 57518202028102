import httpServiceAuth, { getSubdomain } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    applications: null,
    selectedApplication: null,
    selectedAppVersions: null,
    selectedVersion: null,
    application: null
  },
  mutations: {
    setApplicationData(state, payload) {
      state.applications = payload;
    },
    setSelectedApplication(state, payload) {
      state.selectedApplication = payload;
    },
    setSelectedAppVersions(state, payload) {
      state.selectedAppVersions = payload;
    },
    setSelectedVersion(state, payload) {
      state.selectedVersion = payload;
    },
    setApplication(state, payload) {
      state.application = payload;
    }
  },
  actions: {
    getApplications({ commit }) {
      const subdomain = getSubdomain();
      let url = `${apiEndpoints.master.applications}/all`;
      if (subdomain) url = `${apiEndpoints.company.applications}/all`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setApplicationData", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getAppVersions({ commit }, id) {
      const subdomain = getSubdomain();
      let url = apiEndpoints.master.applications;
      if (subdomain) url = apiEndpoints.company.applications;
      url = `${url}/${id}/versions`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setSelectedAppVersions", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    setSelectedApplication({ commit }, payload) {
      commit("setSelectedApplication", payload);
    },
    setSelectedAppVersions({ commit }, payload) {
      commit("setSelectedAppVersions", payload);
    },
    setSelectedVersion({ commit }, payload) {
      commit("setSelectedVersion", payload);
    },
    setApplicationData({ commit }, payload) {
      commit("setApplicationData", payload);
    },
    getApplication({ commit }, id) {
      const subdomain = getSubdomain();
      let url = `${apiEndpoints.master.applications}/${id}`;
      if (subdomain) url = `${apiEndpoints.company.applications}/${id}`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setApplication", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getAcceptedByUser({ commit }, id) {
      // commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.applications}/${id}/accepted-by-user`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          return response.data.success;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    }
  }
};
