import store from "@/store";
export const errorHandler = (errorObject, el, routeName) => {
  const { data } = errorObject;
  if (data) {
    const { message, errors } = data;
    if (errors) {
      const keys = Object.keys(errors);
      if (keys.length) {
        store.commit("global/setErrorMessage", errors[keys[0]][0], {
          root: true
        });
      } else {
        store.commit("global/setErrorMessage", message, { root: true });
      }
    } else {
      store.commit("global/setErrorMessage", message, { root: true });
    }
  }
  if (routeName) {
    store.commit("global/setErrorRouteName", routeName, { root: true });
  }

  if (el) {
    const closesEl = el.closest("[data-id]");
    let modalId;
    // nested screen
    if (closesEl) {
      modalId = closesEl.dataset.id;
    } else {
      // main layout screen
      const firstChildScreen = el.querySelector("[data-id]");
      modalId = firstChildScreen.dataset.id;
    }

    if (modalId) {
      store.commit("global/setScreenId", modalId, { root: true });
    }
  } else {
    // if no element is provided show error message on last screen
    const modalEl = document.querySelectorAll(".screen");
    const modalId = modalEl[modalEl.length - 1].dataset.id;
    if (modalId) {
      store.commit("global/setScreenId", modalId, { root: true });
    }
  }
};
