export default {
  path: "/company-profile",
  name: "r_company-profile",
  meta: {
    middlewares: {
      auth: null,
      permission: ["COMPANY_PROFILE", "read"]
    }
  },
  component: () => import("../../components/company-profile/CompanyProfile"),
  children: [
    {
      path: "select-country",
      name: "r_company-profile-select-country",
      meta: {
        middlewares: {
          auth: null
        }
      },
      props: {
        backLinkName: "r_company-profile"
      },
      component: () => import("../../components/global/CountriesSelection")
    },
    {
      path: "select-language",
      name: "r_company-profile-select-language",
      meta: {
        middlewares: {
          auth: null
        }
      },
      props: {
        backLinkName: "r_company-profile"
      },
      component: () => import("../../components/global/LanguageSelection")
    },
    {
      path: "currency",
      name: "r_company-profile-select-currency",
      meta: {
        middlewares: {
          auth: null
        }
      },
      props: { backLinkName: "r_company-profile" },
      component: () => import("../../components/global/CurrencySelection")
    },
    {
      path: "select-timezone",
      name: "r_company-profile-select-timezone",
      meta: {
        middlewares: {
          auth: null
        }
      },
      props: {
        backLinkName: "r_company-profile"
      },
      component: () => import("../../components/global/TimezoneSelection")
    },
    {
      path: "profile-photo",
      name: "r_company-profile-change-photo",
      meta: {
        middlewares: {
          auth: null
        }
      },
      props: { backLinkName: "r_company-profile" },
      component: () =>
        import("../../components/company-profile-photo/CompanyProfilePhoto")
    }
  ]
};
