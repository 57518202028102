import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    dataRegions: null,
    selectedDataRegion: null
  },
  mutations: {
    setDataRegions(state, payload) {
      state.dataRegions = payload;
    },
    setSelectedDataRegion(state, payload) {
      state.selectedDataRegion = payload;
    }
  },
  actions: {
    getDataRegions({ commit }) {
      let url = `${apiEndpoints.master.dataRegions}`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setDataRegions", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    setSelectedDataRegion({ commit }, payload) {
      commit("setSelectedDataRegion", payload);
    },
    deleteDataRegion({ dispatch }, id) {
      return httpServiceAuth
        .delete(`${apiEndpoints.master.dataRegions}/${id}`)
        .then(() => {
          dispatch("getDataRegions");
        });
    }
  }
};
