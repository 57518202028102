export default {
  path: "/service-items",
  name: "r_service-items",
  meta: {
    middlewares: {
      auth: null,
      permission: "SERVICE_ITEMS"
    }
  },
  component: () => import("../../components/service-items/ServiceItems"),
  children: [
    {
      path: "add",
      name: "r_add-service-item",
      meta: {
        middlewares: {
          auth: null,
          permission: ["MANAGE_SERVICE_ITEMS", "create"]
        }
      },
      props: { backLinkName: "r_service-items" },
      component: () => import("../../components/service-items/AddServiceItem"),
      children: [
        {
          path: "name-translations",
          name: "r_add-service-item-translate-name",
          meta: {
            middlewares: {
              auth: null,
              permission: ["MANAGE_SERVICE_ITEMS", "create"]
            }
          },
          props: {
            backLinkName: "r_add-service-item",
            componentTitle: "add-service-item"
          },
          component: () =>
            import("../../components/service-items/TranslateServiceItemName")
        },
        {
          path: "description-translations",
          name: "r_add-service-item-translate-description",
          meta: {
            middlewares: {
              auth: null,
              permission: ["MANAGE_SERVICE_ITEMS", "create"]
            }
          },
          props: {
            backLinkName: "r_add-service-item",
            componentTitle: "add-service-item"
          },
          component: () =>
            import(
              "../../components/service-items/TranslateServiceItemDescription"
            )
        },
        {
          path: "type",
          name: "r_add-service-item-type",
          meta: {
            middlewares: {
              auth: null,
              permission: ["MANAGE_SERVICE_ITEMS", "create"]
            }
          },
          props: {
            backLinkName: "r_add-service-item",
            componentTitle: "add-service-item"
          },
          component: () =>
            import("../../components/service-items/ServiceItemTypes")
        }
      ]
    },
    {
      path: ":service_item_id",
      name: "r_edit-service-item",
      meta: {
        middlewares: {
          auth: null,
          permission: ["MANAGE_SERVICE_ITEMS", "read"]
        }
      },
      props: { backLinkName: "r_service-items" },
      component: () => import("../../components/service-items/EditServiceItem"),
      children: [
        {
          path: "name-translations",
          name: "r_edit-service-item-translate-name",
          meta: {
            middlewares: {
              auth: null,
              permission: ["MANAGE_SERVICE_ITEMS", "read"]
            }
          },
          props: {
            backLinkName: "r_edit-service-item",
            componentTitle: "edit-service-item"
          },
          component: () =>
            import("../../components/service-items/TranslateServiceItemName")
        },
        {
          path: "description-translations",
          name: "r_edit-service-item-translate-description",
          meta: {
            middlewares: {
              auth: null,
              permission: ["MANAGE_SERVICE_ITEMS", "read"]
            }
          },
          props: {
            backLinkName: "r_edit-service-item",
            componentTitle: "edit-service-item"
          },
          component: () =>
            import(
              "../../components/service-items/TranslateServiceItemDescription"
            )
        },
        {
          path: "type",
          name: "r_edit-service-item-type",
          meta: {
            middlewares: {
              auth: null,
              permission: ["MANAGE_SERVICE_ITEMS", "read"]
            }
          },
          props: {
            backLinkName: "r_edit-service-item",
            componentTitle: "edit-service-item"
          },
          component: () =>
            import("../../components/service-items/ServiceItemTypes")
        },
        {
          path: "image",
          name: "r_edit-service-item-image",
          meta: {
            middlewares: {
              auth: null,
              permission: ["MANAGE_SERVICE_ITEMS", "read"]
            }
          },
          component: () =>
            import("../../components/service-items/ServiceImageSelection")
        }
      ]
    }
  ]
};
