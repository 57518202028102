export default {
  path: "/permissions-level",
  name: "r_permissions-level",
  meta: {
    middlewares: {
      auth: null
    }
  },
  props: {
    containerType: "LEVEL",
    backLinkName: "r_permissions-level"
  },
  component: () => import("../../components/permissions/Permissions"),
  children: [
    {
      path: "edit-permission",
      name: "r_edit-level-permission",
      meta: {
        middlewares: {
          auth: null
        }
      },
      props: { backLinkName: "r_permissions-level", containerType: "LEVEL" },
      component: () => import("../../components/permissions/EditPermission")
    }
  ]
};
