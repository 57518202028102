import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import { DateTime } from "luxon";
import store from "@/store";

export default {
  state: {
    services: null,
    serviceItems: null,
    serviceItem: null,
    serviceItemTranslations: [],
    selectedServiceItem: null,
    serviceResources: null,
    serviceResource: null,
    serviceOrders: null,
    serviceOrder: null,
    serviceStatuses: null,
    serviceStatus: null,
    serviceTypes: null,
    serviceType: null,
    serviceTypesTranslations: [],
    selectedServiceType: null,
    serviceImage: null,
    resourceDetails: null,
    resourceServiceTypes: [],
    serviceOrderType: null,
    serviceOrderHistory: null,
    serviceOrderItems: [],
    selectedOrderItems: [],
    filterDateFrom: null,
    filterDateTo: null,
    filterSortBy: null,
    filterSortOrder: null,
    filterGroupBy: null,
    page: null,
    totalPages: null,
    serviceOrderFrom: null,
    serviceOrderTo: null,
    infobox: null,
  },
  mutations: {
    setServiceItemTranslations(state, payload) {
      state.serviceItemTranslations = payload;
    },
    setServiceItem(state, payload) {
      state.serviceItem = payload;
    },
    setServiceItems(state, payload) {
      state.serviceItems = payload;
    },
    setServiceOrders(state, payload) {
      if (payload == null) {
        state.serviceOrders = null;
      } else if (state.serviceOrders == null) {
        state.serviceOrders = payload;
      } else {
        state.serviceOrders.data = state.serviceOrders.data.concat(
          Object.keys(payload.data).map((i) => payload.data[i])
        );
      }
    },
    setServiceOrder(state, payload) {
      state.serviceOrder = payload;
    },
    setServiceResource(state, payload) {
      state.serviceResource = payload;
    },
    setServiceStatuses(state, payload) {
      state.serviceStatuses = payload;
    },
    setServiceStatus(state, payload) {
      state.serviceStatus = payload;
    },
    setServiceResources(state, payload) {
      state.serviceResources = payload;
    },
    setSelectedResourceTypes(state, payload) {
      state.resourceTypes = payload;
    },
    setServiceTypeTranslations(state, payload) {
      state.serviceTypesTranslations = payload;
    },
    setServiceType(state, payload) {
      state.serviceType = payload;
    },
    setServiceTypes(state, payload) {
      state.serviceTypes = payload;
    },
    setServiceImage(state, payload) {
      state.serviceImage = payload;
    },
    setResourceDetails(state, payload) {
      state.resourceDetails = payload;
    },
    setResourceServiceTypes(state, payload) {
      state.resourceServiceTypes = payload;
    },
    setServiceOrderType(state, payload) {
      state.serviceOrderType = payload;
    },
    setServiceOrderItems(state, payload) {
      state.serviceOrderItems = payload;
    },
    setSelectedOrderItem(state, payload) {
      if (!payload) {
        state.selectedOrderItems = [];
      } else if (Array.isArray(payload)) {
        state.selectedOrderItems = payload;
      } else {
        const hasResourceIdx = state.selectedOrderItems.findIndex(
          (item) => item.id === payload.id
        );
        if (hasResourceIdx !== -1) {
          state.selectedOrderItems.splice(hasResourceIdx, 1);
        } else {
          state.selectedOrderItems.push(payload);
        }
      }
    },
    setFilterDateFrom(state, payload) {
      state.filterDateFrom = payload;
    },
    setFilterDateTo(state, payload) {
      state.filterDateTo = payload;
    },
    setFilterSortBy(state, payload) {
      state.filterSortBy = payload;
    },
    setFilterSortOrder(state, payload) {
      state.filterSortOrder = payload;
    },
    setFilterGroupBy(state, payload) {
      state.filterGroupBy = payload;
    },
    setServiceOrderHistory(state, payload) {
      state.serviceOrderHistory = payload;
    },
    setPage(state, payload) {
      state.page = payload;
    },
    setTotalPages(state, payload) {
      state.totalPages = payload;
    },
    setServiceOrderFrom(state, payload) {
      state.serviceOrderFrom = payload;
    },
    setServiceOrderTo(state, payload) {
      state.serviceOrderTo = payload;
    },
    setInfobox(state, payload) {
      state.infobox = payload;
    },
  },
  getters: {
    serviceImage(state) {
      return (
        state.serviceImage &&
        state.serviceImage.data &&
        state.serviceImage.data.url
      );
    },
  },
  actions: {
    getServiceItems({ state }, params) {
      store.dispatch("pagination/paginate", {
        whatToCall: "service/getServiceItems",
        whereToSave: "service/setServiceItems",
        url: apiEndpoints.company.serviceItems,
        data: state.serviceItems,
        params: params,
      });
    },
    setServiceItems({ commit }, payload) {
      commit("service/setServiceItems", payload, { root: true });
    },
    checkDeleteServiceItem({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.serviceItems}/${id}/check`)
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    deleteServiceItem({ commit, dispatch }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .delete(`${apiEndpoints.company.serviceItems}/${id}`)
        .then(() => {
          commit("pagination/setPaginationData", null, { root: true });
          dispatch("getServiceItems");
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getServiceItem({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      commit("setServiceItemTranslations", []);
      commit("setServiceItemTranslations", []);
      httpServiceAuth
        .get(`${apiEndpoints.company.serviceItems}/${id}`, {
          params: {
            includes: ["translations"],
          },
        })
        .then((response) => {
          commit("setServiceItem", response.data);
          commit("setServiceItemTranslations", response.data.translations);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getServiceOrders({ commit, state, dispatch, loader = true }) {
      if (!state.page && loader == true)
        commit("loader/setScreenLoading", true, { root: true });
      var currentPage = state.page ? state.page : 1;
      var perPage = 200;
      var start = state.filterDateFrom
        ? state.filterDateFrom
        : DateTime.now()
            .plus({ days: -7 })
            .toFormat("yyyy-MM-dd");
      var end = state.filterDateTo
        ? state.filterDateTo
        : DateTime.now()
            .plus({ days: 7 })
            .toFormat("yyyy-MM-dd");
      var sortBy = state.filterSortBy ? state.filterSortBy : "order_id";
      var sortOrder = state.filterSortOrder ? state.filterSortOrder : "desc";
      var groupBy = state.filterGroupBy ? state.filterGroupBy : "none";

      httpServiceAuth
        .get(
          `${apiEndpoints.company.serviceOrders}?start=${start}&end=${end}&sortBy=${sortBy}&sortOrder=${sortOrder}&groupBy=${groupBy}&page=${currentPage}&per_page=${perPage}`
        )
        .then((response) => {
          if (currentPage == 1) {
            commit("setServiceOrders", null);
          }
          commit("setServiceOrders", response.data);
          commit("setPage", response.data.meta.current_page);
          commit("setTotalPages", response.data.meta.last_page);
          commit("loader/setScreenLoading", false, { root: true });
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          // commit("loader/setScreenLoading", false, { root: true });
          if (state.page < state.totalPages) {
            commit("setPage", Number(state.page) + 1);
            dispatch("getServiceOrders");
          }
        });
    },
    getServiceOrder({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.serviceOrders}/${id}`)
        .then((response) => {
          commit("setServiceOrder", response.data);
          commit("setInfobox", {
            order_id: response.data.data.order_id,
            resource: response.data.data.resource,
            resource_id: response.data.data.resource_id,
            path: response.data.data.path,
            address: response.data.data.address,
            request_by: response.data.data.request_by,
            created_at: response.data.data.created_at,
          });
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getServiceStatuses({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.serviceStatuses}`)
        .then((response) => {
          commit("setServiceStatuses", response.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    updateServiceStatus({ commit, dispatch }, values) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .put(
          `${apiEndpoints.company.serviceOrders}/${values.order}/serviceitems/${values.item}/status/${values.status}`
        )
        .then(() => {
          if (values.refresh) {
            dispatch("setPage", null);
            dispatch("setTotalPages", null);
            dispatch("getServiceOrders");
            dispatch("getServiceOrder", values.order);
          }
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setServiceOrder({ commit }, serviceOrder) {
      commit("service/setServiceOrder", serviceOrder, { root: true });
    },
    setServiceResource({ commit }, serviceResource) {
      commit("service/setServiceResource", serviceResource, { root: true });
    },
    setServiceStatus({ commit }, serviceStatus) {
      commit("service/setServiceStatus", serviceStatus, { root: true });
    },
    getServiceResources({ commit }, data) {
      const params = {};

      if (data.query) {
        params.query = data.query;
      }

      if (data && data.type_ids && data.type_ids.length) {
        if (params.filters) {
          params.filters.push({
            type: "resource_type",
            value: [...data.type_ids],
          });
        } else {
          params.filters = [
            {
              type: "resource_type",
              value: [...data.type_ids],
            },
          ];
        }
      }

      if (data && data.level_ids && data.level_ids.length) {
        if (params.filters) {
          params.filters.push({
            type: "resource_level",
            value: [...data.level_ids],
          });
        } else {
          params.filters = [
            {
              type: "resource_level",
              value: [...data.level_ids],
            },
          ];
        }
      }
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.serviceResources}`, { params })
        .then((response) => {
          commit("setServiceResources", response.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getServiceTypes({ state }, params) {
      store.dispatch("pagination/paginate", {
        whatToCall: "service/getServiceTypes",
        whereToSave: "service/setServiceTypes",
        url: apiEndpoints.company.serviceTypes,
        data: state.serviceTypes,
        params: params,
      });
    },
    setServiceTypes({ commit }, payload) {
      commit("service/setServiceTypes", payload, { root: true });
    },
    getServiceType({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      commit("setServiceTypeTranslations", []);
      httpServiceAuth
        .get(`${apiEndpoints.company.serviceTypes}/${id}`, {
          params: {
            includes: ["translations"],
          },
        })
        .then((response) => {
          commit("setServiceType", response.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setServiceType({ commit }, serviceType) {
      commit("service/setServiceType", serviceType, { root: true });
    },
    deleteServiceType({ commit, dispatch }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .delete(`${apiEndpoints.company.serviceTypes}/${id}`)
        .then(() => {
          commit("pagination/setPaginationData", null, { root: true });
          dispatch("getServiceTypes");
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getResourceServiceTypes({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.resourceServiceTypes}/${id}`)
        .then((response) => {
          commit("setResourceServiceTypes", response.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setResourceServiceTypes({ commit }, content) {
      commit("service/setResourceServiceTypes", content, {
        root: true,
      });
    },
    getServiceImage({ commit }, id) {
      return httpServiceAuth
        .get(`${apiEndpoints.company.serviceItems}/${id}/image`)
        .then((response) => {
          commit("setServiceImage", response.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        });
    },
    getResourceDetails({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.resourceDetails}/${id}/details`)
        .then((response) => {
          commit("setResourceDetails", response.data);
          commit("setInfobox", response.data.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setServiceItemTranslations({ commit }, translations) {
      commit("service/setServiceItemTranslations", translations, {
        root: true,
      });
    },
    setServiceOrderType({ commit }, serviceOrderType) {
      commit("service/setServiceOrderType", serviceOrderType, {
        root: true,
      });
    },
    getServiceOrderItems({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.serviceOrderItems}/${id}`)
        .then((response) => {
          commit("setServiceOrderItems", response.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getServiceOrderHistory({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.serviceHistory}?service_order_id=${id}`)
        .then((response) => {
          commit("setServiceOrderHistory", response.data);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setServiceOrderItems({ commit }, serviceOrderItems) {
      commit("service/setServiceOrderItems", serviceOrderItems, {
        root: true,
      });
    },
    selectOrderItem({ commit }, serviceOrderItem) {
      commit("setSelectedOrderItem", serviceOrderItem);
    },
    setFilterDateFrom({ commit }, filterDateFrom) {
      commit("setFilterDateFrom", filterDateFrom);
    },
    setFilterDateTo({ commit }, filterDateTo) {
      commit("setFilterDateTo", filterDateTo);
    },
    setFilterSortBy({ commit }, filterSoftBy) {
      commit("setFilterSortBy", filterSoftBy);
    },
    setFilterSortOrder({ commit }, filterSoftOrder) {
      commit("setFilterSortOrder", filterSoftOrder);
    },
    setFilterGroupBy({ commit }, filterGroupBy) {
      commit("setFilterGroupBy", filterGroupBy);
    },
    setServiceOrders({ commit }, payload) {
      commit("setServiceOrders", payload);
    },
    setPage({ commit }, payload) {
      commit("setPage", payload);
    },
    setTotalPages({ commit }, payload) {
      commit("setTotalPages", payload);
    },
    setServiceOrderFrom({ commit }, payload) {
      commit("service/setServiceOrderFrom", payload, {
        root: true,
      });
    },
    setServiceOrderTo({ commit }, payload) {
      commit("service/setServiceOrderTo", payload, {
        root: true,
      });
    },
  },
};
