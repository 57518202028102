import { APPLICATIONS } from "@/services/consts/application";

export default {
  path: "/realtime",
  name: "r_realtime",
  meta: {
    middlewares: {
      auth: null,
      permission: "REAL_TIME",
      document: { applicationId: APPLICATIONS.APP_REAL_TIME.id },
      menuPreferences: "REAL_TIME"
    }
  },
  component: () => import("../../components/realtime/Realtime"),
  children: [
    {
      path: ":mapId",
      name: "r_realtime-map",
      meta: {
        middlewares: {
          auth: null,
          permission: "REAL_TIME",
          document: { applicationId: APPLICATIONS.APP_REAL_TIME.id },
          menuPreferences: "REAL_TIME"
        }
      },
      component: () => import("../../components/realtime/RealtimeMap"),
      children: [
        {
          path: "help",
          name: "r_realtime-activate-help-online",
          meta: {
            middlewares: {
              auth: null,
              permission: "REAL_TIME",
              document: { applicationId: APPLICATIONS.APP_REAL_TIME.id },
              menuPreferences: "REAL_TIME"
            }
          },
          props: {
            backLinkName: "r_realtime-map",
            slug: "real-time-activate"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "help",
      name: "r_realtime-help-online",
      meta: {
        middlewares: {
          auth: null,
          permission: "REAL_TIME",
          document: { applicationId: APPLICATIONS.APP_REAL_TIME.id },
          menuPreferences: "REAL_TIME"
        }
      },
      props: {
        backLinkName: "r_realtime",
        slug: "real-time"
      },
      component: () => import("../../components/help-online/HelpOnline")
    }
  ]
};
