import { APPLICATIONS } from "@/services/consts/application";
export default {
  path: "/5dmaps",
  name: "r_5dmaps",
  meta: {
    middlewares: {
      auth: null,
      permission: "5D_MAPS",
      document: { applicationId: APPLICATIONS.APP_MAPS.id }
    }
  },
  component: () => import("../../components/5dMaps/5dMaps")
};
